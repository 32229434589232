import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  &:hover {
    cursor: pointer;
    font-weight: 700;
  }
`;

export const Paragraph = styled.span`
  font-size: 12px;
`;

export const Copy = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 5px;
`;