import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
  ButtonContainer,
} from './styles';

import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { CertifyImage } from '../CreateCourse/styles';
import { useParams } from 'react-router-dom';
import adminCoursesService from '../../../../services/adminCoursesService';
import serverConst from '../../../../constants/serverConst';
import Button from '../../../../components/forms/Button';
import { useNavigate } from 'react-router-dom';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import InputTextArea from '../../../../components/forms/InputTextArea';
import RolesCertificate from '../../../../components/certificate/RolesCertificate';
import { myAlert } from "../../../../lib/Alert";

function ShowCourseContent() {
  const navigate = useNavigate();
  const [course, setCourse] = useState({
    title: "",
    description: "",
    url: "",
    certificate: {
      text: "",
    },
  });
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminCoursesService.show(id);
        setCourse(res.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          myAlert.open({
            title:
              "Erro ao buscar informação. Recarregue a página ou contate o suporte.",
            type: "error",
          });
          setLoading(false);

        }, 500);
      }
    };
    getData();
  }, [id]);

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Visualização de curso</Title>
        <SubTitle>
          Você está visualizando as informações de{" "}
          <b>{course.title || "..."}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                value: course.title || "",
                disabled: true,
              }}
            />
            <InputText
              label="URL da Página do Curso"
              inputProps={{ type: "text", value: course.url, disabled: true }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Descrição do curso"
              inputProps={{
                type: "text",
                value: course.description,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Frase padrão"
              inputProps={{
                type: "text",
                value: course.certificate && course.certificate.text,
                disabled: true,
              }}
            />
            <RolesCertificate />
          </RowInput>
          <RowInput style={{ flexDirection: "column" }}>
            {course && (
              <ViewCertificate
                {...{
                  back: {
                    url: course.certificate && course.certificate.back
                      ? course.certificate.back.file
                      : "",
                  },
                  front: {
                    url: course.certificate && course.certificate.front
                      ? course.certificate.front.file
                      : "",
                    text: course.certificate && course.certificate.text,
                  },
                  course,
                }}
              />
            )}
          </RowInput>

          <ButtonContainer>
            <Button
              loading={loading}
              label="Editar"
              onClick={() => {
                navigate(`/courses/edit/${id}`);
              }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function ShowCourse() {
  return (
    <DashboardTemplate>
      <ShowCourseContent />
    </DashboardTemplate>
  );
}

export default ShowCourse;
