import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import imgBackground from "../../assets/background-login.png";
import logo from "../../assets/logo.png";
import Button from "../../components/forms/Button";
import {
  Container,
  ContainerLogin,
  Form,
  FormFooter,
  ImgBackground,
  Logo,
  Title,
} from "./styles";
import LoadingPage from "../LoadingPage";
import authService from "../../services/authService";
import { myAlert } from "../../lib/Alert";

function Login() {
  const [isRequesting, setIsRequesting] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkToken() {
      try {

        const queryString = window.location.search || window.location.href;
        const urlParams = new URLSearchParams(queryString);

        const token = urlParams.get("token") || ""
        const res = await authService.registryConfirm({ token });
        setIsRequesting(false);
      } catch (error) {
        console.log(error);
        setIsRequesting(false);
        myAlert.open({ title: "Erro ao ativar conta. Contate o suporte", type: 'error' });
        navigate("/login", { replace: true });
      }
    }
    checkToken();
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    navigate("/login", { replace: true });
  };

  return (
    <>
      {
        isRequesting ? (
          <LoadingPage />
        ) : (
          <Container>
            <ImgBackground src={imgBackground} />
            <Logo src={logo} />
            <ContainerLogin>
              <Title>E-mail confirmado!</Title>
              <Form onSubmit={submit}>
                <FormFooter>
                  <Button
                    label={"Ir para Login"}
                    buttonProps={{ type: "submit" }}
                  />
                </FormFooter>
              </Form>
            </ContainerLogin>
          </Container>
        )
      }
    </>
  );
}

export default Login;
