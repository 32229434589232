import React from "react";

import { useNavigate } from "react-router-dom";
import imgBackground from "../../../assets/background-login.png";
import logo from "../../../assets/logo.png";
import Button from "../../../components/forms/Button";
import InputText from "../../../components/forms/InputText";
import {
  Container,
  ContainerLogin,
  Form,
  FormFooter,
  ImgBackground,
  Logo,
} from "./styles";
import adminCertificateService from "../../../services/adminCertificateService";
import { myAlert } from "../../../lib/Alert";
import ValidateFooter from "../../../components/ValidateFooter";

function Validation() {
  const navigate = useNavigate();
  const [hash, setHash] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [label, setLabel] = React.useState("Validar");
  const [isInvalid, setIsInvalid] = React.useState(false);

  const submit = async (e) => {
    e.preventDefault();
    if (!hash) {
      setLabel("Preencha o campo para continuar!")
      setTimeout(() => {
        setLabel("Validar novamente")
      }, 3000);
      return
    }
    try {
      setLoading(true);
      setIsInvalid(false)
      const res = await adminCertificateService.validate(hash);
      console.log(res)
      setLoading(false);
      setIsInvalid(false)
      navigate(
        "/validate-certificate/" + hash,
        {
          replace: true,
          state:
          {
            hash: res.hash,
            course: res.course.title,
            issueDate: res.issueDate,
            studentName: res.studentDb ? res.studentDb.fullname : res.student.fullname,
          }
        });
    } catch (error) {
      console.log(error)
      setIsInvalid(true)
      setTimeout(() => {
        setLabel("Validar novamente")
      }, 3000);

      setLoading(false);
    }

  };
  return (
    <Container>
      <ImgBackground src={imgBackground} />
      <Logo src={logo} />
      <ContainerLogin>
        <Form onSubmit={submit}>
          <InputText
            label="Código de Validação"
            labelProps={{ style: { fontSize: "1rem" } }}
            inputProps={{
              type: "text",
              placeholder: 'Cole aqui seu código de validação',
              onChange: (e) => setHash(e.target.value),
            }}
          />
          <FormFooter>
            {isInvalid && <span style={{ color: '#EA5C47', fontWeight: 700, textAlign: 'center', width: '100%' }}>Código inválido!</span>}
            <Button
              label={label}
              buttonProps={{ type: "submit" }}
              loading={loading}
            />
          </FormFooter>
        </Form>
      </ContainerLogin>
      <ValidateFooter />
    </Container>
  );
}

export default Validation;
