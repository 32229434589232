import React, { useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  InputImage,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import InputTextArea from '../../../../components/forms/InputTextArea';
import { myAlert } from '../../../../lib/Alert';
import adminCoursesService from '../../../../services/adminCoursesService';
import RolesCertificate from '../../../../components/certificate/RolesCertificate';
import { useNavigate } from "react-router-dom";

function CreateCourseContent() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [certificateText, setCertificateText] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFront, setImageFront] = useState({ url: "", file: "" });
  const [imageBack, setImageBack] = useState({ url: "", file: "" });
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);
  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const onChangeFile = async (part = "front") => {
    const currFile = part === "front" ? fileFrontRef : fileBackRef;
    if (currFile.current.files[0]) {
      const url = await readURL(currFile.current.files[0]);
      if (part === "front") {
        setImageFront({ url: url, file: currFile.current.files[0] });
        return;
      }

      setImageBack({ url: url, file: currFile.current.files[0] });
    }
  };
  const clearFields = () => {
    setTitle("");
    setDescription("");
    setUrl("");
    setCertificateText("");
    setImageFront({ url: "", file: "" });
    setImageBack({ url: "", file: "" });
    setLoading(false);
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      if (!title || !description || !url) {
        myAlert.open({
          title: "Preencha todos os campos obrigatórios",
          type: "warning",
        });
        setLoading(false);
        return;
      } else {
        await adminCoursesService.create({
          title,
          description,
          url,
          certificate: { text: certificateText },
          fileFront: imageFront.file,
          fileBack: imageBack.file,
        });
        setTimeout(() => {
          myAlert.open({
            title: "Curso cadastrado com sucesso",
            type: "success",
          });
          navigate("/courses", { replace: true });
        }, 500);
      }
    } catch (error) {
      setTimeout(() => {
        if (error.code === "FILE_REQUIRED") {
          myAlert.open({
            title:
              `Erro: ${error.message}.`,
            type: "error",
          });
        } else {
          myAlert.open({
            title:
              "Erro ao cadastrar curso. Tente novamente ou contacte o suporte.",
            type: "error",
          });
        }
        setLoading(false);
      }, 500);
    }
  };

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Criar curso</Title>
        <SubTitle>
          Cadastre um novo curso preenchendo o formulário abaixo
        </SubTitle>
        <br />
        <br />
        <SubTitle>
          Os cursos são automaticamente cadastrados a cada X tempos.
        </SubTitle>
        <br />
        <SubTitle>
          O cursos cadastrados não irão aparecer na plataforma principal
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => setTitle(e.target.value),
              }}
            />
            <InputText
              label="URL da Página do Curso"
              inputProps={{
                type: "text",
                value: url,
                onChange: (e) => setUrl(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Descrição do curso"
              inputProps={{
                type: "text",
                value: description,
                onChange: (e) => setDescription(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Frase padrão"
              inputProps={{
                type: "text",
                value: certificateText,
                onChange: (e) => setCertificateText(e.target.value),
              }}
            />
            <RolesCertificate />
          </RowInput>

          <RowInput>
            <InputImage
              {...{
                type: "file",
                ref: fileFrontRef,
                onChange: () => onChangeFile("front"),
                id: "image-certificate",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <InputImage
              {...{
                type: "file",
                ref: fileBackRef,
                onChange: () => onChangeFile("back"),
                id: "image-certificate-fundo",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <ViewCertificate
              {...{
                upload: true,
                front: {
                  internalUrl: imageFront.url,
                  url: imageFront.url,
                  text: certificateText,
                  forId: "image-certificate",
                  helperTexts: [
                    "O hash do arquivo só poderá ser visualizado após a geração do certificado",
                    "Variáveis de turma serão visíveis apenas em turmas ou após a geração do certificado",
                    "Variáveis de aluno serão visíveis após a geração do certificado",
                  ],
                },
                back: {
                  internalUrl: imageBack.url,
                  url: imageBack.url,
                  text: "Ementa da turma aqui",
                  forId: "image-certificate-fundo",
                  helperTexts: [
                    "O hash do arquivo só poderá ser visualizado após a geração do certificado",
                    "A ementa será visível apenas em turmas ou após a geração do certificado",
                  ],
                },
              }}
            />
          </RowInput>

          <ButtonContainer>
            <Button
              loading={loading}
              label="Cadastrar"
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function CreateCourse() {
  return (
    <DashboardTemplate>
      <CreateCourseContent />
    </DashboardTemplate>
  );
}

export default CreateCourse;
