import React, { useContext, useState } from 'react';
import {
  Body,
  Container,
  DivLogo,
  FooterList,
  HeaderContent,
  Logo,
  MainContent,
  MainIcon,
  MainItem,
  MainLabel,
  MainList,
  MenuIcon,
  SideBarContainer,
  UserName,
} from "./styles";

import { useLocation, useNavigate } from "react-router-dom";
import iconCertificados from "../../../assets/certificados.svg";
import iconClasses from "../../../assets/classes.svg";
import iconCourses from "../../../assets/courses.svg";
import iconLogo from "../../../assets/logo.png";
import iconLogout from "../../../assets/logout.svg";
import iconMenu from "../../../assets/menu.svg";
import iconStudents from "../../../assets/students.svg";
import iconUsers from "../../../assets/users.svg";
import iconCheck from "../../../assets/check2.svg";
import iconUser from "../../../assets/user.png";
import iconChat from "../../../assets/chats.svg";
import AuthContext from "../../../hooks/Auth";

function DashboardTemplate({ children }) {
  const { logout, user, token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const mainItems = [
    {
      key: "courses",
      path: "/courses",
      icon: iconCourses,
      label: "Cursos",
    },
    {
      key: "classes",
      path: "/classes",
      icon: iconClasses,
      label: "Turmas",
    },
    {
      key: "students",
      path: "/students",
      icon: iconStudents,
      label: "Alunos",
    },
    {
      key: "certify",
      path: "/certificates",
      icon: iconCertificados,
      label: "Certificados Emitidos",
    },
    {
      key: "validar-certificado",
      icon: iconCheck,
      onClick: async () => {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: '/validar-certificado',
        }).click();

      },
      label: "Validar certificado",
    },
    {
      key: "users",
      path: "/users",
      icon: iconUsers,
      label: "Usuários",
    },
    {
      key: "chats",
      path: "/chats",
      icon: iconChat,
      label: "Gerador de Chats",
    },

  ];
  const footerItems = [
    {
      key: "logout",
      icon: iconLogout,
      label: "Sair",
      onClick: async () => {
        await logout();
        navigate("/login", { replace: true });
      },
    },
  ];

  const [showSideBar, setShowSideBar] = useState(window.innerWidth > 769 ? true : state?.showSideBar);

  return (
    <Container>
      <HeaderContent>
        <DivLogo>
          <MenuIcon
            src={iconMenu}
            onClick={() => {
              if (showSideBar) {
                setShowSideBar(false);
              } else {
                setShowSideBar(true);
              }
            }}
          />
          <Logo src={iconLogo} />
        </DivLogo>
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <UserName>{user.email}</UserName>
          <img src={iconUser} />
        </div>
      </HeaderContent>
      <MainContent>
        {showSideBar && (
          <SideBarContainer>
            <MainList>
              {mainItems.map((item, idx) => (

                user.roles && (user.roles.includes('admin') || (item.path && user.roles.includes(item.path.replace("/", '')))) ?
                  <MainItem
                    key={idx}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                        return;
                      }
                      if (item.path) navigate(item.path);
                    }}
                    $active={window.location.pathname.includes(item.path)}
                  >
                    <MainIcon src={item.icon} />
                    <MainLabel>{item.label}</MainLabel>
                  </MainItem>
                  : null))}
            </MainList>
            <FooterList>
              {footerItems.map((item, idx) => (
                <MainItem
                  key={idx}
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                      return;
                    }
                    if (item.path) navigate(item.path);
                  }}
                >
                  <MainIcon src={item.icon} />
                  <MainLabel>{item.label}</MainLabel>
                </MainItem>
              ))}
            </FooterList>
          </SideBarContainer>
        )}
        <Body>{children}</Body>
      </MainContent>
    </Container>
  );
}

export default DashboardTemplate;
