import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import imgBackground from '../../assets/background-login.png';
import logo from '../../assets/logo.png';
import Button from '../../components/forms/Button';
import InputText from '../../components/forms/InputText';
import {
  Container,
  ContainerForgotPassword,
  Form,
  FormFooter,
  ImgBackground,
  Logo,
  Title,
} from './styles';
import authService from '../../services/authService';
import { myAlert } from '../../lib/Alert';
import LoadingPage from '../LoadingPage';
import myValidator from '../../lib/validator';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [token, setToken] = useState('');
  const [loadingValidateToken, setLoadingValidateToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const validateToken = async () => {
      const queryString = window.location.href.split('?')[1];
      const urlParams = new URLSearchParams(queryString);
      const tokenURL = urlParams.get('token');

      try {
        setLoadingValidateToken(true);
        if (tokenURL) {
          await authService.validateTokenResetPassword({
            token: tokenURL,
          });

          setToken(tokenURL);
        }
      } catch (error) {
        console.log(error);
        const errorRes = error.response;
        if (errorRes.data.code === 'INVALID_TOKEN') {
          myAlert.open({ title: 'Token inválido. Realize a solicitação novamente', type: 'error' });
          navigate('/forgot-password', { replace: true });
        } else if (errorRes && errorRes.data) {
          myValidator.verifyErrors(errorRes.data);
        }
      }
      setLoadingValidateToken(false);
    };
    validateToken();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const res = await authService.resetPassword({
        password,
        passwordConfirm,
        token,
      });
      setTimeout(() => {
        myAlert.open({ title: res.message, type: 'success' }, true);
        navigate('/', { replace: true });
      }, 500);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        // const errorRes = error.response;
        // if (errorRes && errorRes.data) {
        //   validator.verifyErrors(errorRes.data);
        // }
        myAlert.open({ title: 'Erro ao alterar senha. Tente novamente', type: 'error' }, true);
        setLoading(false);
      }, 500);
    }
  };
  return loadingValidateToken ? (
    <LoadingPage />
  ) : (
    <Container>
      <ImgBackground src={imgBackground} />
      <Logo src={logo} />
      <ContainerForgotPassword>
        <Title>Digite sua nova senha</Title>

        <Form onSubmit={handleSubmit}>
          <InputText
            label="Nova senha"
            inputProps={{ type: 'password', onChange: (e) => setPassword(e.target.value) }}
          />
          <InputText
            label="Confirme a nova senha"
            inputProps={{ type: 'password', onChange: (e) => setPasswordConfirm(e.target.value) }}
          />
          <FormFooter>
            <Button label={'Atualizar senha'} loading={loading} buttonProps={{ type: 'submit' }} />
          </FormFooter>
        </Form>
      </ContainerForgotPassword>
    </Container>
  );
}

export default ResetPassword;
