import styled from 'styled-components';
export const LiPagination = styled.li.attrs((props) => ({
  className: `${props.$waves ? 'waves-effect' : ''}${props.$active ? ' active' : ''}${
    props.$disabled ? ' disabled' : ''
  }`,
}))`
  & span {
    color: #244c59;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 1rem;
    padding: 0 10px;
    line-height: 30px;
    ${(props) => (props.$disabled ? 'cursor: default !important;' : 'cursor: pointer;')}
  }
  & span > img {
    ${(props) => (props.$disabled ? 'cursor: default !important;' : 'cursor: pointer;')}
  }
  & .waves-effect {
    ${(props) => (props.$disabled ? 'cursor: default !important;' : 'cursor: pointer;')}
  }
  &.active span {
    color: white;
    background-color: #244c59;
  }
  &.disabled span {
    color: #999;
  }
  & ul li {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    ${(props) => (props.$disabled ? 'cursor: default !important;' : 'cursor: pointer;')}
  }
`;

export const DropDownContent = styled.div`
  width: 100%;
  position: relative;
`;

export const DropDownUl = styled.ul`
  padding: 0;
  margin: 0;
  margin-top: 5px;
  /* padding-left: 1em; */
  background: #ffffff;
  border: 1px solid #244c59;
  box-sizing: border-box;
  color: #244c59;
  position: absolute;
  width: 100%;
  z-index: 100;
`;
export const DropDownLi = styled.li`
  list-style: none;
  font-size: 1rem;
  font-family: 'Effra-Light';
  color: ${(props) => (props.$active ? 'white' : '#244c59')};
  background-color: ${(props) => (props.$active ? '#244c59' : 'white')};
  padding: 13px 15px;
  &:hover {
    background-color: #eee;
  }
`;
export const ListDiv = styled.div`
  display: flex;
`;
export const UnorderedList = styled.ul`
    display: flex;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`;