import React, { useEffect, useState } from 'react';
import InputSearchBar from '../../../../components/forms/InputSearchBar';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  ActionIcon,
  ActiveTag,
  Body,
  BodyHeader,
  Container,
  ContainerSearch,
  Header,
  SubTitle,
  Title,
} from './styles';

import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyColumn,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';
import CopyText from '../../../../components/forms/CopyText'

import { useNavigate } from 'react-router-dom';
import iconEdit from '../../../../assets/edit.svg';
import iconTrash from '../../../../assets/trash.svg';
import iconReload from '../../../../assets/reload.svg';
import iconView from '../../../../assets/view.svg';
import Button from '../../../../components/forms/Button';
import adminCertificateService from '../../../../services/adminCertificateService';
import adminCoursesService from '../../../../services/adminCoursesService';
import adminClassesService from '../../../../services/adminClassesService'; import mailIcon from '../../../../assets/mailIcon_blue.svg';
import mailWhiteIcon from '../../../../assets/mailIcon_white.svg';
import time from '../../../../lib/time';
import { myAlert } from '../../../../lib/Alert';
import DeleteModal from '../../../../components/modals/DeleteModal';
import SelectShowInactive from '../../../../components/forms/SelectShowInactive';
import Select from 'react-select';
import Pagination from '../../../../components/Pagination';
import ConfirmSend from './ConfirmSend';
// import { Container } from './styles';

function ListCertifyContent() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [searchCourse, setSearchCourse] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [showInactive, setShowInactive] = useState(true);
  const [filterCourseData, setFilterCourseData] = useState([]);
  const [filterClassData, setFilterClassData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedEmailSent, setSelectedEmailSent] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedCourseLabel, setSelectedCourseLabel] = useState("");
  const [selectedClassLabel, setSelectedClassLabel] = useState("");
  const [showResume, setShowResume] = useState(false);
  const [showResumeTotal, setShowResumeTotal] = useState(0);

  const [rowsPageSelected, setRowsPageSelected] = useState(1);
  const rowsPage = [5, 10, 20, 50];
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    perPage: 5,
    total: 0,
  });

  const getData = async ({ page = 1, perPage = 10 }) => {
    try {
      setLoading(true);

      const dataCertificate = {

        find: search,
        isActive: showInactive,
        page,
        perPage,
      }

      if (selectedCourse) {
        dataCertificate.courseId = selectedCourse
      }
      if (selectedClass) {
        dataCertificate.classId = selectedClass

      }
      if (selectedEmailSent !== null) {
        dataCertificate.wasSent = selectedEmailSent
      }
      const res = await adminCertificateService.list(dataCertificate);

      setData(res.data);
      const lastPage = res.total > res.perPage ? Math.ceil(res.total / res.perPage) : 1;
      setPagination({
        lastPage,
        page: res.page,
        perPage: res.perPage,
        total: res.total,
      });


      setLoading(false);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        // myAlert.open({
        //   title: 'Erro ao buscar dados. Recarregue a página ou contate o suporte.',
        //   type: 'error',
        // });
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    const getCourse = async () => {
      try {
        const resCourse = await adminCoursesService.list({ isActive: true, find: searchCourse });
        setFilterCourseData(resCourse.data);


      } catch (error) {
        console.log(error)
      }
    }
    getCourse()
  }, [searchCourse])
  useEffect(() => {
    const getClass = async () => {
      try {


        const resClass = await adminClassesService.list({ isActive: true, find: searchClass, courseId: selectedCourse });
        setFilterClassData(resClass.data);
      } catch (error) {
        console.log(error)
      }
    }
    getClass()
  }, [searchClass, selectedCourse])
  useEffect(() => {
    getData({});
  }, [search, loading2, showInactive, selectedCourse, selectedClass, selectedEmailSent]);
  useEffect(() => {

    if (!showResume) {

      setSearch('')
      setSearchCourse('')
      setSearchClass('')
      setSelectedClass(null)
      setSelectedCourse(null)
      setSelectedEmailSent(null)
    }

  }, [showResume])
  const tableHeaderItems = [
    {
      label: 'Aluno',
      flex: 1,
    },
    {
      label: 'Curso - Turma',
      flex: 1,
    },
    {
      label: 'Data de Emissão',
      flex: 1,
      center: true,
    },
    {
      label: 'E-mail enviado',
      flex: 1,
      center: true,
    },

    {
      label: 'HashCode',
      flex: 1,
    },
    {
      label: 'Ações',
      flex: 1,
    },
  ];
  const handleChangeStatusItem = async ({ id, toActive, title }) => {
    try {
      setLoading2(true);
      await adminCertificateService.update({
        isActive: toActive,
        id,
      });

      myAlert.open({
        title: `Status de ${title} alterado com sucesso`,
        type: 'success',
      });
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      myAlert.open({
        title: `Erro ao alterar status ${title}`,
        type: 'error',
      });
    }
  };
  const handleResendMultiple = async () => {
    try {
      setLoadingSendEmail(true);
      console.log(selectedEmailSent)
      if (!selectedCourse && !selectedClass && !search && selectedEmailSent === undefined && selectedEmailSent === null) {
        myAlert.open({ title: "Selecione uma turma, um curso ou uma palavra", type: "warning", })
        setLoadingSendEmail(false);
        return
      }

      const data = {

      }
      if (selectedCourse) data.courseId = selectedCourse
      if (selectedClass) data.classId = selectedClass
      if (search) data.find = search
      if (selectedEmailSent !== null) {
        data.wasSent = selectedEmailSent
      }


      const result = await adminCertificateService.resendMultiples(data);

      myAlert.open({
        title: `Certificados reenviados com sucesso`,
        type: 'success',
      });
      setShowResume(true)
      setShowResumeTotal(result.total)

      setLoadingSendEmail(false);
    } catch (error) {
      setLoadingSendEmail(false);
      console.log(error);
      if (error && error.message) {
        myAlert.open({
          title: error.message,
          type: 'error',
        });
        return
      }
      myAlert.open({
        title: `Erro ao reenviar certificados`,
        type: 'error',
      });
    }
  };
  return showResume ? <ConfirmSend setShowResume={setShowResume} data={{
    total: showResumeTotal,
    search,
    isActive: showInactive,
    course: selectedCourseLabel,
    classe: selectedClassLabel,
    wasSent: selectedEmailSent
  }} /> : (
    <Container>
      <Header>
        <Title>Certificados</Title>
        <SubTitle>Encontre todos os Certificados emitidos na plataforma</SubTitle>
      </Header>
      <Body>
        <BodyHeader
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            flexWrap: 'wrap',
          }}
        >
          <Container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              flexWrap: 'wrap',
            }}
          >
            <ContainerSearch>
              <InputSearchBar
                loading={loading}
                inputProps={{
                  type: 'text',
                  placeholder: 'Pesquise por hash, nome ou e-mail do aluno',
                  onChange: (e) => setSearch(e.target.value),
                  style: { width: '350px' },
                }}
              />
            </ContainerSearch>
            <Button
              label="Emitir novos certificados"
              onClick={() => {
                navigate(`/certificates/create`);
              }}
            />
          </Container>
          <Container
            style={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
              margin: '10px 0 10px 0',
              alignItems: 'baseline',
            }}
          >
            <SelectShowInactive
              containerProps={{
                style: {
                  marginLeft: '0',
                },
              }}

              inputProps={{
                onChange: (e) => setShowInactive(e.value),
              }}
            />

            <Select
              options={[{ label: "Todos", value: null }, { label: "Enviado", value: true }, { label: "Não enviado", value: false },].map((course) => ({
                value: course.value,
                label: course.label,
              }))}
              isClearable
              placeholder="Envio por email"
              onChange={(e) => {
                if (e) { setSelectedEmailSent(e.value) }
                else { setSelectedEmailSent() }
              }}

              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '200px',
                }),
              }}
            />
            <Select
              options={filterCourseData.map((course) => ({
                value: course._id,
                label: course.title,
              }))}
              isClearable
              placeholder="Cursos"
              onChange={(e) => {
                if (e) {
                  setSelectedCourse(e.value)
                  setSelectedCourseLabel(e.label)
                }
                else {
                  setSelectedCourse()
                  setSelectedCourseLabel()
                }
              }}
              isSearchable
              onInputChange={(inputValue) => {
                if (inputValue) setSearchCourse(inputValue)

              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '230px',
                }),
              }}
            />
            <Select
              options={filterClassData.map((classe) => ({
                value: classe._id,
                label: classe.title,
              }))}
              placeholder="Turmas"
              isClearable
              onChange={(e) => {
                if (e) {
                  setSelectedClass(e.value)
                  setSelectedClassLabel(e.label)

                }
                else {
                  setSelectedClass()
                  setSelectedClassLabel()
                }

              }}
              isSearchable
              onInputChange={(inputValue) => {
                if (inputValue) setSearchClass(inputValue)

              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '230px',
                }),
              }}
            />
            <Button
              label="Reenviar certificados por email"
              iconHover={mailWhiteIcon}
              icon={mailIcon}
              loading={loadingSendEmail}
              onClick={() => {
                setModalContent({
                  title: `Enviar os certificados para o filtro selecionado atualmente?`,
                  action:
                    handleResendMultiple,
                  label: 'Enviar',
                  type: "success"
                });
                setShowDelete(true);
              }}
              containerStyle={{ width: '300px' }}
            />
          </Container>
        </BodyHeader>

        {showDelete && (
          <DeleteModal
            {...{
              show: showDelete,
              setShow: setShowDelete,
              title: modalContent.title || '',
              action: modalContent.action,
              label: modalContent.label,
              type: modalContent.type || "error"
            }}
          />
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHeader>
              {tableHeaderItems.map((item, idx) => (
                <TableHeaderColumn
                  style={item.center ? { justifyContent: 'center' } : {}}
                  key={idx}
                  flex={item.flex}
                >
                  {item.label}
                </TableHeaderColumn>
              ))}
            </TableHeader>

            <TableBody>
              {data.map((item, idx) => (
                <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                  <TableBodyColumn>
                    {item.student ? item.student.fullname : item.studentDb.fullname}
                  </TableBodyColumn>
                  <TableBodyColumn>{item.course.title} - {item.class.title}</TableBodyColumn>

                  <TableBodyColumn style={{ justifyContent: 'center' }}>
                    {item.issueDate || '-'}
                  </TableBodyColumn>
                  <TableBodyColumn style={{ justifyContent: 'center', color: item.wasSent ? "#5BAAB3" : '#EA5C47' }}>
                    {item.wasSent ? "Sim" : "Não"}
                  </TableBodyColumn>
                  <TableBodyColumn style={{ fontSize: '12px' }}> <CopyText>{item.hash}</CopyText></TableBodyColumn>
                  <TableBodyColumn>
                    <ActionIcon
                      src={iconView}
                      onClick={() => {
                        navigate(`/certificates/${item._id}`);
                      }}
                    />
                    {showInactive ? (
                      <ActionIcon
                        src={iconEdit}
                        onClick={() => {
                          navigate(`/certificates/edit/${item._id}`);
                        }}
                      />
                    ) : null}
                    {showInactive ? (
                      <ActionIcon
                        src={iconTrash}
                        onClick={async () => {
                          setModalContent({
                            title: `Deseja desativar certificado de hash ${item.hash}?`,
                            action: () =>
                              handleChangeStatusItem({
                                title: item.hash,
                                id: item._id,
                                toActive: !item.isActive,
                              }),
                            label: 'Desativar',
                          });
                          setShowDelete(true);
                        }}
                      />
                    ) : (
                      <ActionIcon
                        src={iconReload}
                        onClick={async () => {
                          setModalContent({
                            title: `Deseja ativar certificado de hash ${item.hash}?`,
                            action: () =>
                              handleChangeStatusItem({
                                title: item.hash,
                                id: item._id,
                                toActive: !item.isActive,
                              }),
                            label: 'Ativar',
                          });
                          setShowDelete(true);
                        }}
                      />
                    )}
                  </TableBodyColumn>
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#244c59' }}>
          <Pagination
            {...{
              ...pagination,
              rowsPageSelected,
              setRowsPageSelected,
              rowsPage,
              filter: { find: search, isActive: showInactive },
              getData: getData,
            }}
          />
          {`${(pagination.page - 1) * pagination.perPage + 1}-${Math.min(pagination.page * pagination.perPage, pagination.total)} de ${pagination.total}`}
        </Container>
      </Body>
    </Container>
  );
}
function ListCertify() {
  return (
    <DashboardTemplate>
      <ListCertifyContent />
    </DashboardTemplate>
  );
}

export default ListCertify;
