import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Routes from './config/Routes';
import Providers from './Providers';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Providers>
    <Routes />
  </Providers>
);
