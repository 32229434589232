import { myAlert } from './Alert';

const myValidator = {
  throwInvalidInputMessage: ({ inputName, message }) => {
    const input = document.getElementById(inputName);
    if (input) {
      myAlert.open({ title: message, type: 'error' });
      input.focus();
    }
  },

  validateInputs: (data) => {
    let valid = true;
    Object.keys(data).map((key) => {
      if (!data[key]) {
        valid = false;
      }
      return null;
    });

    return valid;
  },

  verifyValidationErrors: (data) => {
    // Exibindo possíveis erros de validação
    data.validationErrors.map((error) => {
      myAlert.open({ title: error.message, type: 'warning' });
      return null;
    });
  },
  verifyErrors: (data) => {
    myAlert.closeAll();
    // Verificando se exsitem erros a serem exibidos
    if (data) {
      if (data.errors) {
        data.errors.map((error) => {
          // console.log(error);
          if (error.id === 'EMAIL_SENDING_FAILS') {
            myAlert.open({ title: error.message, type: 'warning' });
          } else myAlert.open({ title: error.message, type: 'error' });
          return null;
        });
      }
      if (data.validationErrors) {
        myValidator.verifyValidationErrors(data);
      }
    }
  },
};

export default myValidator;
