import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import imgBackground from '../../assets/background-login.png';
import logo from '../../assets/logo.png';
import Button from '../../components/forms/Button';
import InputText from '../../components/forms/InputText';
import { myAlert } from '../../lib/Alert';
import {
  Container,
  ContainerForgotPassword,
  Description,
  Form,
  FormFooter,
  ImgBackground,
  Login,
  Logo,
  Title,
} from './styles';
import authService from '../../services/authService';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const res = await authService.forgotPassword({
        email,
      });

      setTimeout(() => {
        myAlert.open({ title: res.message, type: 'success' }, true);
        navigate('/', { replace: true });
      }, 500);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        // const errorRes = error.response;
        // if (errorRes && errorRes.data) {
        //   validator.verifyErrors(errorRes.data);
        // }
        myAlert.open({ title: 'Erro ao enviar e-mail. Tente novamente', type: 'error' }, true);
        setLoading(false);
      }, 500);
    }
  };
  return (
    <Container>
      <ImgBackground src={imgBackground} />
      <Logo src={logo} />
      <ContainerForgotPassword>
        <Title>Esqueceu sua senha?</Title>
        <Description>
          Digite seu endereço de email e nós enviaremos um link de recuperação de senha para sua
          caixa de entrada.
        </Description>
        <Form onSubmit={handleSubmit}>
          <InputText
            label="E-mail"
            inputProps={{ type: 'email', onChange: (e) => setEmail(e.target.value) }}
          />

          <FormFooter>
            <Button
              label={'Enviar e-mail de redefinição de senha'}
              buttonProps={{ type: 'submit' }}
              loading={loading}
            />
            <Login>
              <span onClick={() => navigate('/', { replace: true })}>Voltar</span>
            </Login>
          </FormFooter>
        </Form>
      </ContainerForgotPassword>
    </Container>
  );
}

export default ForgotPassword;
