import React, { useState } from 'react';
import { ModalBackground, ModalContent, OwnerName } from './styles';
import { Trash } from '@styled-icons/bootstrap';


const Modal = ({ isOpen, fixedMessage, removeFixedMessage, isAdmin }) => {
  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContent>
        {
          fixedMessage && (
            <div style={{display: "flex", justifyContent: 'space-between'}}>
              <div style={{ zIndex: 99, wordBreak: 'break-all', maxWidth: "96%" }}>
                <OwnerName>{fixedMessage.owner.owner}</OwnerName>
                {
                  (fixedMessage.content.includes('<a')) ? (
                    <span style={{ color: 'inherit', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: fixedMessage.content }} />
                  ) : fixedMessage.content
                }
              </div>
              {
                isAdmin && 
                <Trash onClick={removeFixedMessage} title="Apagar mensagem fixada" width={30} height={30} style={{ cursor: 'pointer' }} />
              }
            </div>
          )
        }
      </ModalContent>
    </ModalBackground>
  );
};

export default Modal;
