import styled, { css } from 'styled-components';

export const Container = styled.div(
  (props) => css`
    cursor: pointer;
    border: 1px solid ${props.type === 'error' ? '#EA5C47' : '#5baab3'};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 5px;
    margin: 10px 0;

    &:hover {
      background-color: ${props.type === 'error' ? '#EA5C47' : '#5baab3'};
    }
    & img {
      margin-left: 5px;
      width: 20px;
    }
    & img.icon-hover {
      display: none;
    }
    &:hover img.icon {
      display: none;
    }
    &:hover img.icon-hover {
      display: block;
    }
    & button {
      padding: 10px 20px;
      font-size: 14px;
      cursor: pointer;
      color: ${props.type === 'error' ? '#EA5C47' : '#5baab3'};
      border: none;
      font-weight: 700;
      background-color: transparent;
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover button {
      color: #fff;
    }
  `
);
