import React, { useState, useEffect } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  TagContainer,
  Title,
  ButtonContainer,
} from './styles';
import { TailSpin } from 'react-loader-spinner';

import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyColumn,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';

import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { useNavigate } from 'react-router-dom';
import adminStudentsService from '../../../../services/adminStudentsService';
import adminCertificateService from '../../../../services/adminCertificateService';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/forms/Button';
import iconSend from '../../../../assets/mailIcon.svg';
import iconDownload from '../../../../assets/download.svg';
import { ActionIcon } from '../ListStudent/styles';
import iconView from '../../../../assets/view.svg';
import CheckBox from '../../../../components/forms/CheckBox';
import { myAlert } from '../../../../lib/Alert';
import files from '../../../../lib/files';
import tagConst from '../../../../constants/tags';

function ShowStudentContent() {
  const navigate = useNavigate();
  const [tags, setTags] = useState(tagConst.array);
  let { id } = useParams();

  const [student, setStudent] = useState({});
  const [certificates, setCertificates] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  useEffect(() => {
    const getCertificate = async () => {
      try {
        setLoading(true);
        const res = await adminCertificateService.list({
          isActive: true,
          page: 1,
          perPage: 100,

          studentId: student._id,
        });

        setCertificates(res.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          myAlert.open({
            title: 'Erro ao buscar informação. Recarregue a página ou contate o suporte.',
            type: 'error',
          });
          setLoading(false);
        }, 500);
      }
    };
    if (student && student._id) {
      getCertificate();
    }
  }, [student]);
  useEffect(() => {
    const getStudent = async () => {
      try {
        setLoading(true);
        const res = await adminStudentsService.show(id);
        setStudent(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          myAlert.open({
            title: 'Erro ao buscar informação. Recarregue a página ou contate o suporte.',
            type: 'error',
          });
          setLoading(false);
        }, 500);
      }
    };
    getStudent();
  }, [id]);
  useEffect(() => {
    const newTags = [];
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      if (student.tags && student.tags.includes(tag.key)) {
        newTags.push({ ...tag, status: true });
      } else {
        newTags.push({ ...tag });
      }
    }
    setTags(newTags);
  }, [student]);

  const tableHeaderItems = [
    {
      label: 'Curso',
      flex: 1,
    },
    {
      label: 'Turma',
      flex: 1,
    },
    {
      label: 'Data de emissão',
      flex: 1,
    },
    {
      label: 'Hash de validação',
      flex: 1,
    },
    {
      label: 'Ações',
      flex: 1,
    },
  ];
  const handleDownload = async (hash) => {
    try {
      setLoadingDownload(true);
      if (!hash) {
        myAlert.open({ title: 'Hash do certificado não encontrado', type: 'error' });
        setLoadingDownload(false);
        return;
      }
      const download = await adminCertificateService.downloadCertificate(hash);
      const byteCharacters = files.convertBase64ToBlob(download);
      const url = window.URL.createObjectURL(byteCharacters);
      window.open(url);
      setLoadingDownload(false);
    } catch (error) {
      setLoadingDownload(false);
      console.log(error);
      myAlert.open({ title: 'Erro ao realizar download', type: 'error' });
    }
  };
  const handleResend = async (hash) => {
    try {
      setLoadingSendMail(true);

      if (!hash) {
        myAlert.open({ title: 'Hash do certificado não encontrado', type: 'error' });
        setLoadingSendMail(false);
        return;
      }
      await adminCertificateService.resendCertificateEmail({ hash });

      myAlert.open({ title: 'Certificado reenviado com sucesso', type: 'success' });

      setLoadingSendMail(false);
    } catch (error) {
      setLoadingSendMail(false);
      console.log(error);
      myAlert.open({ title: 'Erro ao enviar certificado', type: 'error' });
    }
  };
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Visualização de Aluno</Title>
        <SubTitle>
          Você está visualizando as informações de <b>{student.name}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form style={{ marginBottom: '15px' }}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: 'text',
                value: student.fullname,
                disabled: true,
              }}
            />
            <InputText
              label="E-mail"
              inputProps={{
                type: 'e-mail',
                value: student.email,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="CPF"
              inputProps={{
                type: 'text',
                value: student.cpfCnpj,
                disabled: true,
              }}
            />
            <InputText
              label="Telefone"
              inputProps={{
                type: 'text',
                value: student.phone,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <CheckBox disabled={true} label="Tags" options={tags} setOptions={setTags} />
          </RowInput>
        </Form>
        <SubTitle>Certificados</SubTitle>
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHeader>
              {tableHeaderItems.map((item, idx) => (
                <TableHeaderColumn key={idx} flex={item.flex}>
                  {item.label}
                </TableHeaderColumn>
              ))}
            </TableHeader>

            <TableBody>
              {certificates.length > 0 &&
                certificates.map((item, idx) => (
                  <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                    <TableBodyColumn>{item.course && item.course.title}</TableBodyColumn>
                    <TableBodyColumn>{item.class && item.class.title}</TableBodyColumn>
                    <TableBodyColumn>{item.issueDate}</TableBodyColumn>
                    <TableBodyColumn>{item.hash}</TableBodyColumn>
                    <TableBodyColumn style={{ alignItems: 'center' }}>
                      <ActionIcon
                        src={iconView}
                        alt="Visualizar certificado"
                        onClick={() => {
                          navigate(`/certificates/${item._id}`);
                        }}
                      />
                      {loadingSendMail ?
                        <TailSpin height="20" width="40" color="#5BAAB3" ariaLabel="" />
                        : <ActionIcon
                          src={iconSend}
                          alt="Enviar certificado por email"
                          onClick={() => {
                            handleResend(item.hash);
                          }}
                        />}
                      {loadingDownload ?
                        <TailSpin height="20" width="40" color="#5BAAB3" ariaLabel="" />
                        : <ActionIcon
                          src={iconDownload}
                          alt="Baixar certificado"
                          onClick={() => {
                            handleDownload(item.hash);
                          }}
                        />}
                    </TableBodyColumn>
                  </TableBodyRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <ButtonContainer>
          <Button
            loading={loading}
            label="Editar"
            onClick={() => {
              navigate(`/students/edit/${id}`);
            }}
            containerStyle={{ width: '200px' }}
          />
        </ButtonContainer>
      </Body>
    </Container>
  );
}
function ShowStudent() {
  return (
    <DashboardTemplate>
      <ShowStudentContent />
    </DashboardTemplate>
  );
}

export default ShowStudent;
