import React from 'react';

import { TailSpin } from 'react-loader-spinner';
import imgBackground from '../../assets/background-login.png';
import logo from '../../assets/logo.png';
import { Container, ContainerLogin, ImgBackground, Logo } from './styles';

function LoadingPage() {
  return (
    <Container>
      <ImgBackground src={imgBackground} />
      <Logo src={logo} />
      <ContainerLogin>
        <TailSpin height="50" width="50" color="#5BAAB3" ariaLabel="" />
      </ContainerLogin>
    </Container>
  );
}

export default LoadingPage;
