import React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import imgBackground from "../../../assets/background-login.png";
import logo from "../../../assets/logo.png";
import Button from "../../../components/forms/Button";
import InputText from "../../../components/forms/InputText";
import {
  Container,
  ContainerCertifyValidation,
  Form,
  FormFooter,
  ImgBackground,
  Logo,
  Title,
  InputContainer,
  BtnContainer
} from "./styles";
import masks from "../../../lib/masks";
import time from "../../../lib/time";
import ValidateFooter from "../../../components/ValidateFooter";

function ValidationConfirmed() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const submit = async (e) => {
    e.preventDefault();
    navigate("/validar-certificado", { replace: true });
  };
  return (
    <Container>
      <ImgBackground src={imgBackground} />
      <Logo src={logo} />
      <ContainerCertifyValidation>
        <Title>Certificado Válido!</Title>

        <Form onSubmit={submit}>
          <InputContainer>
            <InputText
              label="Código do certificado"
              labelProps={{ style: { fontSize: "1rem" } }}
              inputProps={{
                type: "text",
                value: state.hash,
                disabled: true,
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputText
              label="Nome do aluno"
              labelProps={{ style: { fontSize: "1rem" } }}
              inputProps={{
                type: "text",
                value: state.studentName,
                disabled: true,
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputText
              label="Curso"
              labelProps={{ style: { fontSize: "1rem" } }}
              inputProps={{
                type: "text",
                value: state.course,
                disabled: true,
              }}
            />
          </InputContainer>
          <InputContainer>
            <InputText
              label="Data de emissão"
              labelProps={{ style: { fontSize: "1rem" } }}
              inputProps={{
                type: "text",
                value: state.issueDate,
                disabled: true,
              }}
            />
          </InputContainer>
          <BtnContainer>
            <FormFooter>
              <Button label={"Voltar"} buttonProps={{ type: "submit" }} />
            </FormFooter>
          </BtnContainer>
        </Form>
      </ContainerCertifyValidation>
      <ValidateFooter />
    </Container>
  );
}

export default ValidationConfirmed;
