import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
`;
export const MainContent = styled.div`
  display: flex;
`;
export const HeaderContent = styled.div`
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const DivLogo = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px 0;
`;
export const Logo = styled.img`
  height: 100%;
`;

export const MenuIcon = styled.img`
  height: 30px;
  margin-right: 10px;
`;

export const SideBarContainer = styled.div`
  height: calc(100vh - 60px);
  background-color: #244c59;
  width: 250px;
  padding: 20px 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
  @media (max-width: 768px) {
    z-index: 999;
    position: absolute;
  }
`;


export const UserName = styled.span`
margin-right: 10px;
font-size: 15px`;

export const MainList = styled.ul``;
export const FooterList = styled.ul``;
export const Body = styled.div`
  background-color: #f8fafc;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 70px);
`;

export const MainItem = styled.li(
  (props) => css`
    display: flex;
    align-items: center;

    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    ${props.$active &&
    css`
      background-color: #3e606c;
    `}
    &:hover {
      background-color: #3e606c;
    }
  `
);
export const MainIcon = styled.img`
  width: 20px;
  margin-right: 10px;
`;
export const MainLabel = styled.span``;
