import ButtonBack from "../../../../components/forms/ButtonBack"
import { Body, Container, Header, ResumeContainer, ResumeText, Title } from "./styles"

const ConfirmSend = ({ data = {}, setShowResume }) => {
    const {
        total, course, classe, template, type, issueDate, sendMail,

    } = data
    return <Container>
        <Header>
            <ButtonBack />
            <Title>Certificados emitidos com sucesso!</Title>

        </Header>
        <Body style={{ padding: 40 }}>
            <ResumeContainer>
                <ResumeText>Total de certificados emitidos:</ResumeText> {total}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Curso:  </ResumeText>{course}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Turma: </ResumeText>            {classe}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Template usado: </ResumeText>{template === 'course' ? "Curso" : "Turma"}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Data de emissão:  </ResumeText>{issueDate}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Enviado por email: </ResumeText>{sendMail ? "Sim" : "Não"}
            </ResumeContainer>
            <ResumeContainer>
                <ResumeText>Fonte de dados: </ResumeText>{type === "class" ? "Turma inteira" : "Inserção por csv ou banco de dados"}
            </ResumeContainer>
        </Body>
    </Container>
}

export default ConfirmSend