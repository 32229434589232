import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ImgBackground = styled.img`

  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  z-index: 0;
  
  `;
export const Logo = styled.img`
z-index:1;
width: calc(200px + 10%);
margin: 20px 0;
`
export const ContainerForgotPassword = styled.div`
  z-index: 1;
  min-width: 300px;
  width: 30%;
  min-width: 360px;
  /* height: 500px;*/
  background-color: #fff;
  display: flex;
  flex-direction: column;
  
  padding: 40px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  @media (max-width:760px){
    padding: 20px;
  }
`
export const Form = styled.form``
export const FormFooter = styled.div`
margin: 30px 0 20px 0;
`
export const Login = styled.div`
color: #A8AEB9;
margin-bottom: 10px;
text-align: center;
& span{
  color: #5BAAB3;
  cursor: pointer;
  
}
& span:hover{
  font-weight: 600;
  
}
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
  `
export const Description = styled.span`
color: #9CA3AF;
font-weight: 400;
font-size: 13px;

`