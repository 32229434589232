import React from 'react';
import { Container, Content, Subtitle, Title, Text } from './styles';
import CopyText from '../../forms/CopyText';

function RolesCertificate() {
  return (
    <Container>
      <Title>Variáveis</Title>
      <Content>
        <Subtitle>Turma</Subtitle>
        <Text>
          Ementa da turma = <CopyText>%turma_ementa%</CopyText>
        </Text>

        <Text>Nome da turma = <CopyText>%turma_nome%</CopyText></Text>
        <Text>
          Carga horária da turma=<CopyText>%turma_carga_horaria% </CopyText>
        </Text>

        <Subtitle>Curso</Subtitle>
        <Text>
          Nome do curso = <CopyText>%curso_nome%</CopyText>
        </Text>
        <Text>
          Descrição do curso = <CopyText>%curso_descricao%</CopyText>
        </Text>
        <Subtitle>Aluno</Subtitle>
        <Text>
          Nome do aluno= <CopyText>%aluno_nome%</CopyText>
        </Text>
        <Text>
          CPF/CNPJ do aluno = <CopyText>%aluno_cpf_cnpj% </CopyText>
        </Text>
        <Text>
          E-mail do aluno = <CopyText>%aluno_email%</CopyText>
        </Text>
        <Text>
          Telefone do aluno = <CopyText>%aluno_telefone%</CopyText>
        </Text>
        <Subtitle>Certificado</Subtitle>
        <Text>
          Data de emissão = <CopyText>%data_emissao%</CopyText>
        </Text>
      </Content>
    </Container>
  );
}

export default RolesCertificate;
