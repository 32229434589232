import React from 'react';

import { Container, Label } from './styles';
import Select from 'react-select';

function SelectShowInactive({ inputProps, containerProps, notLabel }) {
  return (
    <Container {...containerProps}>
      {!notLabel && <Label>Filtrar:</Label>}
      <Select
        {...inputProps}
        options={[
          { value: true, label: 'Ativos' },
          { value: false, label: 'Inativos' },
        ]}
        defaultValue={{ value: true, label: 'Ativos' }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            minWidth: '130px',
          }),
        }}
      />
    </Container>
  );
}

export default SelectShowInactive;
