import React, { useEffect, useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import { useParams } from 'react-router-dom';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { myAlert } from '../../../../lib/Alert';
import adminCoursesService from '../../../../services/adminCoursesService';
import { InputImage } from '../CreateCourse/styles';
import InputTextArea from '../../../../components/forms/InputTextArea';
import RolesCertificate from '../../../../components/certificate/RolesCertificate';
import { useNavigate } from "react-router-dom";

function UpdateCourseContent() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: "",
    description: "",
    url: "",
    certificate: {
      text: "",
    },
  });
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [certificateText, setCertificateText] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageFront, setImageFront] = useState({});
  const [imageBack, setImageBack] = useState({});
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);
  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);

      reader.readAsDataURL(file);
    });
  };

  const onChangeFile = async (part = "front") => {
    const currFile = part === "front" ? fileFrontRef : fileBackRef;
    if (currFile.current.files[0]) {
      const url = await readURL(currFile.current.files[0]);

      if (part === "front") {
        setImageFront({ url: url, file: currFile.current.files[0] });
        return;
      }

      setImageBack({ url: url, file: currFile.current.files[0] });
    }
  };
  let { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminCoursesService.show(id);
        setData(res.data);
        setTitle(res.data.title);
        setDescription(res.data.description);
        setUrl(res.data.url);
        setCertificateText(res.data.certificate && res.data.certificate.text);
        setLoading(false);

        setLoading(false);
      } catch (error) {
        console.log(error);
        myAlert.open({
          title:
            "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
          type: "error",
        });
        setLoading(false);
      }
    };
    getData();
  }, [id]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      await adminCoursesService.update({
        title,
        description,
        url,
        certificate: { text: certificateText },
        fileFront: imageFront.file,
        fileBack: imageBack.file,
        id,
      });
      myAlert.open({ title: "Curso atualizado com sucesso!", type: "success" });

      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({
        title: "Falha ao atualizar curso. Tente novamente mais tarde",
        type: "error",
      });

      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Atualização do Curso</Title>
        <SubTitle>
          Você está atualizando as informações de <b>{data.title}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => setTitle(e.target.value),
              }}
            />
            <InputText
              label="URL da Página do Curso"
              inputProps={{
                type: "text",
                value: url,
                onChange: (e) => setUrl(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Descrição do curso"
              inputProps={{
                type: "text",
                value: description,
                onChange: (e) => setDescription(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Frase padrão"
              inputProps={{
                type: "text",
                value: data.certificate && data.certificate.text,
                onChange: (e) => setCertificateText(e.target.value),
              }}
            />
            <RolesCertificate />
          </RowInput>
          <RowInput>
            <InputImage
              {...{
                type: "file",
                ref: fileBackRef,
                onChange: () => onChangeFile("back"),
                id: "image-certificate-fundo",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <InputImage
              {...{
                type: "file",
                ref: fileFrontRef,
                onChange: () => onChangeFile("front"),
                id: "image-certificate",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <ViewCertificate
              {...{
                front: {
                  internalUrl: imageFront && imageFront.url,
                  url:
                    imageFront && imageFront.url
                      ? imageFront.url
                      : data && data.certificate && data.certificate.front
                        ? data.certificate.front.file
                        : "",
                  text: certificateText,
                  forId: "image-certificate",
                  helperTexts: [
                    "O hash do arquivo só poderá ser visualizado após a geração do certificado",
                    "Variáveis de turma serão visíveis apenas em turmas ou após a geração do certificado",
                    "Variáveis de aluno serão visíveis após a geração do certificado",
                  ],
                },
                back: {
                  internalUrl: imageBack && imageBack.url,
                  url:
                    imageBack && imageBack.url
                      ? imageBack.url
                      : data && data.certificate && data.certificate.back
                        ? data.certificate.back.file
                        : "",

                  forId: "image-certificate-fundo",
                  helperTexts: [
                    "O hash do arquivo só poderá ser visualizado após a geração do certificado",
                    "A ementa será visível apenas em turmas ou após a geração do certificado",
                  ],
                },
              }}
            />
          </RowInput>
          <ButtonContainer>
            <Button
              label="Atualizar"
              loading={loading}
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function UpdateCourse() {
  return (
    <DashboardTemplate>
      <UpdateCourseContent />
    </DashboardTemplate>
  );
}

export default UpdateCourse;
