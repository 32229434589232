import React, { useEffect, useState } from 'react';
import InputSearchBar from '../../../../components/forms/InputSearchBar';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  ActionIcon,
  ActiveTag,
  Body,
  BodyHeader,
  Container,
  ContainerSearch,
  Header,
  SubTitle,
  Title,
  TagContainer,
  HeaderDiv,
} from './styles';
import Select from 'react-select';

import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyColumn,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';

import iconReload from '../../../../assets/reload.svg';
import DeleteModal from '../../../../components/modals/DeleteModal';
import adminStudentsService from '../../../../services/adminStudentsService';
import { useNavigate } from 'react-router-dom';
import iconEdit from '../../../../assets/edit.svg';
import iconTrash from '../../../../assets/trash.svg';
import iconView from '../../../../assets/view.svg';
import Button from '../../../../components/forms/Button';
import { myAlert } from '../../../../lib/Alert';
import { Label } from '../../../../components/forms/InputSearchBar/styles';
import SelectShowInactive from '../../../../components/forms/SelectShowInactive';
import Pagination from '../../../../components/Pagination';
import tags from '../../../../constants/tags';
// import { Container } from './styles';

function ListStudentContent() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [showInactive, setShowInactive] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [tagSelect, setTagSelect] = useState();

  const [rowsPageSelected, setRowsPageSelected] = useState(1);
  const rowsPage = [5, 10, 20, 50];
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    perPage: 5,
    total: 0,
  });

  const [students, setStudents] = useState([]);
  const getStudents = async ({ page = 1, perPage = 10 }) => {
    try {
      setLoading(true);
      const toSend = {
        find: search,
        isActive: showInactive,
        page,
        perPage,
      };
      if (tagSelect) {
        toSend.tag = tagSelect;
      }

      const res = await adminStudentsService.list(toSend);
      setStudents(res.data);
      const lastPage = res.total > res.perPage ? Math.ceil(res.total / res.perPage) : 1;

      setPagination({
        lastPage,
        page: res.page,
        perPage: res.perPage,
        total: res.total,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({
        title: 'Erro ao buscar dados. Recarregue a página ou contate o suporte.',
        type: 'error',
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    getStudents({});
  }, [search, loading2, showInactive, tagSelect]);
  const tableHeaderItems = [
    {
      label: 'Nome',
      flex: 1,
    },
    {
      label: 'E-mail',
      flex: 1.3,
    },
    {
      label: 'Tags',
      flex: 1,
    },
    {
      label: 'Status',
      flex: 1,
    },
    {
      label: 'Ações',
      flex: 1,
    },
  ];
  const handleChangeStatusItem = async ({ id, toActive, title }) => {
    try {
      setLoading2(true);
      await adminStudentsService.update({
        isActive: toActive,
        id,
      });

      myAlert.open({
        title: `Status de ${title} alterado com sucesso`,
        type: 'success',
      });
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      myAlert.open({
        title: `Erro ao alterar status ${title}`,
        type: 'error',
      });
    }
  };
  const syncStudents = async () => {
    try {
      setLoading3(true)
      const res = await adminStudentsService.sync()
      setLoading3(false)
      myAlert.open({ title: "Sincronização do alunos em andamento. Ela pode levar alguns minutos", type: 'success' })
    } catch (error) {
      setLoading3(false)
      myAlert.open({ title: "Erro ao sincronizar alunos", type: 'error' })
      console.log(error);

    }
  }
  return (
    <Container>
      <Header>
        <HeaderDiv>
          <Title>Alunos</Title>
          <Button
            label="Sincronizar Alunos"
            loading={loading3}
            onClick={syncStudents}
            containerStyle={{ width: '200px' }}
          />
        </HeaderDiv>
        <SubTitle>
          Encontre todos os alunos cadastrados na plataforma. Selecione visualizar para ver mais
          informações
        </SubTitle>
      </Header>
      <Body>
        <BodyHeader
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <Container
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ContainerSearch>
              <InputSearchBar
                loading={loading}
                inputProps={{
                  type: 'text',
                  placeholder: 'Pesquise por nome ou email',
                  onChange: (e) => setSearch(e.target.value),
                }}
              />
              {/* <SelectShowInactive
              inputProps={{
                onChange: (e) => setShowInactive(e.value),
              }}
              /> */}
            </ContainerSearch>
            <Button
              label="Adicionar novo Aluno"
              onClick={() => {
                navigate(`/students/create`);
              }}
            />
          </Container>
          <Container
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              flexWrap: 'wrap',
              margin: '10px 0 10px 0',
            }}
          >
            <SelectShowInactive
              containerProps={{
                style: {
                  marginLeft: '0',
                },
              }}
              inputProps={{
                onChange: (e) => setShowInactive(e.value),
              }}
            />
            <Select
              isClearable
              options={tags.array.map((item) => ({
                value: item.key,
                label: item.label,
              }))}
              placeholder="Tags"
              onChange={(e) => {
                if (e) {
                  setTagSelect(e.value);
                } else {
                  setTagSelect();
                }
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: '230px',
                }),
              }}
            />
          </Container>
        </BodyHeader>
        {showDelete && (
          <DeleteModal
            {...{
              show: showDelete,
              setShow: setShowDelete,
              title: modalContent.title || '',
              action: modalContent.action,
              label: modalContent.label,
            }}
          />
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHeader>
              {tableHeaderItems.map((item, idx) => (
                <TableHeaderColumn key={idx} flex={item.flex}>
                  {item.label}
                </TableHeaderColumn>
              ))}
            </TableHeader>

            <TableBody>
              {students.length > 0 &&
                students.map((item, idx) => (
                  <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                    <TableBodyColumn>{item.fullname}</TableBodyColumn>
                    <TableBodyColumn flex={1.3}>{item.email}</TableBodyColumn>
                    <TableBodyColumn $overflowX>
                      {item.tags &&
                        item.tags.length > 0 &&
                        item.tags.map((label, idx) => (
                          <TagContainer
                            key={idx}
                            style={{ backgroundColor: tags.keys[label].color, marginRight: 4 }}
                          >
                            {tags.keys[label].label}
                          </TagContainer>
                        ))}
                    </TableBodyColumn>
                    <TableBodyColumn>
                      <ActiveTag $active={item.isActive}>
                        {item.isActive ? 'Ativo' : 'Inativo'}
                      </ActiveTag>
                    </TableBodyColumn>
                    <TableBodyColumn>
                      <ActionIcon
                        src={iconView}
                        onClick={() => {
                          navigate(`/students/${item._id}`);
                        }}
                      />
                      {showInactive ? (
                        <ActionIcon
                          src={iconEdit}
                          onClick={() => {
                            navigate(`/students/edit/${item._id}`);
                          }}
                        />
                      ) : null}
                      {showInactive ? (
                        <ActionIcon
                          src={iconTrash}
                          onClick={async () => {
                            setModalContent({
                              title: `Deseja desativar o aluno ${item.fullname}?`,
                              action: () =>
                                handleChangeStatusItem({
                                  title: item.fullname,
                                  id: item._id,
                                  toActive: !item.isActive,
                                }),
                              label: 'Desativar',
                            });
                            setShowDelete(true);
                          }}
                        />
                      ) : (
                        <ActionIcon
                          src={iconReload}
                          onClick={async () => {
                            setModalContent({
                              title: `Deseja reativar o aluno ${item.fullname}?`,
                              label: 'Reativar',
                              action: () =>
                                handleChangeStatusItem({
                                  title: item.fullname,
                                  id: item._id,
                                  toActive: !item.isActive,
                                }),
                            });
                            setShowDelete(true);
                          }}
                        />
                      )}
                    </TableBodyColumn>
                  </TableBodyRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <Container
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#244c59',
          }}
        >
          <Pagination
            {...{
              ...pagination,
              rowsPageSelected,
              setRowsPageSelected,
              rowsPage,
              filter: { find: search, isActive: showInactive },
              getData: getStudents,
            }}
          />
          {`${(pagination.page - 1) * pagination.perPage + 1}-${Math.min(
            pagination.page * pagination.perPage,
            pagination.total
          )} de ${pagination.total}`}
        </Container>
      </Body>
    </Container>
  );
}
function ListStudent() {
  return (
    <DashboardTemplate>
      <ListStudentContent />
    </DashboardTemplate>
  );
}

export default ListStudent;
