import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  CertifyImage,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/forms/Button';
import { useNavigate } from 'react-router-dom';
import InputTextArea from '../../../../components/forms/InputTextArea';
import adminChatsService from '../../../../services/adminChatsService';

function ShowChatContent() {
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState(false);
  const [data, setData] = useState({
    title: '',
    fixedMessage: '',
    createdAt: '',
    id: '',
  });
  const [loading, setLoading] = useState(false);
  let { id } = useParams();

  function dateFormatter(dateString) {
    const data = new Date(dateString);
  
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const hora = String(data.getHours()).padStart(2, '0');
    const minuto = String(data.getMinutes()).padStart(2, '0');
    const segundo = String(data.getSeconds()).padStart(2, '0');
  
    return `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;
  }

  const getData = async () => {
    try {
      setLoading(true);
      const res = await adminChatsService.show(id);
      const formatRes = {
        title: res.data.roomName,
        fixedMessage: res.data.fixedMessage.content,
        createdAt: dateFormatter(res.data.createdAt),
        id: res.data._id,
      }
      setData(formatRes);
      console.log(res.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

    useEffect(() => {
        getData();
        }
    , []);
  
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Visualização de Chat</Title>
        <SubTitle>
          Você está visualizando as informações de <b>{data.title}</b>
        </SubTitle>
        <br />
        <br />
        <SubTitle>
          Para acessar o chat <a href={`/aula/chat/${data.id}`}>clique Aqui</a>
        </SubTitle>
      </Header>
      <Body>
        <Form>
          <RowInput>
            <InputText
              label="Nome do chat"
              inputProps={{
                type: 'text',
                value: data.title,
                disabled: true,
              }}
            />
            <InputText
            label="Identificador"
            inputProps={{
                type: 'text',
                value: data.id,
                disabled: true,
            }}
            />
          </RowInput>
          <RowInput>
            <InputText
                label="Data de criação"
                inputProps={{
                    type: 'text',
                    value: data.createdAt,
                    disabled: true,
                }}
                />
            </RowInput>
          <RowInput>
            <InputTextArea
              label="Mensagem fixada"
              inputProps={{
                type: 'text',
                value: data.fixedMessage,
                disabled: true,
              }}
            />
          </RowInput>
          
          <ButtonContainer>
            <Button
              loading={loading}
              label="Editar"
              onClick={() => {
                navigate(`/chats/edit/${id}`);
              }}
              containerStyle={{ width: '200px' }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function ShowChat() {
  return (
    <DashboardTemplate>
      <ShowChatContent />
    </DashboardTemplate>
  );
}

export default ShowChat;
