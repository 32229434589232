import React, { useEffect, useState } from 'react';

import {
  BackContainer,
  CenterText,
  Container,
  FrontContainer,
  HashText,
  ImageContainer,
  LeftText,
  LinkText,
  Title,
  UploadFile,
  TextHelper,
  ContainerHelperText,
  HashTitle, RightText
} from './styles';
import serverConst from '../../../constants/serverConst';
import uploadImg from '../../../assets/upload.svg';

const formatDefaultText = ({ text, course, classe, student, issueDate }) => {
  if (!text) return;
  let newText = `${text}`;
  newText = newText
    .replaceAll('%aluno_telefone%', (student && student.phone) || 'TELEFONE_DO_ALUNO')
    .replaceAll('%aluno_cpf_cnpj%', (student && student.cpfCnpj) || 'NOME_DO_CPF_CNPJ')
    .replaceAll('%aluno_email%', (student && student.email) || 'EMAIL_ALUNO')
    .replaceAll('%aluno_nome%', (student && student.fullname) || 'NOME_ALUNO')
    .replaceAll('%curso_nome%', (course && course.title) || "NOME_CURSO")
    .replaceAll('%curso_descricao%', (course && course.description) || "DESCRICAO_CURSO")
    .replaceAll('%turma_carga_horaria%', (classe && classe.workload) || "CARGA_HORARIA")
    .replaceAll('%turma_nome%', (classe && classe.title) || "NOME_CURSO")
    .replaceAll('%turma_ementa%', (classe && classe.program) || "EMENTA")
    .replaceAll('%data_emissao%', issueDate || "DATA_EMISSAO");

  return newText;
};
const calculateWidth = (val) => parseFloat((val * 595) / 852).toFixed(2);
function ViewCertificate({
  internalUrl,
  front,
  back,
  hash,
  validateLink,
  course,
  classe,
  student,
  upload,
  issueDate
}) {
  const [width, setWidth] = useState(window.innerWidth > 700 ? 800 : 300);
  const [height, setHeight] = useState(calculateWidth(window.innerWidth > 700 ? 800 : 300) + 'px');
  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth > 700 ? 800 : 300);
      setHeight(calculateWidth(window.innerWidth > 700 ? 800 : 300) + 'px');
    };

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  return (
    <Container >
      <Title>Imagem Frontal</Title>
      <FrontContainer
        {...{ htmlFor: front.forId, $clickable: front.forId, style: { width, height } }}
      >
        {upload && <UploadFile alt="Escolher arquivo" src={uploadImg} />}
        {front.url && (
          <ImageContainer
            style={{ width, height }}
            src={front.internalUrl ? front.url : `${serverConst.imgUrl}/${front.url}`}
          />
        )}
        <CenterText>
          {formatDefaultText({ text: front.text, course, classe, student, issueDate }) ||
            'Texto principal do certificado aqui'}
        </CenterText>

        <HashTitle>Código de validação do certificado</HashTitle>
        <HashText>{hash || 'e253326c-9fe0-4c61-8782-3a3dccb1321f'}</HashText>
        {validateLink && <LinkText>{hash}</LinkText>}
      </FrontContainer>
      {front.helperTexts && front.helperTexts.length > 0 && (
        <ContainerHelperText>
          <TextHelper> Obs: </TextHelper>
          {front.helperTexts.map((item, idx) => (
            <TextHelper key={idx}>{`${idx + 1} - ${item}`}</TextHelper>
          ))}
        </ContainerHelperText>
      )}
      <br />
      <Title>Imagem de trás</Title>
      <BackContainer {...{ htmlFor: back.forId, $clickable: back.forId, style: { width, height } }}>
        {upload && <UploadFile alt="Escolher arquivo" src={uploadImg} />}
        {back.url && (
          <ImageContainer
            htmlFor={back.forId}
            $active={back.forId}
            style={{ width, height }}
            src={back.internalUrl ? back.url : `${serverConst.imgUrl}/${back.url}`}
          />
        )}
        {classe && classe.program && (
          <RightText>
            {formatDefaultText({ text: classe.program, classe, course, student, })}
          </RightText>
        )}

        <HashTitle>Código de validação do certificado</HashTitle>
        <HashText>{hash || 'e253326c-9fe0-4c61-8782-3a3dccb1321f'}</HashText>
        {/* {validateLink && <LinkText>{hash}</LinkText>} */}
      </BackContainer>
      {back.helperTexts && back.helperTexts.length > 0 && (
        <ContainerHelperText>
          <TextHelper> Obs: </TextHelper>
          {back.helperTexts.map((item, idx) => (
            <TextHelper key={idx}>{`${idx + 1} - ${item}`}</TextHelper>
          ))}
        </ContainerHelperText>
      )}
    </Container>
  );
}

export default ViewCertificate;
