import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;
`;

export const Label = styled.label`
  margin: 20px 0 8px 0;
  font-size: 13px;
`;
export const OptionsContainer = styled.div`
  display: flex;
    flex-wrap: wrap;
`;
export const OptionContainer = styled.div(
  (props) => css`
    display: flex;
    align-items: center;
    margin: 10px 15px 0 0;
    ${!props.$disabled &&
    css`
      cursor: pointer;
    `}
  `
);
export const Box = styled.div(
  (props) => css`
    width: 15px;
    height: 15px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #ced4da;
    border-radius: 3px;
    & img {
      width: 10px;
    }
    ${!props.$status &&
    css`
      & img {
        display: none;
      }
    `}
  `
);
export const OptionLabel = styled.span`
  font-size: 14px;
`;
