import React from 'react';

import { Container, Paragraph, Copy } from './styles';

import CopySVG from '../../../assets/copy.svg';
import { myAlert } from '../../../lib/Alert';

function CopyText({ children, text, paragraphStyle }) {
  return (
    <Container
      onClick={
        (e) => {
          if (text) {
            navigator.clipboard.writeText(text);
            myAlert.open({ title: `Conteúdo copiado`, type: "success" })
          } else {
            navigator.clipboard.writeText(children);
            myAlert.open({ title: `${children} - copiado`, type: "success" })
          }

        }
      }
    >
      <Paragraph style={{ ...paragraphStyle }}>
        {children}
      </Paragraph>
      <Copy src={CopySVG} alt="copy"></Copy>

    </Container >
  );
}

export default CopyText;
