import React from 'react';

import { Container, Input, Label } from './styles';

function InputTextArea({ inputProps, label }) {
  return (
    <Container>
      <Label for={inputProps.name}>{label}</Label>
      <Input {...inputProps} />
    </Container>
  );
}

export default InputTextArea;
