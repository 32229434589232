import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
  TitleContainer
} from './styles';

import { useNavigate, useParams } from 'react-router-dom';
import downloadIcon from '../../../../assets/download.svg';
import downloadWhiteIcon from '../../../../assets/download_white.svg';
import mailIcon from '../../../../assets/mailIcon_blue.svg';
import mailWhiteIcon from '../../../../assets/mailIcon_white.svg';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { myAlert } from '../../../../lib/Alert';
import files from '../../../../lib/files';
import time from '../../../../lib/time';
import adminCertificateService from '../../../../services/adminCertificateService';

function ShowCertifyContent() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    certificate: {
      text: '',
      front: { file: '' },
      back: { file: '' },
    },
  });

  const [imageFront, setImageFront] = useState('');
  const [imageBack, setImageBack] = useState('');

  const [textTemplate, setTextTemplate] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  let { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const res = await adminCertificateService.show(id);
        setData(res.data);

        let text = '';
        if (res.data.template === 'course') {
          setImageFront(res.data.course.certificate.front.file);
          setImageBack(res.data.course.certificate.back.file);
          text = `${res.data.course.certificate.text}`;
        }
        if (res.data.template === 'class') {
          setImageFront(res.data.class.certificate.front.file);
          setImageBack(res.data.class.certificate.back.file);
          text = `${res.data.class.certificate.text}`;
        }

        setTextTemplate(`${text}`);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          myAlert.open({
            title: 'Erro ao buscar informação. Recarregue a página ou contate o suporte.',
            type: 'error',
          });
          setLoading(false);
        }, 500);
      }
    };
    getData();
  }, [id]);

  const handleDownload = async () => {
    try {
      setLoadingDownload(true);
      if (!data.hash) {
        myAlert.open({ title: 'Hash do certificado não encontrado', type: 'error' });
        setLoadingDownload(false);
        return;
      }
      const download = await adminCertificateService.downloadCertificate(data.hash);
      const byteCharacters = files.convertBase64ToBlob(download);
      const url = window.URL.createObjectURL(byteCharacters);
      window.open(url);
      myAlert.open({ title: 'Download realizado com sucesso', type: 'success' });
      setLoadingDownload(false);
    } catch (error) {
      setLoadingDownload(false);
      console.log(error);
      myAlert.open({ title: 'Erro ao realizar download', type: 'error' });
    }
  };
  const handleResend = async () => {
    try {
      setLoadingSendEmail(true);

      if (!data.hash) {
        myAlert.open({ title: 'Hash do certificado não encontrado', type: 'error' });
        setLoadingSendEmail(false);
        return;
      }
      await adminCertificateService.resendCertificateEmail({ hash: data.hash });

      myAlert.open({ title: 'Certificado reenviado com sucesso', type: 'success' });

      setLoadingSendEmail(false);
    } catch (error) {
      setLoadingSendEmail(false);
      console.log(error);
      myAlert.open({ title: 'Erro ao enviar certificado', type: 'error' });
    }
  };
  return (
    <Container>
      <Header>
        <ButtonBack />
        <TitleContainer>
          <Title>Visualização de Certificado</Title>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Button
              {...{
                loading: loadingDownload,
                label: 'Baixar',
                onClick: handleDownload,
                iconHover: downloadWhiteIcon,
                icon: downloadIcon,
                containerStyle: { width: '200px' },
              }}
            />
            <Button
              label="Reenviar por email"
              iconHover={mailWhiteIcon}
              icon={mailIcon}
              loading={loadingSendEmail}
              onClick={handleResend}
              containerStyle={{ width: '200px' }}
            />
          </div>
        </TitleContainer>
        <SubTitle>
          Você está visualizando as informações de <b>{data.title}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: 'text',
                value: data.student && data.student.fullname,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="CPF/CNPJ"
              copyValue
              inputProps={{
                type: 'text',
                value: data.student && data.student.cpfCnpj,
                disabled: true,
              }}
            />
            <InputText
              label="E-mail"
              copyValue
              inputProps={{
                type: 'text',
                value: data.student && data.student.email,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="ID de Validação"
              copyValue
              inputProps={{ type: 'text', value: data.hash, disabled: true }}
            />
            <InputText
              label="Nome do Curso"
              copyValue
              inputProps={{
                type: 'text',
                value: data.course && data.course.title,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Nome da Turma"
              copyValue
              inputProps={{
                type: 'text',
                value: data.class && data.class.title,
                disabled: true,
              }}
            />
            <InputText
              label="Data de emissão"
              copyValue
              inputProps={{
                type: 'text',
                value: data.issueDate,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Criado Em"
              inputProps={{
                type: 'text',
                value: time.date2String(data.createdAt),
                disabled: true,
              }}
            />
            <InputText
              label="Atualizado Em"
              inputProps={{
                type: 'text',
                value: time.date2String(data.updatedAt),
                disabled: true,
              }}
            />

          </RowInput>


          <RowInput>
            <ViewCertificate
              {...{
                course: data.course,
                classe: data.class,
                student: data.student,
                hash: data.hash,
                issueDate: data.issueDate,
                front: {
                  url: imageFront,
                  text: textTemplate,
                },
                back: {
                  url: imageBack,
                },
              }}
            />
          </RowInput>
        </Form>
        <ButtonContainer>
          <Button
            loading={loading}
            label="Editar"
            onClick={() => {
              navigate(`/certificates/edit/${data._id}`);
            }}
            containerStyle={{ width: '200px' }}
          />
        </ButtonContainer>
      </Body>
    </Container>
  );
}
function ShowCertify() {
  return (
    <DashboardTemplate>
      <ShowCertifyContent />
    </DashboardTemplate>
  );
}

export default ShowCertify;
