import React from 'react';

import { useNavigate } from 'react-router-dom';
import backIcon from '../../../assets/arrow-back.svg';
import { BackContainer, BackIcon } from './styles';

function ButtonBack({ onClick }) {
  const navigate = useNavigate();
  return (
    <BackContainer onClick={() => {
      if (onClick) onClick()
      else navigate(-1)
    }}>
      <BackIcon src={backIcon} /> Voltar
    </BackContainer >
  );
}

export default ButtonBack;
