import React from 'react';

import {
  Body,
  CloseContainer,
  ContainerModal,
  Footer,
  Header,
  Modal,
  TextContent,
  Title,
} from './styles';
import Button from '../../forms/Button';
import iconClose from '../../../assets/close.svg';
import { ActionIcon } from '../../../pages/dashboard/students/ListStudent/styles';

function DeleteModal({ type = 'error', setShow, title, action, label }) {
  return (
    <ContainerModal>
      <CloseContainer onClick={() => setShow(false)} />
      <Modal>
        <Header>
          <Title>Atenção!</Title>
          <ActionIcon src={iconClose} onClick={() => setShow(false)} />
        </Header>
        <Body>
          <TextContent>{title}</TextContent>
        </Body>
        <Footer>
          <Button label="Cancelar" onClick={() => setShow(false)} />
          <Button
            label={label}
            type={type}
            onClick={async () => {
              if (action) await action();
              setShow(false);
            }}
          />
        </Footer>
      </Modal>
    </ContainerModal>
  );
}

export default DeleteModal;
