import React from 'react';

import { Footer, Link } from './styles';

function ValidateFooter() {
  return (
    <Footer>
      <p>INCANTATO EDUCAÇÃO - CNPJ: 31.248.548/0001-89</p>
      <Link href="https://incantatopsicologia.com.br/termos-gerais-de-uso/" target='_blank' rel="noopener noreferrer"  >TERMOS GERAIS DE USO</Link>
      <Link href="https://incantatopsicologia.com.br/politica-de-privacidade/" target='_blank' rel="noopener noreferrer"  >AVISO DE PRIVACIDADE</Link>
    </Footer>
  );
}

export default ValidateFooter;
