import { handleApi } from './server';

const adminUsersService = {
  update: async (data) => {
    const { email, fullName, id, roles, isActive } = data;
    const response = await handleApi('/admin/users/' + id, 'put', {
      data: { email, fullName, roles, isActive },
    });
    return response;
  },
  create: async (data) => {
    const { email, fullName, password, passwordConfirm, roles } = data;
    const response = await handleApi('/admin/users', 'post', {
      data: { email, fullName, password, passwordConfirm, roles },
    });
    return response.data;
  },
  list: async (data) => {
    const params = {
      ...data,
    };
    return await handleApi('/admin/users', 'get', {
      data,
      params,
    });
  },
  show: async (id) => {
    return await handleApi('/admin/users/' + id, 'get', {});
  },
};
export default adminUsersService;
