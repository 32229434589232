import React, { useContext, useEffect, useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from "./styles";

import Button from "../../../../components/forms/Button";
import ButtonBack from "../../../../components/forms/ButtonBack";
import InputText from "../../../../components/forms/InputText";
import { myAlert } from "../../../../lib/Alert";
import { useParams } from "react-router-dom";

import InputTextArea from "../../../../components/forms/InputTextArea";
import { useNavigate } from "react-router-dom";
import adminChatsService from '../../../../services/adminChatsService';
import AuthContext from '../../../../hooks/Auth';

function UpdateChatContent() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: "",
    fixedMessage: ""
  });
  const [title, setTitle] = useState("");
  const [fixedMessage, setFixedMessage] = useState("");
  const [roomId, setRoomId] = useState("");

  
  const [updateData, setUpdateData] = useState(false);
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminChatsService.show(id);
        setData(res.data);

        console.log(res.data)

        setTitle(res.data.roomName);
        setFixedMessage(res.data.fixedMessage.content);
        setRoomId(res.data._id);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getData();
  }, [id, updateData]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);
      const toUpdate = {
        roomName: title,
        fixedMessage: {
            content: fixedMessage,
            owner: user.fullName + " (Admin)",
        },
        isActive: true,
        id,
      }
      await adminChatsService.update(toUpdate);
      myAlert.open({ title: "Chat atualizado com sucesso", type: "success" });
      navigate("/chats", { replace: true });
      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({
        title: "Falha ao atualizar chat. Tente novamente mais tarde",
        type: "error",
      });

      setLoading(false);
    }
  }
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Atualização Chat</Title>
        <SubTitle>
          Você está atualizando as informações de <b>{data.roomName}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome do chat"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => setTitle(e.target.value),
              }}
            />
            <InputText
            label="Identificador"
            inputProps={{
                type: 'text',
                value: roomId,
                disabled: true,
            }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Mensagem fixada (opcional)"
              inputProps={{
                type: "text",
                value: fixedMessage,
                onChange: (e) => setFixedMessage(e.target.value),
              }}
            />
          </RowInput>

          <ButtonContainer>
            <Button
              label="Atualizar"
              loading={loading}
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function UpdateChat() {
  return (
    <DashboardTemplate>
      <UpdateChatContent />
    </DashboardTemplate>
  );
}

export default UpdateChat;
