import styled from 'styled-components';

export const Container = styled.div`
  min-width: 30%;
`;

export const Title = styled.p`
  margin: 20px 0 8px 0;
  font-size: 13px;
`;
export const Content = styled.div``;
export const Subtitle = styled.p`
  margin: 15px 0 5px 0;
  font-weight: bold;
  font-size: 12px;
`;
export const Text = styled.p`
  font-size: 12px;
  gap: 5px;
  display: flex;
`