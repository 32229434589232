import AlertContext from '../../../hooks/Alert';

import { myAlert } from '../../../lib/Alert';
import React, { useContext, useEffect, useState } from 'react';
import {
  AlertContainer,
  CloseIcon,
  Container,
  LeftContent,
  LinkBsc,
  RightContent,
  Subtitle,
  Title,
} from './styles';
const AlertItem = ({ type, title, subtitle, scanLink, duration = 5000 }) => {
  const [show, setShow] = useState(false);

  // * Poe temporizador no myAlert
  useEffect(() => {
    let interval;
    if (show && type !== 'info' && duration)
      interval = setTimeout(() => {
        setShow(false);
        myAlert.closeAll();
      }, duration);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 200);
  }, []);
  return (
    <AlertContainer show={show} type={type}>
      <LeftContent type={type} />
      <RightContent>
        <Title type={type}>{title}</Title>
        {subtitle && <Subtitle type={type}>{subtitle}</Subtitle>}
        {scanLink && (
          <LinkBsc href={scanLink} target="_blank">
            BSC
          </LinkBsc>
        )}

        <CloseIcon
          onClick={() => {
            setShow(false);
            myAlert.closeAll();
          }}
          type={type}
        />
      </RightContent>
    </AlertContainer>
  );
};

const Alert = () => {
  const { notifications } = useContext(AlertContext);
  return (
    <Container>
      {notifications.map((notification, idx) => (
        <AlertItem key={notification.id} {...notification} />
      ))}
    </Container>
  );
};

export default Alert;
