import React from 'react';

import Button from '../Button';
import { Container, Input } from './styles';

function InputSearchBar({ loading, inputProps, onClick, buttonProps }) {
  return (
    <Container>
      <Input {...inputProps} />
      <Button
        buttonProps={buttonProps}
        containerStyle={{ width: '100px' }}
        loading={loading}
        label={'Buscar'}
        onClick={onClick}
      />
    </Container>
  );
}

export default InputSearchBar;
