import React, { useState } from 'react';
import { DropDownContent, DropDownLi, DropDownUl, LiPagination, ListDiv, UnorderedList } from './styles';

import arrowDownIcon from '../../assets/arrow_down.svg';
import arrowLeftIcon from '../../assets/arrow_left.svg';
import arrowRightIcon from '../../assets/arrow_right.svg';

function Pagination({
  page,
  total = '',
  lastPage,
  perPage,
  rowsPage = [5, 10, 15],
  getData,
  filter,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [rowsPageSelected, setRowsPageSelected] = useState(1);

  const changePage = ({ numPage, limit }) => {
    let _page = numPage || page;

    getData({ page: limit ? 1 : _page, filter, perPage: limit || perPage });
  };
  const listPages = (total) => {
    const rowsPage = Array.from(Array(total).keys());
    const currentPage = page;

    if (lastPage <= 5) {
      return rowsPage.map((row, idx) => (
        <LiPagination
          key={row + idx}
          $active={row + 1 === currentPage}
          $waves
          onClick={() => changePage({ numPage: row + 1 })}
        >
          <span>{`${row + 1}`}</span>
        </LiPagination>
      ));
    } else {
      if (currentPage < 3) {
        const primeiraParte = rowsPage.slice(0, 3);
        return (
          <>
            {primeiraParte.map((row, idx) => (
              <LiPagination
                key={row + idx}
                $active={row + 1 === currentPage}
                $waves
                onClick={() => changePage({ numPage: row + 1 })}
              >
                <span>{`${row + 1}`}</span>
              </LiPagination>
            ))}
            <LiPagination>
              <span>...</span>
            </LiPagination>
            <LiPagination
              $active={lastPage === currentPage}
              $waves
              onClick={() => changePage({ numPage: lastPage })}
            >
              <span>{`${lastPage}`}</span>
            </LiPagination>
          </>
        );
      } else if (currentPage > lastPage - 2) {
        const ultimaParte = rowsPage.slice(-3);
        return (
          <>
            <LiPagination
              $active={1 === currentPage}
              $waves
              onClick={() => changePage({ numPage: 1 })}
            >
              <span>1</span>
            </LiPagination>
            <LiPagination>
              <span>...</span>
            </LiPagination>
            {ultimaParte.map((row, idx) => (
              <LiPagination
                key={row + idx}
                $active={row + 1 === currentPage}
                $waves
                onClick={() => changePage({ numPage: row + 1 })}
              >
                <span>{`${row + 1}`}</span>
              </LiPagination>
            ))}
          </>
        );
      } else {
        const parteMeio = rowsPage.slice(currentPage - 2, currentPage + 1);
        return (
          <>
            <LiPagination
              $active={1 === currentPage}
              $waves
              onClick={() => changePage({ numPage: 1 })}
            >
              <span>1</span>
            </LiPagination>
            <LiPagination>
              <span>...</span>
            </LiPagination>
            {parteMeio.map((row, idx) => (
              <LiPagination
                key={row + idx}
                $active={row + 1 === currentPage}
                $waves
                onClick={() => changePage({ numPage: row + 1 })}
              >
                <span>{`${row + 1}`}</span>
              </LiPagination>
            ))}
            <LiPagination>
              <span>...</span>
            </LiPagination>
            <LiPagination
              $active={lastPage === currentPage}
              $waves
              onClick={() => changePage({ numPage: lastPage })}
            >
              <span>{`${lastPage}`}</span>
            </LiPagination>
          </>
        );
      }
    }
  };
  return (
    <UnorderedList className="pagination" style={{ display: 'flex' }}>
      <LiPagination style={{ display: 'flex' }} onClick={() => setIsOpen(!isOpen)}>
        <span style={{ fontSize: '14px' }}>Linhas por página:</span>
        <div>
          <div style={{ display: 'flex' }}>
            <span style={{ paddingRight: 0 }}>{rowsPage[rowsPageSelected]}</span>

            <img width={10} style={{ margin: '0 5px' }} src={arrowDownIcon} alt="arrow down" />
          </div>
          {isOpen && (
            <DropDownContent>
              <DropDownUl>
                {rowsPage.map((row, idx) => (
                  <DropDownLi
                    key={row + idx}
                    $active={idx === rowsPageSelected}
                    onClick={() => {
                      setRowsPageSelected(idx);
                      changePage({ limit: row });
                    }}
                  >
                    {row}
                  </DropDownLi>
                ))}
              </DropDownUl>
            </DropDownContent>
          )}
        </div>
      </LiPagination>
      <ListDiv>
        <LiPagination
          $disabled={page === 1}
          $waves={!(page === 1)}
          onClick={() => {
            if (!(page === 1)) changePage({ numPage: page - 1 });
          }}
        >
          <span>
            <img alt={'Left Icon'} width={20} src={arrowLeftIcon} />
          </span>
        </LiPagination>

        {listPages(lastPage)}

        <LiPagination
          $disabled={page === lastPage}
          $waves={!(page === lastPage)}
          onClick={() => {
            if (!(page === lastPage)) changePage({ numPage: page + 1 });
          }}
        >
          <span>
            <img alt={'Right Icon'} width={20} src={arrowRightIcon} />
          </span>
        </LiPagination>
      </ListDiv>
    </UnorderedList>
  );
}

export default Pagination;
