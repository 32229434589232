import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import authStorage from '../../storage/auth';

const { REACT_APP_SERVER_HOST: SERVER_HOST } = process.env;

const validToken = (token) => {
  let decodedToken = jwtDecode(token);
  let currentDate = new Date();

  if (decodedToken) {
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      authStorage.remove();
      // userStorage.remove();
      window.location.reload();
      throw new axios.Cancel('Operation canceled! Invalid Token');
    }
  }
};
// Criando api para comunicação com servidor
const api = axios.create({
  baseURL: SERVER_HOST,
  // withCredentials: true,
  // credentials: true, //access-control-allow-credentials:true
  // optionSuccessStatus: 200,
});

// Adicionando interceptor para inserir token Bearer de autenticação no header
api.interceptors.request.use(
  async (config) => {
    const token = await authStorage.get();

    if (token) {
      validToken(token);
    }

    if (config.headers) {
      config.headers.Authorization = `Bearer ${token || ''}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
const timeout = 300000; // Equal 5 minutes, 1000 === 1 second
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { data, status, headers } = error.response;
      // console.error('Response Error:', data);
      // console.error('Status:', status);
      // console.error('Headers:', headers);
      const token = authStorage.get();
      if (status === 401 && token) {
        authStorage.remove();
        window.location.replace('/');
      }

      return Promise.reject(data);
    } else if (error.request) {
      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        error.message = `Timeout excedeu ${timeout}ms`;
        return Promise.reject(error);
      }

      return Promise.reject(error.request);
    } else {
      console.error('Error:', error.message);

      return Promise.reject(error.message);
    }
  }
);

export const handleApi = async (path, method, data, responseType) => {
  if (data.adminData) {
    const response = await api[method](path, { params: data.params });
    return response.data;
  }

  const formData = data.formData;

  let _responseTypeOptions;
  if (responseType) {
    _responseTypeOptions = { responseType: 'blob' };
  }

  const response = await api[method](
    path,
    data.formData
      ? formData
      : {
          ...data.data,
          params: data.params,
        },
    _responseTypeOptions
  );
  return response.data;
};
export default api;
