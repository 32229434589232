import React from 'react';

import { Box, Container, Label, OptionContainer, OptionLabel, OptionsContainer } from './styles';
import checkIcon from '../../../assets/check.svg';
function CheckBox({ options = [], setOptions, label, disabled }) {
  const handleCheck = (idx) => {
    const newOptions = [...options];
    newOptions[idx].status = !newOptions[idx].status;
    // Se usuário adminstrador
    if (idx === 0 && newOptions[idx].status === true) {
      for (let i = 1; i < newOptions.length; i++) {
        newOptions[i].status = false
      }
    }
    setOptions(newOptions);
  };
  return (
    <Container>
      <Label>{label}</Label>
      <OptionsContainer>
        {options[0] && options[0].status ? <OptionContainer

          $disabled={disabled}
          onClick={() => {
            if (!disabled) handleCheck(0);
          }}
        >
          <Box $status={options[0].status}>
            <img src={checkIcon} alt="checkIcon" />
          </Box>
          <OptionLabel>{options[0].label}</OptionLabel>

          <OptionLabel style={{ color: '#EA5C47', marginLeft: 20 }}> Todas as permissões habilitadas</OptionLabel>
        </OptionContainer> :
          options.map((option, idx) => (
            <OptionContainer
              key={idx}
              $disabled={disabled}
              onClick={() => {
                if (!disabled) handleCheck(idx);
              }}
            >
              <Box $status={option.status}>
                <img src={checkIcon} alt="checkIcon" />
              </Box>
              <OptionLabel>{option.label}</OptionLabel>
            </OptionContainer>
          ))}
      </OptionsContainer>
    </Container>
  );
}

export default CheckBox;
