import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  CertifyImage,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { useParams } from 'react-router-dom';
import adminClassesService from '../../../../services/adminClassesService';
import serverConst from '../../../../constants/serverConst';
import Button from '../../../../components/forms/Button';
import { useNavigate } from 'react-router-dom';
import adminStudentsService from '../../../../services/adminStudentsService';
import {
  ActionIcon,
  Table,
  TableBody,
  TableBodyColumn,
  TableBodyRow,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';
import iconView from "../../../../assets/view.svg";
import { ActiveTag } from '../../students/ListStudent/styles';
import iconTrash from '../../../../assets/trash.svg';
import { Label } from '../UpdateClass/styles';
import InputTextArea from '../../../../components/forms/InputTextArea';
import RolesCertificate from '../../../../components/certificate/RolesCertificate';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import { myAlert } from '../../../../lib/Alert';
import DeleteModal from '../../../../components/modals/DeleteModal';

function ShowClassContent() {
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState(false);
  const [listStudents, setListStudents] = useState([]);
  const [modalContent, setModalContent] = useState({});
  const [classData, setClassData] = useState({
    title: '',
    courseId: '',
    program: '',
    workload: '',
    certificate: {
      text: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  let { id } = useParams();
  const getData = async () => {
    try {
      setLoading(true);
      const res = await adminClassesService.show(id);
      setClassData(res.data);
      if (res.data.students.length > 0) {
        setListStudents(res.data.students);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // useEffect(() => {

  //   getData();
  // }, [updateData]);

  useEffect(() => {

    getData();
  }, [id, updateData]);

  const tableHeaderItems = [
    {
      label: 'Nome',
      flex: 1,
    },
    {
      label: 'Email',
      flex: 1.3,
    },
    {
      label: 'Ações',

    },
  ];

  const handleChangeDelete = async ({ studentId, title }) => {
    try {
      setLoading2(true);
      if (!studentId && !id) {
        myAlert.open({
          title: `Dados não encontrados`,
          type: 'error',
        });
        setLoading2(false)
      }
      await adminClassesService.deleteStudent({
        classId: id,
        studentId,
      });
      setUpdateData(!updateData)
      myAlert.open({
        title: `${title} deletado da turma com sucesso`,
        type: 'success',
      });
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      myAlert.open({
        title: `Erro ao alterar status ${title}`,
        type: 'error',
      });
    }
  };
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Visualização de turma</Title>
        <SubTitle>
          Você está visualizando as informações de <b>{classData.title}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form>
          <RowInput style={{ width: 'calc(50% - 10px)' }}>
            <InputText
              label="Curso"
              inputProps={{
                type: 'text',
                value: classData.course?.title,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: 'text',
                value: classData.title,
                disabled: true,
              }}
            />
            <InputText
              label="Carga Horária"
              inputProps={{
                type: 'text',
                value: classData.workload,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Ementa da turma"
              inputProps={{
                type: 'text',
                value: classData.program,
                disabled: true,
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Frase padrão"
              inputProps={{
                type: 'text',
                value: classData.certificate && classData.certificate.text,
                disabled: true,
              }}
            />
            <RolesCertificate />
          </RowInput>
          <RowInput style={{ flexDirection: 'column' }}>
            {classData && (
              <ViewCertificate
                {...{
                  back: {
                    url:
                      classData.certificate && classData.certificate.back
                        ? classData.certificate.back.file
                        : '',
                  },
                  front: {
                    url:
                      classData.certificate && classData.certificate.front
                        ? classData.certificate.front.file
                        : '',
                    text: classData.certificate && classData.certificate.text,
                  },
                  classe: classData,
                }}
              />
            )}
          </RowInput>
          {/* {showDelete && (
            <DeleteModal
              {...{
                show: showDelete,
                setShow: setShowDelete,
                title: modalContent.title || '',
                action: modalContent.action,
                label: "Remover",
              }}
            />
          )} */}
          <div style={{ overflowX: 'auto' }}>
            <Table>
              <Label>Alunos </Label>
              <TableHeader>
                {tableHeaderItems.map((item, idx) => (
                  <TableHeaderColumn key={idx} flex={item.flex}>
                    {item.label}
                  </TableHeaderColumn>
                ))}
              </TableHeader>
              <TableBody>
                {listStudents.length > 0 &&
                  listStudents.map((item, idx) => (
                    <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                      <TableBodyColumn>{item.fullname}</TableBodyColumn>
                      <TableBodyColumn flex={1.3}>{item.email}</TableBodyColumn>
                      <TableBodyColumn>
                        <ActionIcon
                          src={iconView}
                          onClick={() => {
                            navigate(`/students/${item._id}`);
                          }}
                        />
                        {/* <ActionIcon
                        src={iconTrash}
                        onClick={async () => {
                          setModalContent({
                            title: `Deseja deletar o aluno ${item.fullname}?`,
                            action: () =>
                              handleChangeDelete({
                                title: item.fullname,
                                studentId: item._id,
                              }),
                            label: "Desativar",
                          });
                          setShowDelete(true);
                        }}
                      /> */}
                      </TableBodyColumn>

                    </TableBodyRow>
                  ))}
              </TableBody>
            </Table>
          </div>
          <ButtonContainer>
            <Button
              loading={loading}
              label="Editar"
              onClick={() => {
                navigate(`/classes/edit/${id}`);
              }}
              containerStyle={{ width: '200px' }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function ShowClass() {
  return (
    <DashboardTemplate>
      <ShowClassContent />
    </DashboardTemplate>
  );
}

export default ShowClass;
