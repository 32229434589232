const tags = {
  keys: {
    serneuropsi: {
      label: 'serneuropsi',
      key: 'serneuropsi',
      color: '#ef476f',
    },
    corujaneuro: {
      label: 'corujaneuro',
      key: 'corujaneuro',
      color: '#4b0082',
    },
    psicologo: {
      label: 'psicologo',
      key: 'psicologo',
      color: '#008000',
    },
    estudante: {
      label: 'estudante',
      key: 'estudante',
      color: '#118ab2',
    },
    psicopedagogo: {
      label: 'psicopedagogo',
      key: 'psicopedagogo',
      color: '#073b4c',
    },
    areasaude: {
      label: 'areasaude',
      key: 'areasaude',
      color: '#577590',
    },
    gratuito: {
      label: 'gratuito',
      key: 'gratuito',
      color: '#f3722c',
    },


  },
  array: [
    {
      label: 'serneuropsi',
      key: 'serneuropsi',
      color: '#ef476f',
    },
    {
      label: 'corujaneuro',
      key: 'corujaneuro',
      color: '#4b0082',
    },
    {
      label: 'psicologo',
      key: 'psicologo',
      color: '#008000',
    },
    {
      label: 'estudante',
      key: 'estudante',
      color: '#118ab2',
    },
    {
      label: 'psicopedagogo',
      key: 'psicopedagogo',
      color: '#073b4c',
    },
    {
      label: 'areasaude',
      key: 'areasaude',
      color: '#577590',
    },
    {
      label: 'gratuito',
      key: 'gratuito',
      color: '#f3722c',
    },
  ],
};
export default tags;
