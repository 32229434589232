const masks = {
  cpfMask: (value) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cpnjMask: (value) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  },
  cpf_cnpj_mask: (value) => {
    if (value.replace(/[^0-9 ]/g, '').length <= 11) {
      return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    } else {
      return value
        .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
    }
  },
  removeSpecialCharacter: (value) => {
    return value.replace(/[^a-zA-Z0-9]/g, '');
  },
  removeSpecialCharacterDot: (value) => {
    return value.replace(/[^0-9,.]/g, '');
  },
  justInt: (value, min, max) => {
    if (parseInt(value) < min) {
      return min;
    }
    if (parseInt(value) > max) {
      return max;
    }
    return value.replace(/[^0-9]/g, '');
  },
  monthYearMask: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1');
  },

  dayMonthYearMask: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1');
  },

  phoneBrMask: (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1');
  },
  cellphoneBrMask: (value) => {
    if (value.length < 3) {
      return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2');
    } else {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)(\d)/, '($1) $2 $3')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
  },
  numberFloat: ({ value, precision = 8 }) => {
    const rgx = new RegExp(`(\\.\\d{${precision}})\\d+?$`);
    return value
      .replace(/[^0-9.]/g, '')
      .replace(/(\d\.)(\d)/, '$1$2')
      .replace(rgx, '$1');
  },
};
export default masks;
