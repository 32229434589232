import Alert from '../../components/notifications/Alert';
import { myAlert, makeid } from '../../lib/Alert';
import React, { createContext, useEffect, useState } from 'react';

const AlertContext = createContext({
  notifications: [],
});

export const AlertProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    myAlert.init({ open, closeAll });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const verifyId = (id) => notifications.find((notify) => notify.id === id);

  const open = (data, closeNotification) => {
    if (closeNotification) closeAll();
    if (!data.id) data.id = makeid(15);
    if (!verifyId(data.id)) {
      setNotifications((state) => [...state, data]);
    }
  };
  const closeAll = () => {
    setNotifications([]);
  };
  return (
    <AlertContext.Provider
      value={{
        notifications,
      }}
    >
      <Alert />
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
