import React, { useState } from 'react';
import { ModalContent, OptionsDiv } from './styles';
import { PinAngle } from '@styled-icons/bootstrap/PinAngle';
import { Delete } from '@styled-icons/fluentui-system-regular/Delete';


const AdminActions = ({ onDelete, onFix, index, myMessage }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ModalContent className={`${ myMessage ? 'mymessage' : 'othermessage' } ${ isOpen ? 'open' : 'closed'}`}>
        {
          isOpen ? (
            <OptionsDiv onClick={() => setIsOpen(!isOpen)}>
              <Delete title={'Deletar'} size={25} onClick={() => onDelete(index)} />
              <PinAngle title={'Fixar'} size={25} onClick={() => onFix(index)} />
            </OptionsDiv>
          ) : (
            <div
              onClick={() => setIsOpen(!isOpen)}
            >
              ...
            </div>
          )
        }
    </ModalContent>
  );
};

export default AdminActions;
