import styled, { css } from 'styled-components';

export const Container = styled.div`
@media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;
export const BodyHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div``;
export const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const SubTitle = styled.span`
  color: #363636;
  font-weight: 400;
  font-size: 18px;
`;
export const Body = styled.div`
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 20px;
`;
export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  min-width: 100%;
  width: fit-content;
`;
export const TableHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 50px;
  background-color: #244c59;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
export const TableHeaderColumn = styled.div(
  (props) => css`
    /* flex: ${props.flex || 1}; */
    color: white;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    
    min-width: ${props.minWidth || "200px"};
  `
);
export const TableBody = styled.div`
  border-bottom: 1px solid #244c5933;
  min-width: 600px;
`;
export const TableBodyRow = styled.div(
  (props) => css`
    height: 70px;
    display: flex;

    align-items: center;
    ${props.$bgc &&
    css`
      background-color: #90909011;
    `}
    &:hover {
      background-color: #244c5911;
    }
  `
);
export const TableBodyColumn = styled.div(
  (props) => css`
    display: flex;
    height: 40px;
    font-size: 14px;
    width: 100%;
    min-width: ${props.minWidth || "200px"};
    
    padding: 0 10px;
    display: flex;
    overflow-x: ${props.$overflowX ? "auto" : "inherit"};
    word-break: break-all;

    &::-webkit-scrollbar {
      width: 10px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #90909011; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid #909090aa; /* creates padding around scroll thumb */
    }
  `
);
export const ContainerSearch = styled.div`
  max-width: 600px;
`;
export const ActionIcon = styled.img`
  cursor: pointer;
  padding: 10px;
  width: 40px;
`;
export const ActiveTag = styled.span(
  (props) => css`
    padding: 8px 15px;
    border-radius: 10px;
    color: #ea5c47;

    ${props.$active &&
    css`
      color: #5baab3;
    `}
  `
);
