import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  gap: 50px;
  height: 50px;
  width: 100%;
  
  background-color: #ffffffaa;
  position: fixed;
  bottom: 0;
  
  font-size: 12px !important;

  color: inherit;
  font-weight: bold;

  box-shadow: rgba(0, 0, 0, 0.1) 10px 0 0 0,
    rgba(0, 0, 0, 0.06) 10px 0 0 0;
  
  @media (max-width: 1024px) {
    height: 70px;
    padding: 10px 0;
    gap: 10px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    height: 90px;
    padding: 10px 0;
    gap: 10px;
  }

  @media (max-width: 425px) {
    font-size: 0.7rem;
    height: 100px;
    padding: 10px 0;
    gap: 10px;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover{
    text-decoration: underline;
  }
`;