import React, { useEffect, useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
  Label,
  SelectInput,
} from "./styles";

import Button from "../../../../components/forms/Button";
import ButtonBack from "../../../../components/forms/ButtonBack";
import InputText from "../../../../components/forms/InputText";
import adminClassesService from "../../../../services/adminClassesService";
import { myAlert } from "../../../../lib/Alert";
import { useParams } from "react-router-dom";
import { InputImage } from "../../courses/CreateCourse/styles";
import adminStudentsService from "../../../../services/adminStudentsService";
import {
  Table,
  TableBody,
  TableBodyColumn,
  TableBodyRow,
  TableHeader,
  TableHeaderColumn,
} from "../../users/ListUser/styles";
import { ActiveTag, ActionIcon } from "../../students/ListStudent/styles";
import iconTrash from "../../../../assets/trash.svg";
import Select from "react-select";
import ViewCertificate from "../../../../components/certificate/ViewCertificate";
import InputTextArea from "../../../../components/forms/InputTextArea";
import RolesCertificate from "../../../../components/certificate/RolesCertificate";
import { useNavigate } from "react-router-dom";
import adminCoursesService from "../../../../services/adminCoursesService";
import DeleteModal from '../../../../components/modals/DeleteModal';
import ReactSelect from 'react-select';

function UpdateClassContent() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    title: "",
    courseId: "",
    program: "",
    workload: "",
    certificate: {
      text: "",
    },
  });
  const [title, setTitle] = useState("");
  const [workload, setWorkload] = useState("");
  const [program, setProgram] = useState("");
  const [students, setStudents] = useState([]);
  const [listStudents, setListStudents] = useState([]);
  const [search, setSearch] = useState("");
  const [searchStudent, setSearchStudent] = useState("");
  const [modalContent, setModalContent] = useState({});
  const [updateData, setUpdateData] = useState(false);
  const [certificateText, setCertificateText] = useState("");
  const [searchCourse, setSearchCourse] = useState('');
  const [imageFront, setImageFront] = useState({});
  const [imageBack, setImageBack] = useState({});
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [loading2, setLoading2] = useState(false);
  const [showDelete, setShowDelete] = useState(false);


  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const getStudents = async () => {
      try {
        const res = await adminStudentsService.list({
          find: searchStudent,
          isActive: true,
          perPage: 100
        });

        setStudents(res.data);
      } catch (error) {
        console.log(error);
        myAlert.open({
          title:
            "Alunos não encontrados. Verifique se existem alunos cadastrados.",
          type: "warning",
        });
      }
    };
    getStudents();
  }, [searchStudent]);

  const onChangeFile = async (part = "front") => {
    const currFile = part === "front" ? fileFrontRef : fileBackRef;
    if (currFile.current.files[0]) {
      const url = await readURL(currFile.current.files[0]);

      if (part === "front") {
        setImageFront({ url: url, file: currFile.current.files[0] });
        return;
      }

      setImageBack({ url: url, file: currFile.current.files[0] });
    }
  };

  let { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminClassesService.show(id);
        setData(res.data);

        setTitle(res.data.title);
        setProgram(res.data.program);
        setWorkload(res.data.workload);

        setCertificateText(res.data.certificate.text);
        if (res.data.students.length > 0) {
          setListStudents(res.data.students);
        }

        if (res.data.course) {

          setCourseId({
            value: res.data.course._id, label: res.data.course.title
          });
        }


        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getData();
  }, [id, updateData]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);
      const toUpdate = {
        title,
        courseId: courseId.value,
        workload,
        program,
        certificate: { text: certificateText },
        fileFront: imageFront.file,
        fileBack: imageBack.file,
        id,
      }
      await adminClassesService.update(toUpdate);
      myAlert.open({ title: "Turma atualizada com sucesso", type: "success" });
      navigate("/classes", { replace: true });
      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({
        title: "Falha ao atualizar turma. Tente novamente mais tarde",
        type: "error",
      });

      setLoading(false);
    }

  };

  const tableHeaderItems = [
    {
      label: "Nome",
      flex: 1,
    },
    {
      label: "Email",
      flex: 1.3,
    },
    {
      label: "Status",
      flex: 1,
    },
    {
      label: "Remover",
      flex: 1,
    },
  ];
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminCoursesService.list({ find: searchCourse, isActive: true });
        setCourses(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getData();
  }, [searchCourse]);
  const handleChangeDelete = async ({ studentId, title }) => {
    try {
      setLoading2(true);
      if (!studentId && !id) {
        myAlert.open({
          title: `Dados não encontrados`,
          type: 'error',
        });
        setLoading2(false)
        return
      }
      await adminClassesService.deleteStudent({
        classId: id,
        studentId,
      });
      setUpdateData(!updateData)
      myAlert.open({
        title: `${title} deletado da turma com sucesso.`,
        type: 'success',
      });
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      myAlert.open({
        title: `Erro ao alterar status ${title}`,
        type: 'error',
      });
    }
  };
  const handleAddStudent = async () => {
    try {

      setLoading2(true)
      if (!search) {
        myAlert.open({
          title: `Selecione um aluno`,
          type: 'warning',
        });
        setLoading2(false)
        return
      }
      if (listStudents.find(({ _id }) => _id === search._id)) {
        myAlert.open({
          title: `Usuário já cadastrado`,
          type: 'warning',
        });
        setLoading2(false)
        return
      }

      await adminClassesService.addStudent({
        classId: id,
        studentIds: [search._id],
      });
      setListStudents([...listStudents, search]);
      setUpdateData(!updateData)
      setSearch("")
      myAlert.open({
        title: `${search.fullname} adicionado com sucesso a turma.`,
        type: 'success',
      });
      setLoading2(false)
    } catch (error) {
      console.log(error);
      myAlert.open({
        title: `Erro ao adicionar usuário`,
        type: 'error',
      });
    }
  }
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Atualização turma</Title>
        <SubTitle>
          Você está atualizando as informações de <b>{data.title}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput style={{ flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", marginBottom: 8 }} htmlFor="CourseInput">
              Curso:
            </label>
            <ReactSelect
              options={courses.map((item, idx) => ({
                value: item._id,
                label: item.title,
              }))}
              placeholder={'Selecione'}
              value={courseId}
              onChange={(e) => {
                setCourseId(e)

              }}
              isSearchable
              onInputChange={(inputValue) => {
                if (inputValue) setSearchCourse(inputValue)
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // width: '230px',
                }),
              }}
            />

          </RowInput>

          <RowInput>
            <InputText
              label="Nome da turma"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => setTitle(e.target.value),
              }}
            />
            <InputText
              label="Carga Horária"
              inputProps={{
                type: "text",
                value: workload,
                onChange: (e) => setWorkload(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Ementa da turma"
              inputProps={{
                type: "text",
                value: program,
                onChange: (e) => setProgram(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Frase padrão"
              inputProps={{
                type: "text",
                value: certificateText,
                onChange: (e) => setCertificateText(e.target.value),
              }}
            />
            <RolesCertificate />
          </RowInput>
          <RowInput>
            <InputImage
              {...{
                type: "file",
                ref: fileBackRef,
                onChange: () => onChangeFile("back"),
                id: "image-certificate-fundo",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <InputImage
              {...{
                type: "file",
                ref: fileFrontRef,
                onChange: () => onChangeFile("front"),
                id: "image-certificate",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <ViewCertificate
              {...{
                front: {
                  internalUrl: imageFront && imageFront.url,
                  url:
                    imageFront && imageFront.url
                      ? imageFront.url
                      : data && data.certificate && data.certificate.front
                        ? data.certificate.front.file
                        : "",
                  text: certificateText,
                  forId: "image-certificate",
                },
                back: {
                  internalUrl: imageBack && imageBack.url,
                  url:
                    imageBack && imageBack.url
                      ? imageBack.url
                      : data && data.certificate && data.certificate.back
                        ? data.certificate.back.file
                        : "",

                  forId: "image-certificate-fundo",
                },
                classe: {
                  title: title || data.title,
                  workload: workload || data.workload,
                  program: program || data.program,


                }
              }}
            />
          </RowInput>

          <ButtonContainer>
            <Button
              label="Atualizar"
              loading={loading}
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
        {showDelete && (
          <DeleteModal
            {...{
              show: showDelete,
              setShow: setShowDelete,
              title: modalContent.title || '',
              action: modalContent.action,
              label: "Remover",
            }}
          />
        )}
        <br />
        <hr />
        <br />
        <br />
        <div style={{ overflowX: "auto" }}>
          <Table>
            <Title>Adicionar alunos</Title>
            <SubTitle>
              Busque pelo aluno e vincule ele a turma
            </SubTitle>
            <br />

            <RowInput style={{ alignItems: "center" }}>
              <Select
                placeholder="Selecionar aluno"
                value={{
                  value: search,
                  label: search ? search.fullname + " - " + search.email : '',
                }}
                options={students.map((student) => ({
                  value: student,
                  label: student.fullname + " - " + student.email,
                }))}
                isSearchable
                onInputChange={(inputValue) => {
                  if (inputValue) setSearchStudent(inputValue)
                }}
                onChange={(e) => setSearch(e.value)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    width: "400px",
                  }),
                }}
              />
              <Button
                label={"Adicionar"}
                loading={loading2}
                onClick={e => {
                  e.preventDefault()
                  handleAddStudent()
                }}
              />
            </RowInput>
            <TableHeader>
              {tableHeaderItems.map((item, idx) => (
                <TableHeaderColumn key={idx} flex={item.flex}>
                  {item.label}
                </TableHeaderColumn>
              ))}
            </TableHeader>
            <TableBody>
              {listStudents.length > 0 &&
                listStudents.map((item, idx) => (
                  <TableBodyRow
                    key={idx}
                    $bgc={idx % 2 > 0}
                    style={{
                      height: "auto",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                  >
                    <TableBodyColumn>{item.fullname}</TableBodyColumn>
                    <TableBodyColumn flex={1.3}>{item.email}</TableBodyColumn>

                    <TableBodyColumn>
                      <ActiveTag $active={item.isActive}>
                        {item.isActive ? "Ativo" : "Inativo"}
                      </ActiveTag>
                    </TableBodyColumn>
                    <TableBodyColumn>
                      <ActionIcon
                        src={iconTrash}
                        onClick={(e) => {
                          e.preventDefault()
                          setModalContent({
                            title: `Deseja deletar o aluno ${item.fullname}?`,
                            action: () =>
                              handleChangeDelete({
                                title: item.fullname,
                                studentId: item._id,
                              }),
                            label: "Desativar",
                          });
                          setShowDelete(true);
                        }}
                      />
                    </TableBodyColumn>
                  </TableBodyRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Body>
    </Container>
  );
}
function UpdateClass() {
  return (
    <DashboardTemplate>
      <UpdateClassContent />
    </DashboardTemplate>
  );
}

export default UpdateClass;
