import React from 'react';

import styled, { css } from 'styled-components';

export const Container = styled.div(
  (props) => css`
    position: absolute;
    top: ${props.theme.sizes.navbarHeightMob};
    z-index: 9;
    right: 0;
    ${props.theme.responsive.sm(css`
      right: 20px;
    `)};
    ${props.theme.responsive.lg(css`
      top: ${props.theme.sizes.navbarHeightDesk};
    `)};
  `
);

export const AlertContainer = styled.div(
  (props) => css`
    background-color: ${props.theme.alertColors[`${props.type}Bgc`]};
    position: relative;
    min-height: 30px;

    display: flex;
    box-shadow: 0px 4px 4px rgba(177, 177, 177, 0.25);
    ${props.show
      ? css`
          transition: visibility 0s linear 0s, opacity 0.3s;
          opacity: 1;
        `
      : css`
          transition: visibility 0s linear 0.3s, opacity 0.3s;
          opacity: 0;

          z-index: -1;
        `}
    width: 100%;
    margin: 5px 0;
    ${props.theme.responsive.sm(css`
      width: 285px;
      border-radius: 6px;
    `)};
  `
);

export const LeftContent = styled.div`
  background-color: ${(props) => props.theme.alertColors[`${props.type}Bgc2`]};
  min-height: 30px;
  width: 30px;
  border-radius: 5px 0 0 5px;
`;
export const RightContent = styled.div`
  padding: 10px 30px 10px 10px;
  width: 255px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  ${(props) => props.theme.fonts.poppins}
`;
export const Title = styled.span`
  color: ${(props) => props.theme.alertColors[`${props.type}Font`]};
  font-size: 14px;
  font-weight: 600;
`;
export const Subtitle = styled.span`
  color: ${(props) => props.theme.alertColors[`${props.type}Font`]};
  margin-top: 5px;
  font-size: 12px;
`;
const CloseSvg = ({ fill, onClick }) => (
  <svg
    onClick={onClick}
    style={{
      width: 20,
      position: 'absolute',
      right: 5,
      top: 5,
      padding: '5px',

      cursor: 'pointer',
    }}
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.88773 8.00546L15.6211 2.28546C15.8721 2.03438 16.0132 1.69386 16.0132 1.33879C16.0132 0.98372 15.8721 0.643194 15.6211 0.392122C15.37 0.141051 15.0295 0 14.6744 0C14.3193 0 13.9788 0.141051 13.7277 0.392122L8.00773 6.12546L2.28773 0.392122C2.03666 0.141051 1.69613 -2.64547e-09 1.34106 0C0.985996 2.64547e-09 0.645469 0.141051 0.394398 0.392122C0.143326 0.643194 0.00227547 0.98372 0.00227547 1.33879C0.00227546 1.69386 0.143326 2.03438 0.394398 2.28546L6.12773 8.00546L0.394398 13.7255C0.269426 13.8494 0.170234 13.9969 0.102543 14.1594C0.0348513 14.3218 0 14.4961 0 14.6721C0 14.8481 0.0348513 15.0224 0.102543 15.1849C0.170234 15.3474 0.269426 15.4948 0.394398 15.6188C0.518348 15.7438 0.665816 15.843 0.828295 15.9106C0.990774 15.9783 1.16505 16.0132 1.34106 16.0132C1.51708 16.0132 1.69135 15.9783 1.85383 15.9106C2.01631 15.843 2.16378 15.7438 2.28773 15.6188L8.00773 9.88545L13.7277 15.6188C13.8517 15.7438 13.9991 15.843 14.1616 15.9106C14.3241 15.9783 14.4984 16.0132 14.6744 16.0132C14.8504 16.0132 15.0247 15.9783 15.1872 15.9106C15.3496 15.843 15.4971 15.7438 15.6211 15.6188C15.746 15.4948 15.8452 15.3474 15.9129 15.1849C15.9806 15.0224 16.0155 14.8481 16.0155 14.6721C16.0155 14.4961 15.9806 14.3218 15.9129 14.1594C15.8452 13.9969 15.746 13.8494 15.6211 13.7255L9.88773 8.00546Z"
      fill={`${fill}88`}
    />
  </svg>
);

export const CloseIcon = styled(CloseSvg).attrs((props) => ({
  fill: props.theme.alertColors[`${props.type}Font`],
}))``;
export const LinkBsc = styled.a`
  color: ${(props) => props.theme.colors.primaryLink};
  text-decoration: none;
  font-size: 12px;
  margin-top: 5px;
`;
