import React, { useEffect, useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SelectInput,
  SubTitle,
  Title,
} from './styles';

import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import defaultImage from '../../../../assets/empty.jpg';
import { InputImage, LabelmageInput } from '../../courses/CreateCourse/styles';
import adminCoursesService from '../../../../services/adminCoursesService';
import { myAlert } from '../../../../lib/Alert';
import adminClassesService from '../../../../services/adminClassesService';
import RolesCertificate from '../../../../components/certificate/RolesCertificate';
import InputTextArea from '../../../../components/forms/InputTextArea';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';

function CreateClassContent() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [program, setProgram] = useState('');
  const [workload, setWorkload] = useState('');

  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState('');
  const [searchCourse, setSearchCourse] = useState('');
  const [images, setImages] = useState([{ url: defaultImage, file: defaultImage }]);
  const [loading, setLoading] = useState(false);
  const [imageFront, setImageFront] = useState({ url: '', file: '' });
  const [imageBack, setImageBack] = useState({ url: '', file: '' });
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);
  const [certificateText, setCertificateText] = useState('');
  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const onChangeFile = async (part = 'front') => {
    const currFile = part === 'front' ? fileFrontRef : fileBackRef;
    if (currFile.current.files[0]) {
      const url = await readURL(currFile.current.files[0]);
      if (part === 'front') {
        setImageFront({ url: url, file: currFile.current.files[0] });
        return;
      }

      setImageBack({ url: url, file: currFile.current.files[0] });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!title || !workload || !courseId) {
        myAlert.open({
          title: 'Preencha todos os campos obrigatórios',
          type: 'warning',
        });
        setLoading(false);
        return;
      } else {
        await adminClassesService.create({
          title,
          courseId,
          program,
          workload,
          certificate: { text: certificateText },
          fileFront: imageFront.file,
          fileBack: imageBack.file,
        });
        setTimeout(() => {
          myAlert.open({
            title: 'Turma cadastrada com sucesso',
            type: 'success',
          });
          navigate('/classes', { replace: true });
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {

        console.log(error);
        myAlert.open({
          title:
            "Erro ao cadastrar turma. Tente novamente ou contacte o suporte.",
          type: "error",
        });
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminCoursesService.list({ find: searchCourse, isActive: true });
        setCourses(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getData();
  }, [searchCourse]);

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Criar turma</Title>
        <SubTitle>
          Cadastre uma nova turma preenchendo o formulário abaixo
        </SubTitle>
        <br />
        <br />
        <SubTitle>
          As turmas cadastradas não irão aparecer na plataforma principal
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput style={{ flexDirection: 'column', width: '50%' }}>
            <label style={{ fontSize: "13px", marginBottom: 8 }} htmlFor="CourseInput">
              Curso:
            </label>
            <ReactSelect
              options={courses.map((item, idx) => ({
                value: item._id,
                label: item.title,
              }))}
              placeholder={'Selecione'}
              onChange={(e) => {
                setCourseId(e.value)

              }}
              isSearchable
              onInputChange={(inputValue) => {
                if (inputValue) setSearchCourse(inputValue)
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // width: '230px',
                }),
              }}
            />

          </RowInput>
          <RowInput>
            <InputText
              label="Nome da turma"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => setTitle(e.target.value),
              }}
            />
            <InputText
              label="Carga Horária"
              inputProps={{
                type: "text",
                value: workload,
                onChange: (e) => setWorkload(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Ementa"
              inputProps={{
                type: "text",
                value: program,
                onChange: (e) => setProgram(e.target.value),
              }}
            />
          </RowInput>

          <RowInput>
            <InputTextArea
              label="Frase padrão"
              inputProps={{
                type: "text",
                value: certificateText,
                onChange: (e) => setCertificateText(e.target.value),
              }}
            />
            <RolesCertificate />
          </RowInput>

          <RowInput>
            <InputImage
              {...{
                type: "file",
                ref: fileFrontRef,
                onChange: () => onChangeFile("front"),
                id: "image-certificate",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <InputImage
              {...{
                type: "file",
                ref: fileBackRef,
                onChange: () => onChangeFile("back"),
                id: "image-certificate-fundo",
                accept: "image/png, image/jpeg",
                multiple: true,
              }}
            />
            <ViewCertificate
              {...{
                upload: true,
                front: {
                  internalUrl: imageFront.url,
                  url: imageFront.url,
                  text: certificateText,
                  forId: "image-certificate",
                  helperTexts: [
                    "O hash do arquivo só poderá ser visualizado após a geração do certificado",
                    "Variáveis de turma serão visíveis apenas em turmas ou após a geração do certificado",
                    "Variáveis de aluno serão visíveis após a geração do certificado",
                  ],
                },
                classe: {
                  program,
                  title,
                  workload
                },
                back: {
                  internalUrl: imageBack.url,
                  url: imageBack.url,
                  text: "Ementa da turma aqui",
                  forId: "image-certificate-fundo",
                  helperTexts: [
                    "O hash do arquivo só poderá ser visualizado após a geração do certificado",
                    "A ementa será visível apenas em turmas ou após a geração do certificado",
                  ],
                },
              }}
            />
          </RowInput>

          <ButtonContainer>
            <Button
              loading={loading}
              label="Cadastrar"
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function CreateClass() {
  return (
    <DashboardTemplate>
      <CreateClassContent />
    </DashboardTemplate>
  );
}

export default CreateClass;
