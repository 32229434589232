import { handleApi } from './server';

const adminCertificateService = {
  update: async (data) => {
    const { isActive, id, courseId, classId, template, issueDate } = data;

    if (isActive !== undefined) {
      const response = await handleApi('/admin/certificates/' + id, 'put', {
        data: {
          courseId,
          classId,
          template,
          issueDate,
          isActive
        },
      });
      return response;
    }
  },
  create: async (data) => {
    const { courseId, classId, template, type, students, issueDate, sendMail } = data;
    const response = await handleApi('/admin/certificates', 'post', {
      data: {
        courseId,
        classId,
        template,
        type,
        students,
        issueDate,
        sendMail,
      },
    });
    return response.data;
  },
  resendMultiples: async (data) => {


    const response = await handleApi('/admin/certificates/resend/multiples', 'post', {
      data,
    });
    return response;
  },
  list: async (data) => {

    const params = {
      ...data,
    };
    return await handleApi('/admin/certificates', 'get', {
      data,
      params,
    });
  },
  show: async (id) => {
    return await handleApi('/admin/certificates/' + id, 'get', {});
  },
  downloadCertificate: async (hash) => {
    const res = await handleApi('/admin/certificates/generate/' + hash, 'get', {});
    return res.data;
  },
  resendCertificateEmail: async (data) => {
    const { hash } = data;
    const res = await handleApi('/admin/certificates/resend', 'post', { data: { hash } });
    return res.data;
  },
  validate: async (hash) => {
    const res = await handleApi('/admin/certificates/validate/' + hash, 'get', {});
    return res.data;
  }
};
export default adminCertificateService;
