import React, { useContext, useEffect, useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SelectInput,
  SubTitle,
  Title,
} from './styles';

import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import defaultImage from '../../../../assets/empty.jpg';
import { InputImage, LabelmageInput } from '../../courses/CreateCourse/styles';
import adminCoursesService from '../../../../services/adminCoursesService';
import { myAlert } from '../../../../lib/Alert';
import adminChatsService from '../../../../services/adminChatsService';
import RolesCertificate from '../../../../components/certificate/RolesCertificate';
import InputTextArea from '../../../../components/forms/InputTextArea';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import AuthContext from '../../../../hooks/Auth';

function CreateChatContent() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [fixedMessage, setFixedMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!title) {
        myAlert.open({
          title: 'Preencha todos os campos obrigatórios',
          type: 'warning',
        });
        setLoading(false);
        return;
      } else {
        await adminChatsService.create({
          title,
          fixedMessage: {
            content: fixedMessage,
            owner: user.fullName + " (Admin)",
          },
        });
        setTimeout(() => {
          myAlert.open({
            title: 'Chat cadastrado com sucesso',
            type: 'success',
          });
          navigate('/chats', { replace: true });
        }, 500);
      }
    } catch (error) {
      console.log(error);
      setTimeout(() => {

        console.log(error);
        myAlert.open({
          title:
            "Erro ao cadastrar chat. Tente novamente ou contacte o suporte.",
          type: "error",
        });
        setLoading(false);
      }, 500);
    }
  };

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Criar Chat</Title>
        <SubTitle>
          Cadastre um novo chat preenchendo o formulário abaixo
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome do chat"
              inputProps={{
                type: "text",
                value: title,
                onChange: (e) => setTitle(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputTextArea
              label="Mensagem Fixada (opcional)"
              inputProps={{
                type: "text",
                value: fixedMessage,
                onChange: (e) => setFixedMessage(e.target.value),
              }}
            />
          </RowInput>

          <ButtonContainer>
            <Button
              loading={loading}
              label="Cadastrar"
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function CreateChat() {
  return (
    <DashboardTemplate>
      <CreateChatContent />
    </DashboardTemplate>
  );
}

export default CreateChat;
