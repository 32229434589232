import React, { useContext, useEffect, useState } from 'react';
import ChatComponent from './components/chat';
import UserRequestComponent from './components/userRequest/userRequest';
import AuthContext from '../../hooks/Auth';
import adminChatsService from '../../services/adminChatsService';
import { useNavigate, useParams } from 'react-router-dom';
import authService from '../../services/authService';
import { myAlert } from '../../lib/Alert';
import LoadingPage from "../LoadingPage"
const Chat = () => {
  const [username, setUsername] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  // const navigate = useNavigate();
  let { hash } = useParams();

  useEffect(() => {


    const getData = async () => {
      try {
        setIsLoading(true)
        console.log("entrei")
        const a = await authService.validateChatId(hash);
        console.log(a);
        const userChatLogin = localStorage.getItem('userChatLogin');

        if (user) {
          setIsAdmin(true);
          setUsername(user.fullName + " (Admin)");
        } else if (userChatLogin) {
          setIsAdmin(false);
          setUsername(userChatLogin);
        }
        console.log("===========")
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setIsInvalid(true)
      }
    }

    getData()

  }, []);
  console.log(username || isLoading, username, isLoading)
  return isLoading ? <LoadingPage /> : <>
    {
      username ? <ChatComponent username={username} isAdmin={isAdmin} /> : <UserRequestComponent setUsername={setUsername} invalid={isInvalid} />
    }
  </>
    ;
};

export default Chat;
