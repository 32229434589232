import { AlertProvider } from './hooks/Alert';
import { AuthProvider } from './hooks/Auth';
import React from 'react';

import { ThemeProvider } from 'styled-components';
import themes from './config/themes';

const Providers = ({ children }) => {
  return (
    <ThemeProvider theme={themes}>
      <AlertProvider>
        <AuthProvider>{children}</AuthProvider>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default Providers;
