import styled, { css } from "styled-components";

export const Container = styled.div``;
export const BodyHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div``;
export const Title = styled.h1`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
`;
export const SubTitle = styled.span`
  color: #363636;
  font-weight: 400;
  font-size: 18px;
`;
export const Body = styled.div`
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 20px;

    @media (max-width: 600px) {
        padding: 0;
    }
`;
export const Table = styled.div`
  margin: 20px 0;
`;
export const TableHeader = styled.div`
  display: flex;
  height: 50px;
  background-color: #244c59;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
export const TableHeaderColumn = styled.div(
  (props) => css`
    flex: ${props.flex || 1};
    color: white;
    display: flex;
    align-items: center;
    padding: 0 10px;
  `
);
export const TableBody = styled.div`
  border-bottom: 1px solid #244c5933;
`;
export const TableBodyRow = styled.div(
  (props) => css`
    height: 50px;
    display: flex;
    display: flex;
    align-items: center;
    ${props.$bgc &&
    css`
      background-color: #90909011;
    `}
    &:hover {
      background-color: #244c5911;
    }
  `
);
export const TableBodyColumn = styled.div(
  (props) => css`
    flex: ${props.flex || 1};
    padding: 0 10px;
  `
);
export const ContainerSearch = styled.div`
  max-width: 600px;
`;
export const Form = styled.form``;
export const RowInput = styled.div`
  display: flex;
  column-gap: 20px;
  flex-wrap: wrap;
`;
export const ButtonContainer = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: flex-end;
`;
