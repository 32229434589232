import React, { useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import { useNavigate } from "react-router-dom";
import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import CheckBox from '../../../../components/forms/CheckBox';
import InputText from '../../../../components/forms/InputText';
import { myAlert } from "../../../../lib/Alert";
import validator from '../../../../lib/validator';
import adminUsersService from '../../../../services/adminUsersService';

function CreateUserContent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [fullName, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState([
    {
      label: "Administrador",
      key: "admin",
      status: false,
    },
    {
      label: "Cursos",
      key: "courses",
      status: false,
    },
    {
      label: "Turmas",
      key: "classes",
      status: false,
    },
    {
      label: "Alunos",
      key: "students",
      status: false,
    },
    {
      label: "Certificados",
      key: "certificates",
      status: false,
    },
    {
      label: "Chat",
      key: "chats",
      status: false,
    },
  ]);


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let roles = permissions.filter((perm) => perm.status);
      roles = roles.map((role) => role.key);

      await adminUsersService.create({
        email,
        fullName,
        password,
        passwordConfirm,
        roles,
      });

      setTimeout(() => {
        myAlert.open({
          title: "Usuário cadastrado com sucesso",
          type: "success",
        });
        navigate("/users", { replace: true });
      }, 500);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        if (error && error.code) {
          if (!error.errors) {
            myAlert.open({ title: error.message, type: 'error' });
          } else {
            validator.verifyErrors(error);
          }
        } else {
          myAlert.open({ title: "Erro, contate o suporte", type: 'error' });
        }
        setLoading(false);
      }, 500);
    }
  };

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Criar usuário</Title>
        <SubTitle>
          Cadastre um novo usuário preenchendo o formulário abaixo
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                onChange: (e) => setFullname(e.target.value),
              }}
            />
            <InputText
              label="E-mail"
              inputProps={{
                type: "e-mail",
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="Senha"
              inputProps={{
                type: "password",
                onChange: (e) => setPassword(e.target.value),
              }}
            />
            <InputText
              label="Confirmar Senha"
              inputProps={{
                type: "password",
                onChange: (e) => setPasswordConfirm(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <CheckBox
              label="Permissões"
              options={permissions}
              setOptions={setPermissions}
            />
          </RowInput>
          <ButtonContainer>
            <Button
              label="Cadastrar"
              loading={loading}
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function CreateUser() {
  return (
    <DashboardTemplate>
      <CreateUserContent />
    </DashboardTemplate>
  );
}

export default CreateUser;
