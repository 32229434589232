import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ImgBackground = styled.img`
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  z-index: 0;
`;
export const Logo = styled.img`
  z-index: 1;
  width: calc(200px + 10%);
  margin: 20px 0;
`;
export const ContainerCertifyValidation = styled.div`
  z-index: 1;
  min-width: 50%;
  width: 50%;
  /* min-width: 360px; */
  /* height: 500px;*/
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;

  padding: 40px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  @media (max-width: 760px) {
    width: 90%;
    padding: 20px;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const FormFooter = styled.div`
  margin: 30px 0 20px 0;
  width: 300px;
`;
export const Login = styled.div`
  color: #a8aeb9;
  margin-bottom: 10px;
  text-align: center;
  & span {
    color: #5baab3;
    cursor: pointer;
  }
  & span:hover {
    font-weight: 600;
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  color: #244c59;
  margin-bottom: 20px;
  margin-top: 10px;
`;
export const Description = styled.span`
  color: #9ca3af;
  font-weight: 400;
  font-size: 13px;
`;

export const InputContainer = styled.div`
  width: 50%;
  padding: 10px;

  @media (max-width: 1050px) {
    padding: 0;
    width: 100%;
  }
`;

export const BtnContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;

  @media (max-width: 1050px) {
    padding: 0;
    width: 100%;
  }
`;