import React, { useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import CheckBox from '../../../../components/forms/CheckBox';
import adminStudentsService from '../../../../services/adminStudentsService';
import { myAlert } from '../../../../lib/Alert';
import tagConst from '../../../../constants/tags';
import masks from '../../../../lib/masks'
function CreateStudentContent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(tagConst.array);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      let _tags = tags.filter((tag) => tag.status).map((tag) => tag.key);

      if (!fullname || !email || !cpfCnpj || !phone) {
        myAlert.open({
          title: 'Preencha todos os campos obrigatórios',
          type: 'warning',
        });
        setLoading(false);
        return;
      } else {
        const rest = await adminStudentsService.create({
          email,
          fullname,
          cpfCnpj,
          phone,
          tags: _tags,
        });


        setTimeout(() => {
          myAlert.open({
            title: 'Aluno cadastrado com sucesso',
            type: 'success',
          });
          navigate('/students', { replace: true });
        }, 500);
      }
    } catch (error) {

      setTimeout(() => {

        if (error && error.message) {

          myAlert.open({
            title: error.message,
            type: 'error',
          });
        } else {
          myAlert.open({
            title: 'Erro ao cadastrar aluno. Tente novamente ou contacte o suporte.',
            type: 'error',
          });
        }
        setLoading(false);
      }, 500);
    }
  };
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Cadastrar Aluno</Title>
        <SubTitle>Cadastre um novo aluno preenchendo o formulário abaixo</SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: 'text',
                value: fullname,
                onChange: (e) => setFullname(e.target.value),
              }}
            />
            <InputText
              label="E-mail"
              inputProps={{
                type: 'e-mail',
                value: email,
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="CPF ou CNPJ"
              inputProps={{
                type: 'text',
                value: cpfCnpj,

                onChange: (e) => setCpfCnpj(masks.cpf_cnpj_mask(e.target.value)),
              }}
            />
            <InputText
              label="Telefone"
              inputProps={{
                type: 'text',
                value: phone,
                onChange: (e) => setPhone(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <CheckBox label="Tags" options={tags} setOptions={setTags} />
          </RowInput>
          <ButtonContainer>
            <Button
              label="Cadastrar"
              loading={loading}
              buttonProps={{ type: 'submit' }}
              containerStyle={{ width: '200px' }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function CreateStudent() {
  return (
    <DashboardTemplate>
      <CreateStudentContent />
    </DashboardTemplate>
  );
}

export default CreateStudent;
