import { css } from 'styled-components';

const colors = {
  white: '#ffffff',
  whiteLightest: '#f7f7f7',
  whiteLighter: '#f6f6f9',
  whitelight: '#f0f0f1',
  whiteMindLight: '#f2f3f4',
  black: '#000000',
  blackTransparentDarkest: '#00000077',
  blackTransparentDarker: '#00000040',
  blackTransparentMind: '#00000033',
  blackTransparentMindLight: '#00000026',

  alertInfo: '#1cecec',
  alertWarning: '#f3ba2f',
  alertSuccess: '#1cec7c',
  alertError: '#ff463a',

  primary: '#2d687d',
  primaryFont: '#191654',
  primaryBtn: '#009789',
  primaryLink: '#44818e',
  primaryTextDisabled: '#8692aa',

  neutralDarkest: '#c6c6c6',
  neutralDarker: '#d2d2d2',
  neutralDark: '#aeaeae',
  neutralMind: '#979797',
  neutralMindLight: '#ebebf0',

  secundary: '#deebea',
  secundaryFont: '#2f7382',
  secondaryBtn: '#00247D',
  tertiary: '#43c6ac',
  tertiaryFont: '#403F55',

  titleCacauDigital: '#25486d',

  boxShadowWalletCard: '#d7dce7c2',
  maxButtonHover: '#3ca89d68',
  maxButtonBgc: '#3ca89d1c',
  selectOption: '#01323a',

  buttonStatusSuccessBgc: '#EEFAF5',
  buttonStatusSuccessBgc2: '#C6F6E2',
  buttonStatusSuccessFont: '#1A9574',
  buttonStatusErrorBgc: '#F9CDCD',
  buttonStatusErrorBgc2: '#e9b6b9',
  buttonStatusErrorFont: '#be3b3b',
  buttonStatusWarningBgc: '#FEF9EB',
  buttonStatusWarningBgc2: '#FBEFC7',
  buttonStatusWarningFont: '#C99A2E',
  buttonStatusInfoBgc: '#DBEDF9',
  buttonStatusInfoBgc2: '#CCE8F7',
  buttonStatusInfoFont: '#0F609B',
};
const sizes = {
  navbarHeightDesk: '64px',
  navbarHeightMob: '56px',
  inputsHeightDesk: '40px',
  inputsHeightMob: '40px',
  inputsWidthDesk: '320px',
  inputsWidthMob: '320px',
  checkboxHeightWidth: '15px',
  fontSizeSmallest: '10px',
  fontSizeSmaller: '12px',
  fontSizeMedium: '14px',
  fontSizeBig: '18px',
  fontSizeBigger: '28px',
  fontSizeBiggest: '36px',
};
const alertColors = {
  success: colors.alertSuccess,
  info: colors.alertInfo,
  error: colors.alertError,

  successBgc: colors.buttonStatusSuccessBgc,
  successBgc2: colors.buttonStatusSuccessBgc2,
  successFont: colors.buttonStatusSuccessFont,
  errorBgc: colors.buttonStatusErrorBgc,
  errorBgc2: colors.buttonStatusErrorBgc2,
  errorFont: colors.buttonStatusErrorFont,
  warningBgc: colors.buttonStatusWarningBgc,
  warningBgc2: colors.buttonStatusWarningBgc2,
  warningFont: colors.buttonStatusWarningFont,
  infoBgc: colors.buttonStatusInfoBgc,
  infoBgc2: colors.buttonStatusInfoBgc2,
  infoFont: colors.buttonStatusInfoFont,
};
const fonts = {
  inter: 'font-family: "Inter", sans-serif',
  dmSans: 'font-family: "DM Sans", sans-serif',
  barlow: 'font-family: "Barlow", sans-serif',
  raleway: 'font-family: "Raleway", sans-serif',
  roboto: 'font-family: "Roboto", sans-serif',
  poppins: 'font-family: "Poppins", sans-serif',
};
const paddingLayoutPage = css`
  padding: 0 20px;
`;
const aligns = {
  vertical: css`
    display: flex;
    align-items: center;
  `,
  horizontal: css`
    display: flex;
    justify-content: center;
  `,
  verticalAndHorizontal: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  initial: css`
    display: inline;
    align-items: initial;
    justify-content: initial;
  `,
};

const responsive = {
  sm: (content) =>
    css`
      @media only screen and (min-width: 350px) {
        ${content}
      }
    `,
  md: (content) =>
    css`
      @media only screen and (min-width: 600px) {
        ${content}
      }
    `,
  lg: (content) =>
    css`
      @media only screen and (min-width: 992px) {
        ${content}
      }
    `,
  xlg: (content) =>
    css`
      @media only screen and (min-width: 1280px) {
        ${content}
      }
    `,
};
const main = {
  colors,
  fonts,
  aligns,
  alertColors,
  paddingLayoutPage,
  sizes,
  responsive,
};

export default main;
