// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-family: "Inter", "Roboto", Helvetica, Arial;
}

textarea, input { outline: none; }
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,SAAS;EACT,sBAAsB;EACtB,gDAAgD;AAClD;;AAEA,kBAAkB,aAAa,EAAE;AACjC;EACE,qBAAqB;EACrB,SAAS;EACT,UAAU;AACZ","sourcesContent":["*{\n  margin: 0;\n  padding:0;\n  box-sizing: border-box;\n  font-family: \"Inter\", \"Roboto\", Helvetica, Arial;\n}\n\ntextarea, input { outline: none; }\nul {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
