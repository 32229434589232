import React, { useContext, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import imgBackground from '../../assets/background-login.png';
import logo from '../../assets/logo.png';
import Button from '../../components/forms/Button';
import InputText from '../../components/forms/InputText';
import {
  Container,
  ContainerLogin,
  ForgotPassword,
  Form,
  FormFooter,
  ImgBackground,
  Logo,
} from './styles';
import authService from '../../services/authService';
import { myAlert } from '../../lib/Alert';
import validator from '../../lib/validator';
import AuthContext from '../../hooks/Auth';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await login({ email, password });

      setTimeout(() => {
        myAlert.open({ title: res.message, type: 'success' }, true);
        navigate('/courses', { replace: true });
      }, 500);
    } catch (error) {
      setTimeout(() => {
        console.log(error);
        if (error && error.code) {
          if (!error.errors) {
            myAlert.open({ title: error.message, type: 'error' });
          } else {
            validator.verifyErrors(error);
          }
        } else {
          myAlert.open({ title: "Erro, contate o suporte", type: 'error' });
        }
        setLoading(false);
      }, 500);
    }
  };
  return (
    <Container>
      <ImgBackground src={imgBackground} />
      <Logo src={logo} />
      <ContainerLogin>
        <Form onSubmit={handleSubmit}>
          <InputText
            label="E-mail"
            inputProps={{ type: 'email', onChange: (e) => setEmail(e.target.value) }}
          />
          <InputText
            label="Senha"
            inputProps={{ type: 'password', onChange: (e) => setPassword(e.target.value) }}
          />
          <FormFooter>
            <ForgotPassword>
              Esqueceu sua senha?{' '}
              <span onClick={() => navigate('/forgot-password', { replace: true })}>
                Clique aqui
              </span>
            </ForgotPassword>
            <Button label={'Login'} loading={loading} buttonProps={{ type: 'submit' }} />
          </FormFooter>
        </Form>
      </ContainerLogin>
    </Container>
  );
}

export default Login;
