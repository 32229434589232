import React, { useEffect, useState } from 'react';
import InputSearchBar from '../../../../components/forms/InputSearchBar';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  ActionIcon,
  ActiveTag,
  Body,
  BodyHeader,
  Container,
  ContainerSearch,
  Header,
  HeaderDiv,
  SubTitle,
  Title,
} from './styles';

import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyColumn,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';

import DeleteModal from "../../../../components/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import iconEdit from "../../../../assets/edit.svg";
import iconTrash from "../../../../assets/trash.svg";
import iconView from "../../../../assets/view.svg";
import iconReload from "../../../../assets/reload.svg";
import Button from "../../../../components/forms/Button";
import adminCoursesService from "../../../../services/adminCoursesService";
import { myAlert } from "../../../../lib/Alert";
import SelectShowInactive from "../../../../components/forms/SelectShowInactive";
import Pagination from '../../../../components/Pagination';

function ListCourseContent() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [showInactive, setShowInactive] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [rowsPageSelected, setRowsPageSelected] = useState(1);
  const rowsPage = [5, 10, 20, 50];
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    perPage: 5,
    total: 0,
  });

  const getData = async ({ page = 1, perPage = 10 }) => {
    try {
      setLoading(true);
      const res = await adminCoursesService.list({
        find: search,
        isActive: showInactive,
        page,
        perPage,
      });
      setCourses(res.data);

      const lastPage =
        res.total > res.perPage ? Math.ceil(res.total / res.perPage) : 1;

      setPagination({
        lastPage,
        page: res.page,
        perPage: res.perPage,
        total: res.total,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({
        title:
          "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
        type: "error",
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    getData({});
  }, [search, loading2, showInactive]);
  const tableHeaderItems = [
    {
      label: "Nome",
      flex: 1,
    },
    {
      label: "Descrição do curso",
      flex: 1.3,
    },
    {
      label: "URL da Página do Curso",
      flex: 1,
    },
    {
      label: "Status",
      flex: 1,
    },
    {
      label: "Ações",
      flex: 1,
    },
  ];

  const handleChangeStatusItem = async ({ id, toActive, title }) => {
    try {
      setLoading2(true);
      await adminCoursesService.update({
        isActive: toActive,
        id,
      });

      myAlert.open({
        title: `Status de ${title} alterado com sucesso`,
        type: "success",
      });
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      myAlert.open({
        title: `Erro ao alterar status ${title}`,
        type: "error",
      });
    }
  };
  const syncCourses = async () => {
    try {
      setLoading3(true)
      const res = await adminCoursesService.sync()
      setLoading3(false)
      myAlert.open({ title: "Sincronização do curso em andamento. Ela pode levar alguns minutos", type: 'success' })
    } catch (error) {
      setLoading3(false)
      myAlert.open({ title: "Erro ao sincronizar cursos", type: 'error' })
      console.log(error);

    }
  }
  return (
    <Container>
      <Header>
        <HeaderDiv>
          <Title>Cursos</Title>
          <Button
            label="Sincronizar Cursos"
            onClick={syncCourses}
            loading={loading3}
            containerStyle={{ width: "200px" }}
          />
        </HeaderDiv>

        <SubTitle>Encontre todos os cursos cadastrados na plataforma </SubTitle>
      </Header>
      <Body>
        <BodyHeader>
          <ContainerSearch style={{ display: "flex" }}>
            <InputSearchBar
              loading={loading}
              inputProps={{
                type: "text",
                placeholder: "Pesquise pelo nome do curso",
                onChange: (e) => setSearch(e.target.value),
              }}
            />
            <SelectShowInactive
              inputProps={{
                onChange: (e) => setShowInactive(e.value),
              }}
            />
          </ContainerSearch>
          <Button
            label="Adicionar Curso"
            onClick={() => {
              navigate(`/courses/create`);
            }}
          />
        </BodyHeader>

        {showDelete && (
          <DeleteModal
            {...{
              show: showDelete,
              setShow: setShowDelete,
              title: modalContent.title || "",
              action: modalContent.action,
              label: modalContent.label,
            }}
          />
        )}

        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHeader>
              {tableHeaderItems.map((item, idx) => (
                <TableHeaderColumn key={idx} flex={item.flex}>
                  {item.label}
                </TableHeaderColumn>
              ))}
            </TableHeader>

            <TableBody>
              {courses &&
                courses.map((item, idx) => (
                  <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                    <TableBodyColumn>{item.title}</TableBodyColumn>
                    <TableBodyColumn flex={1.3}>
                      {item.description}
                    </TableBodyColumn>
                    <TableBodyColumn>
                      <a href={item.url} target="_black">
                        Link do curso
                      </a>
                    </TableBodyColumn>
                    <TableBodyColumn>
                      <ActiveTag $active={item.isActive}>
                        {item.isActive ? "Ativo" : "Inativo"}
                      </ActiveTag>
                    </TableBodyColumn>
                    <TableBodyColumn>
                      <ActionIcon
                        src={iconView}
                        onClick={() => {
                          navigate(`/courses/${item._id}`);
                        }}
                      />
                      {showInactive ? (
                        <ActionIcon
                          src={iconEdit}
                          onClick={() => {
                            navigate(`/courses/edit/${item._id}`);
                          }}
                        />
                      ) : null}
                      {showInactive ? (
                        <ActionIcon
                          src={iconTrash}
                          onClick={async () => {
                            setModalContent({
                              title: `Deseja desativar o curso ${item.title}?`,
                              action: () =>
                                handleChangeStatusItem({
                                  title: item.title,
                                  id: item._id,
                                  toActive: !item.isActive,
                                }),
                              label: "Desativar",
                            });
                            setShowDelete(true);
                          }}
                        />
                      ) : (
                        <ActionIcon
                          src={iconReload}
                          onClick={async () => {
                            setModalContent({
                              title: `Deseja ativar o curso ${item.title}?`,
                              action: () =>
                                handleChangeStatusItem({
                                  title: item.title,
                                  id: item._id,
                                  toActive: !item.isActive,
                                }),
                              label: "Ativar",
                            });
                            setShowDelete(true);
                          }}
                        />
                      )}
                    </TableBodyColumn>
                  </TableBodyRow>
                ))}
            </TableBody>
          </Table>
        </div>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: 'center', color: '#244c59' }}>
          <Pagination
            {...{
              ...pagination,
              rowsPageSelected,
              setRowsPageSelected,
              rowsPage,
              filter: { find: search, isActive: showInactive },
              getData: getData,
            }}
          />
          {`${(pagination.page - 1) * pagination.perPage + 1}-${Math.min(pagination.page * pagination.perPage, pagination.total)} de ${pagination.total}`}
        </Container>
      </Body>
    </Container>
  );
}
function ListCourse() {
  return (
    <DashboardTemplate>
      <ListCourseContent />
    </DashboardTemplate>
  );
}

export default ListCourse;
