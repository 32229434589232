import styled from 'styled-components';

export const ModalBackground = styled.div`
  z-index: 2;
  position: absolute;
  top: 10vh;
  max-width: 800px;
  width: 100%;
  right: 0;
  margin-top: -1px;
  
  background-color: #FFC631;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  
`;

export const ModalContent = styled.div`
  max-height: 17vh;
  width: 100%;
  overflow-y: auto;
  background-color: #FFC631;
  color: #091820;
  padding: 15px 35px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
`;

export const OwnerName = styled.h2`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #091820;
`;