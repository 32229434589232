import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';

import {
  Container, MessagesDiv, InputForm, FormDiv, ButtonForm, SingleMessageDiv, UserDiv, InformationalDiv, MessageName, FixedMessageContainer, LoadMessagesDiv,
  ContainerEmpty,
  MessageScroll

} from './styles';
import { Send } from '@styled-icons/fluentui-system-regular/Send';
import { Group } from '@styled-icons/boxicons-solid/Group';
import { PinAngle } from '@styled-icons/bootstrap/PinAngle';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import { EmojiAdd } from '@styled-icons/fluentui-system-regular/EmojiAdd';
import { myAlert } from '../../../../lib/Alert';

import Modal from './modal';
import EmojiModal from './emojimodal';
import AdminActions from './adminActions';
import serverConst from '../../../../constants/serverConst'

const ChatComponent = ({ username, isAdmin }) => {
  const { hash: id } = useParams();
  const [newMessage, setNewMessage] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [messagePage, setMessagePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [socket, setSocket] = useState(null);
  const [roomName, setRoomName] = useState('');
  const [fixedMessage, setFixedMessage] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isBottomMessages, setIsBottomMessages] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  const messagesDivRef = useRef(null);
  const messageRef = useRef(null);

  useEffect(() => {
    // Conexão WebSocket é iniciada quando o componente é montado
    const newSocket = io(serverConst.urlWebsocket + '/chat', { reconnection: true });

    // Salva o objeto socket no estado
    setSocket(newSocket);

    // Retorno da função de efeito para desconectar o WebSocket quando o componente é desmontado
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // Evento disparado quando uma mensagem é recebida do servidor

      socket.on('roomInfo', ({ roomName, roomMessages, fixedMessage, total, isActive }) => {
        setRoomName(roomName);
        setFixedMessage(fixedMessage);
        setTotal(total);
        setMessagePage(1);
        setReceivedMessages(roomMessages);
        setIsActive(isActive);
      });

      socket.on("resendMessages", (messages) => {
        setReceivedMessages(messages);
      });

      socket.on("resendFixedMessage", message => {
        setFixedMessage(message);
      })

      socket.on('disconnect', () => {
        myAlert.open({
          title: 'Desconectado do servidor. Tentando reconectar...',
          type: 'error',
        });
      });

      socket.on('connect', () => {
        myAlert.open({
          title: 'Conectado ao servidor',
          type: 'success',
        });
        socket.emit('joinRoom', { member: username, id });
      });

      socket.on('message', (data) => {
        // Adiciona a mensagem recebida à lista de mensagens recebidas
        setReceivedMessages(prevMessages => [...prevMessages, data.data]);
      });

      socket.on('loadMoreMessages', (messages) => {
        setReceivedMessages((prevMessages) => [...messages, ...prevMessages]);
      });
    }
  }, [socket]); // Executa sempre que o socket é alterado

  const handleMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Envia a mensagem para o servidor
    if (newMessage && socket) {
      const sendMessage = checkLink(newMessage);
      socket.emit('message', { id, newMessage: sendMessage, member: username });
      // Limpa o campo de mensagem após o envio
      setNewMessage('');
      setIsEmojiOpen(false);
    }
  };
  const toFirstMessage = (target) => {
    let messages = target ? target.querySelectorAll(".messages") : document.querySelectorAll(".messages")
    if (messages.length > 0) {
      messages[messages.length - 1].scrollIntoView({ block: "end" });
      if (document.querySelector("#input-form")) document.querySelector("#input-form").scrollIntoView({ block: "end" });
    }
  }

  const scrollPage = (e) => {

    const containerTarget = e.target || e.current

    if (containerTarget) { // Verifique se a ref está definida e, em seguida, acesse o elemento DOM

      // Caso não seja um evento e esteja com a opção de descer o chat
      if (!(e.nativeEvent && e.nativeEvent.type === "scroll") && isBottomMessages) {
        console.log("Não foi scroll");
        toFirstMessage(containerTarget)
      } else {
        console.log("Foi scroll");

      }

      // Caso esteja no final das mensagens ativa o scroll pra baixo automático
      if (containerTarget.scrollHeight - containerTarget.scrollTop - 200 < containerTarget.clientHeight) {
        setIsBottomMessages(true)
      } else {
        setIsBottomMessages(false)
      }

    }
  }

  useEffect(() => {

    scrollPage(messagesDivRef);
    if (receivedMessages.length > 0) {

      setIsInitialized(true)
    }


  }, [receivedMessages]); // O array vazio assegura que este efeito seja executado apenas uma vez após a montagem do componente

  function formatarHoraMinuto(time) {
    const toConvert = new Date(time);
    // Obter horas e minutos atuais
    const horas = toConvert.getHours();
    const minutos = toConvert.getMinutes();

    // Adicionar um zero à esquerda se o número for menor que 10
    const horasFormatadas = horas < 10 ? `0${horas}` : horas;
    const minutosFormatados = minutos < 10 ? `0${minutos}` : minutos;

    // Retornar a hora e os minutos formatados
    return `${horasFormatadas}:${minutosFormatados}`;
  }

  const checkLink = (string) => {
    const reURL = /https:\/\/(?:www\.)?[a-zA-Z0-9]([-.\w]*[a-zA-Z0-9])*\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?(?:\/[^\s()]*)?(?:\([^\s()]*\))?(?:\?[^\s()]*)?/g;

    const regex = new RegExp(reURL);

    const link = regex.exec(string);

    if (link) {
      const reHttps = new RegExp(/https:\/\/\S+/);

      if (!reHttps.test(link[0])) {
        const novolink = link[0];

        return string.replace(link[0], `<a href="${novolink}" target="_blank">${novolink}</a>`);
      }

      return string.replace(link[0], `<a href="${link[0]}" target="_blank">${link[0]}</a>`);
    }

    return string;
  }

  const changeModal = () => {
    setIsOpen(!isOpen);
  };

  const onDelete = (index) => {
    socket.emit('deleteMessage', receivedMessages[index]);
    myAlert.open({
      title: 'Mensagem apagada com sucesso',
      type: 'success',
    });
  }

  const onFix = (index) => {
    socket.emit('fixMessage', receivedMessages[index]);

    myAlert.open({
      title: 'Mensagem fixada com sucesso',
      type: 'success',
    });
  }

  const removeFixedMessage = () => {
    socket.emit('removeFixedMessage', { id, fixedMessage: '' });
  }

  const oldMessages = () => {
    setMessagePage(messagePage + 1);
    socket.emit('oldMessages', { id, messagePage: messagePage + 1 });
  }

  return (
    <ContainerEmpty>

      <Container>
        <UserDiv>
          <div style={{ paddingLeft: 20 }}>
            <h2>{roomName}</h2>
          </div>
        </UserDiv>
        {
          (isActive || isAdmin) && isOpen && fixedMessage && fixedMessage.content && (
            <Modal fixedMessage={fixedMessage} isOpen={isOpen} removeFixedMessage={removeFixedMessage} isAdmin={isAdmin}>
            </Modal>
          )
        }

        <MessagesDiv onScroll={scrollPage} ref={messagesDivRef} style={{ padding: fixedMessage && fixedMessage.content ? "70px 20px 10px 20px" : '10px 20px' }}>
          {
            (isActive || isAdmin) && receivedMessages.length >= (30 * messagePage) &&
            receivedMessages.length <= total && (
              <LoadMessagesDiv onClick={oldMessages}>
                <h4>Carregar mensagens anteriores</h4>
              </LoadMessagesDiv>
            )
          }
          {(isActive || isAdmin) && receivedMessages.map(({ owner, content, createdAt }, index) => (
            <SingleMessageDiv
              key={index}
              className={`messages ${owner.owner && owner.owner === username ? `mymessage${owner.owner.includes("Admin") ? " admin" : ""}` : `othermessage${owner.owner && owner.owner.includes("Admin") ? " admin" : ""}`}`}
              color={owner.color}
              margintop={index === 0 || receivedMessages[index - 1].owner.owner !== owner.owner ? '10px 0 0px 0' : '5px 0 0 0'}
            >
              {
                isAdmin && (
                  <AdminActions
                    index={index}
                    onDelete={onDelete}
                    onFix={onFix}
                    myMessage={owner.owner === username}
                  />
                )
              }

              <MessageName>
                {
                  owner.owner
                }
              </MessageName>
              {
                (content.includes('<a')) ? (
                  <span style={{ color: 'inherit', wordBreak: 'break-word', display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: content }} />
                ) : content
              }
              <InformationalDiv className="time">
                {
                  formatarHoraMinuto(createdAt)
                }
              </InformationalDiv>
            </SingleMessageDiv>
          ))}
        </MessagesDiv>

        {
          isActive ? (
            <FormDiv id="input-form">
              {!isBottomMessages ? <MessageScroll onClick={() => toFirstMessage()}>Rolar para baixo</MessageScroll> : null}
              <div>
                {
                  isEmojiOpen && (
                    <EmojiModal
                      isOpen={isEmojiOpen}
                      setNewMessage={setNewMessage}
                      newMessage={newMessage}
                    />
                  )
                }
                <EmojiAdd
                  style={{ color: 'white', cursor: 'pointer', position: 'relative' }}
                  size={30}
                  title={"Emojis"}
                  onClick={() => setIsEmojiOpen(!isEmojiOpen)}
                />
              </div>
              <InputForm
                type="text"
                placeholder="Digite sua mensagem"
                value={newMessage}
                onChange={handleMessageChange}
                ref={messageRef}
              />
              <ButtonForm onClick={handleSubmit}>
                <Send size={30} title={"Enviar"} />
              </ButtonForm>
            </FormDiv>
          ) : (
            <h2 style={{ textAlign: 'center', color: 'white', margintop: '10px' }}>Chat finalizado</h2>)
        }
      </Container >
    </ContainerEmpty>
  );
};

export default ChatComponent;
