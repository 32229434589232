import { handleApi } from './server';

const adminClassesService = {
  update: async (data) => {
    const { fileFront, fileBack, title, courseId, certificate, program, workload, isActive, id } = data;
    let formData = new FormData();
    const _data = {};
    if (title !== undefined) _data.title = title;
    if (courseId !== undefined) _data.courseId = courseId;
    if (program !== undefined) _data.program = program;
    if (certificate !== undefined) _data.certificate = certificate;
    if (workload !== undefined) _data.workload = workload;
    if (isActive !== undefined) _data.isActive = isActive;
    formData.append('data', JSON.stringify(_data));

    if (fileFront) {
      formData.append('front', fileFront, fileFront.name);
    }
    if (fileBack) {
      formData.append('back', fileBack, fileBack.name);
    }
    const response = await handleApi('/admin/classes/' + id, 'put', {
      data,
      formData,
    });
    return response;
  },
  create: async (data) => {
    const { fileFront, fileBack, title, courseId, certificate, program, workload } = data;
    let formData = new FormData();
    const _data = {
      title,
      courseId,
      program,
      certificate,
      workload,
    };
    formData.append('data', JSON.stringify(_data));

    if (fileFront) {
      formData.append('front', fileFront, fileFront.name);
    }
    if (fileBack) {
      formData.append('back', fileBack, fileBack.name);
    }
    const response = await handleApi('/admin/classes', 'post', {
      data,
      formData,
    });
    return response.data;
  },
  deleteStudent: async (data) => {
    const { studentId, classId } = data;
    const response = await handleApi('/admin/classes/remove-students/' + classId, 'post', {
      data: { studentId },
    });
    return response.data;
  },
  addStudent: async (data) => {
    const { studentIds, classId } = data;
    const response = await handleApi('/admin/classes/add-students/' + classId, 'post', {
      data: { studentIds },
    });
    return response.data;
  },
  list: async (data) => {
    const params = {
      ...data,
    };
    return await handleApi('/admin/classes', 'get', {
      data,
      params,
    });
  },
  show: async (id) => {
    return await handleApi('/admin/classes/' + id, 'get', {});
  },
};
export default adminClassesService;
