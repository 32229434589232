import React from 'react';

import { Container } from './styles';
import { TailSpin } from 'react-loader-spinner';

function Button({ type, label, loading, onClick, buttonProps, containerStyle, icon, iconHover }) {
  return (
    <Container onClick={onClick} style={containerStyle} type={type}>
      {loading ? (
        <TailSpin height="25" width="25" color="#5BAAB3" ariaLabel="" />
      ) : (
        <button {...buttonProps}>
          {label}
          {icon && <img className="icon" src={icon} alt="icon button" />}
          {iconHover && <img className="icon-hover" src={iconHover} alt="icon button" />}
        </button>
      )}
    </Container>
  );
}

export default Button;
