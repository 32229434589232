import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from './styles';

import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import CheckBox from '../../../../components/forms/CheckBox';
import InputText from '../../../../components/forms/InputText';
import { useParams } from 'react-router-dom';
import adminUsersService from '../../../../services/adminUsersService';
import { myAlert } from '../../../../lib/Alert';
import { useNavigate } from "react-router-dom";

function UpdateUserContent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [fullName, setFullname] = useState("");
  const [permissions, setPermissions] = useState([
    {
      label: "Administrador",
      key: "admin",
      status: false,
    },
    {
      label: "Cursos",
      key: "courses",
      status: false,
    },
    {
      label: "Turmas",
      key: "classes",
      status: false,
    },
    {
      label: "Alunos",
      key: "students",
      status: false,
    },
    {
      label: "Certificados",
      key: "certificates",
      status: false,
    },
    {
      label: "Chat",
      key: "chats",
      status: false,
    },
  ]);
  let { id } = useParams();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (user) {
      const newPermission = [];
      for (let i = 0; i < permissions.length; i++) {
        const permission = permissions[i];

        if (user.roles && user.roles.includes(permission.key)) {
          newPermission.push({ ...permission, status: true });
        } else {
          newPermission.push({ ...permission });
        }
      }

      setPermissions(newPermission);
    }
  }, [user]);
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const res = await adminUsersService.show(id);
        setUser(res.user);
        setEmail(res.user.email);
        setFullname(res.user.fullName);

        setLoading(false);
      } catch (error) {
        console.log(error);
        myAlert.open({
          title:
            "Erro ao buscar informação. Recarregue a página ou contate o suporte",
          type: "error",
        });
        setLoading(false);
      }
    };
    getUser();
  }, [id]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let roles = permissions.filter((perm) => perm.status);
      roles = roles.map((role) => role.key);

      const res = await adminUsersService.update({
        email,
        fullName,
        id,
        roles,
      });
      myAlert.open({ title: "Atualizado com sucesso!", type: "success" });
      navigate("/users", { replace: true });

      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({ title: "Erro ao atualizar usuário", type: "error" });
      setLoading(false);
    }
  };
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Atualização usuário</Title>
        <SubTitle>
          Você está atualizando as informações de <b>{user.name}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                value: fullName,
                onChange: (e) => setFullname(e.target.value),
              }}
            />
            <InputText
              label="E-mail"
              inputProps={{
                type: "e-mail",
                value: email,
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <CheckBox
              label="Permissões"
              options={permissions}
              setOptions={setPermissions}
            />
          </RowInput>
          <ButtonContainer>
            <Button
              label="Atualizar"
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
              loading={loading}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function UpdateUser() {
  return (
    <DashboardTemplate>
      <UpdateUserContent />
    </DashboardTemplate>
  );
}

export default UpdateUser;
