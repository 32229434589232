import React, { useContext } from 'react';
import { BrowserRouter, Navigate, Route, Routes as RoutesDom } from 'react-router-dom';

import LoadingPage from '../../pages/LoadingPage';
import Login from '../../pages/Login';
import ForgotPassword from '../../pages/ForgotPassword';
import ResetPassword from '../../pages/ResetPassword';
import CreateUser from '../../pages/dashboard/users/CreateUser';
import ListUser from '../../pages/dashboard/users/ListUser';
import UpdateUser from '../../pages/dashboard/users/UpdateUser';
import ShowUser from '../../pages/dashboard/users/ShowUser';
import ListCourse from '../../pages/dashboard/courses/ListCourse';
import ShowCourse from '../../pages/dashboard/courses/ShowCourse';
import CreateCourse from '../../pages/dashboard/courses/CreateCourse';
import UpdateCourse from '../../pages/dashboard/courses/UpdateCourse';
import ListClass from '../../pages/dashboard/classes/ListClass';
import CreateClass from '../../pages/dashboard/classes/CreateClass';
import UpdateClass from '../../pages/dashboard/classes/UpdateClass';
import ShowClass from '../../pages/dashboard/classes/ShowClass';
import ListStudent from '../../pages/dashboard/students/ListStudent';
import ShowStudent from '../../pages/dashboard/students/ShowStudent';
import CreateStudent from '../../pages/dashboard/students/CreateStudent';
import UpdateStudent from '../../pages/dashboard/students/UpdateStudent';
import ListCertify from '../../pages/dashboard/certifies/ListCertify';
import CreateCertify from '../../pages/dashboard/certifies/CreateCertify';
import ShowCertify from '../../pages/dashboard/certifies/ShowCertify';
import UpdateCertify from '../../pages/dashboard/certifies/UpdateCertify';

import CheckValidation from '../../pages/Validation/CheckValidation';
import ValidationConfirmed from '../../pages/Validation/ValidationConfirmed';
import EmailConfirmation from "../../pages/EmailConfirmation";
import ChatPage from '../../pages/Chat';

import AuthContext from "../../hooks/Auth";
import ListChat from '../../pages/dashboard/chats/ListChat';
import CreateChat from '../../pages/dashboard/chats/CreateChat';
import UpdateChat from '../../pages/dashboard/chats/UpdateChat';
import ShowChat from '../../pages/dashboard/chats/ShowChat';

const Routes = () => {
  const { token, user, isCheckingToken } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <RoutesDom>
        {isCheckingToken ? (
          <Route path="*" element={<LoadingPage />} />
        ) : (
          <>
            {token && user.roles ? (
              <>
                {
                  user.roles.includes("admin") || user.roles.includes("users") ?
                    <>
                      <Route path="/users" element={<ListUser />} />
                      <Route path="/users/:id" element={<ShowUser />} />
                      <Route path="/users/create" element={<CreateUser />} />
                      <Route path="/users/edit/:id" element={<UpdateUser />} />
                    </> : null

                }
                {
                  user.roles.includes("admin") || user.roles.includes("courses") ?
                    <>
                      <Route path="/courses" element={<ListCourse />} />
                      <Route path="/courses/:id" element={<ShowCourse />} />
                      <Route path="/courses/create" element={<CreateCourse />} />
                      <Route path="/courses/edit/:id" element={<UpdateCourse />} />
                    </> : null

                }
                {
                  user.roles.includes("admin") || user.roles.includes("classes") ?
                    <>
                      <Route path="/classes" element={<ListClass />} />
                      <Route path="/classes/:id" element={<ShowClass />} />
                      <Route path="/classes/create" element={<CreateClass />} />
                      <Route path="/classes/edit/:id" element={<UpdateClass />} />
                    </> : null

                }
                {
                  user.roles.includes("admin") || user.roles.includes("students") ?
                    <>
                      <Route path="/students" element={<ListStudent />} />
                      <Route path="/students/:id" element={<ShowStudent />} />
                      <Route path="/students/create" element={<CreateStudent />} />
                      <Route path="/students/edit/:id" element={<UpdateStudent />} />
                    </> : null

                }
                {
                  user.roles.includes("admin") || user.roles.includes("certificates") ?
                    <>
                      <Route path="/certificates" element={<ListCertify />} />
                      <Route path="/certificates/:id" element={<ShowCertify />} />
                      <Route path="/certificates/create" element={<CreateCertify />} />
                      <Route path="/certificates/edit/:id" element={<UpdateCertify />} />
                    </> : null

                }
                {
                  user.roles.includes("admin") || user.roles.includes("chats") ?
                    <>
                      <Route path="/chats" element={<ListChat />} />
                      <Route path="/chats/:id" element={<ShowChat />} />
                      <Route path="/chats/create" element={<CreateChat />} />
                      <Route path="/chats/edit/:id" element={<UpdateChat />} />
                    </> : null

                }
                <Route path="/" element={<Navigate to={user.roles[0] === 'admin' ? '/courses' : `/${user.roles[0]}`} />} />
              </>
            ) : (
              <>
                <Route path="/loading" element={<LoadingPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/login" element={<Login />} />

                {/* <Route path="/email-verification" element={<VerifyEmail />} /> */}
                <Route path="/" element={<Login />} />
              </>
            )}
            <Route path="/validar-certificado" element={<CheckValidation />} />
            <Route
              path="/validate-certificate/:hash"
              element={<ValidationConfirmed />}
            />
            <Route path="/email-verification" element={<EmailConfirmation />} />
            <Route path="/aula/chat/:hash" element={ <ChatPage /> } />

            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </RoutesDom>
    </BrowserRouter>
  );
};

export default Routes;
