import React, { useEffect, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
  ButtonContainer,
} from "./styles";

import ButtonBack from "../../../../components/forms/ButtonBack";
import CheckBox from "../../../../components/forms/CheckBox";
import InputText from "../../../../components/forms/InputText";
import { useParams } from "react-router-dom";
import adminUsersService from "../../../../services/adminUsersService";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/forms/Button";
import { myAlert } from "../../../../lib/Alert";

function ShowUserContent() {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([
    {
      label: "Administrador",
      key: "admin",
      status: false,
    },
    {
      label: "Cursos",
      key: "courses",
      status: false,
    },
    {
      label: "Turmas",
      key: "classes",
      status: false,
    },
    {
      label: "Alunos",
      key: "students",
      status: false,
    },
    {
      label: "Certificados",
      key: "certificates",
      status: false,
    },
    {
      label: "Chat",
      key: "chats",
      status: false,
    },
  ]);
  let { id } = useParams();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (user) {
      const newPermission = [];
      for (let i = 0; i < permissions.length; i++) {
        const permission = permissions[i];
        if (user.roles && user.roles.includes(permission.key)) {
          newPermission.push({ ...permission, status: true });
        } else {
          newPermission.push({ ...permission });
        }
      }

      setPermissions(newPermission);
    }
  }, [user]);
  useEffect(() => {
    const getUser = async () => {
      try {
        setLoading(true);
        const res = await adminUsersService.show(id);
        setUser(res.user);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setTimeout(() => {
          myAlert.open({
            title:
              "Erro ao buscar informação. Recarregue a página ou contate o suporte.",
            type: "error",
          });
          setLoading(false);
        }, 500);
      }
    };
    getUser();
  }, [id]);

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Visualização de usuário</Title>
        <SubTitle>
          Você está visualizando as informações de <b>{user.name}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                value: user.fullName,
                disabled: true,
              }}
            />
            <InputText
              label="E-mail"
              inputProps={{ type: "e-mail", value: user.email, disabled: true }}
            />
          </RowInput>
          <RowInput>
            <CheckBox
              disabled={true}
              label="Permissões"
              options={permissions}
              setOptions={setPermissions}
            />
          </RowInput>
        </Form>
        <ButtonContainer>
          <Button
            loading={loading}
            label="Editar"
            onClick={() => {
              navigate(`/users/edit/${id}`);
            }}
            containerStyle={{ width: "200px" }}
          />
        </ButtonContainer>
      </Body>
    </Container>
  );
}
function ShowUser() {
  return (
    <DashboardTemplate>
      <ShowUserContent />
    </DashboardTemplate>
  );
}

export default ShowUser;
