import ButtonBack from "../../../../components/forms/ButtonBack"
import { Body, Container, Header, ResumeContainer, ResumeText, Title } from "../CreateCertify/styles"

const ConfirmSend = ({ data = {}, setShowResume }) => {
    const {
        total, search, ativo, wasSent, course, classe
    } = data
    return <Container>
        <Header>
            <ButtonBack onClick={() => { setShowResume(false) }} />
            <Title>Certificados enviados por email com sucesso!</Title>

        </Header>
        <Body style={{ padding: 40 }}>
            <ResumeContainer>
                <ResumeText>Total de certificados enviados:</ResumeText> {total}
            </ResumeContainer>
            <br />
            <hr />
            <br />
            <br />
            <ResumeContainer>
                Busca:
            </ResumeContainer>
            <ResumeContainer>
                <ResumeText>Curso:  </ResumeText>{course || "---"}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Turma: </ResumeText> {classe || "---"}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Busca por: </ResumeText>{search}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Ativo: </ResumeText>{ativo ? "Não" : "Sim"}
            </ResumeContainer>
            <ResumeContainer>

                <ResumeText>Enviado email anteriormente? </ResumeText>{wasSent === null ? "---" : wasSent ? "Sim" : "Não"}
            </ResumeContainer>

        </Body>
    </Container>
}

export default ConfirmSend