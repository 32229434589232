import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  flex: 1;
`;
export const Input = styled.textarea`
  height: 250px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  &:focus {
    border: 1px solid #5baab3;
  }
`;
export const Label = styled.label`
  margin: 20px 0 8px 0;
  font-size: 13px;
`;
