import React, { useEffect, useRef, useState } from "react";
import DashboardTemplate from "../../../templates/DashboardTemplate";
import {
  Body,
  ButtonContainer,
  ColumnInput,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from "../CreateCertify/styles";

import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../components/forms/Button";
import ButtonBack from "../../../../components/forms/ButtonBack";
import InputText from "../../../../components/forms/InputText";
import { Label } from "../../../../components/forms/InputText/styles";
import {
  OptionInput,
  SelectInput,
} from "../CreateCertify/styles";
import uploadsvg from "../../../../assets/upload.svg";
import iconTrash from "../../../../assets/trash.svg";
import Select from "react-select";
import { TagContainer } from "../../students/ListStudent/styles";
import { ActionIcon, ActiveTag } from "../ListCertify/styles";
import { CertifyImage } from "../../classes/ShowClass/styles";
import adminCertificateService from "../../../../services/adminCertificateService";
import { myAlert } from "../../../../lib/Alert";
import adminCoursesService from "../../../../services/adminCoursesService";
import adminClassesService from "../../../../services/adminClassesService";
import masks from "../../../../lib/masks";
import ReactSelect from "react-select";

function UpdateCertifyContent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [courseIdx, setCourseIdx] = useState({ value: 0, label: '' });
  const [classIdx, setClassIdx] = useState({ value: 0, label: '' });
  const [template, setTemplate] = useState('course');
  const [issue, setIssue] = useState('');
  const [searchCourse, setSearchCourse] = useState('');
  const [searchClass, setSearchClass] = useState('');

  let { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await adminCertificateService.show(id);
        setData(res.data);
        setIssue(res.data.issueDate);
        setCourseIdx({ value: res.data.course._idx, label: res.data.course.title });
        setClassIdx({ value: res.data.class._idx, label: res.data.class.title });

        setLoading(false);
      } catch (error) {
        console.log(error);
        myAlert.open({
          title:
            "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
          type: "error",
        });
        setLoading(false);
      }
    };
    getData();
  }, [id]);
  useEffect(() => {
    const getCourse = async () => {
      try {
        setLoading(true);

        const coursesData = await adminCoursesService.list({ isActive: true, find: searchCourse });
        setCourses(coursesData.data);
        const classesData = await adminClassesService.list();
        setClasses(classesData.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
        // myAlert.open({
        //   title:
        //     "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
        //   type: "error",
        // });
        setLoading(false);
      }
    };
    getCourse();
  }, [searchCourse]);
  useEffect(() => {
    const getClass = async () => {
      try {
        setLoading(true);
        const classesData = await adminClassesService.list({ isActive: true, find: searchClass, courseId: courseIdx.value });
        setClasses(classesData.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        // myAlert.open({
        //   title:
        //     "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
        //   type: "error",
        // });
        setLoading(false);
      }
    };
    getClass();
  }, [searchClass, courseIdx]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      await adminCertificateService.update({
        id,
        courseId: courseIdx.value,
        classId: classIdx.value,
        template,
        issueDate: issue,
        isActive: data.isActive
      });

      myAlert.open({ title: "Atualizado com sucesso", type: "success" });
      setLoading(false);

    } catch (error) {
      console.log(error);
      myAlert.open({ title: "Erro ao atualizar", type: "error" });

      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Atualização Certificado</Title>
        <SubTitle>
          Você está atualizando as informações do certificado de hash {" "}
          <b>{data.hash}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <ColumnInput>
              <Label>Curso</Label>
              <ReactSelect
                options={courses.map((item, idx) => ({
                  value: item._id,
                  label: item.title,
                }))}
                placeholder={'Selecione'}
                value={courseIdx}
                onChange={(e) => {
                  setCourseIdx(e)

                }}
                isSearchable
                onInputChange={(inputValue) => {
                  if (inputValue) setSearchCourse(inputValue)
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // width: '230px',
                  }),
                }}
              />

            </ColumnInput>
            <ColumnInput>
              <Label>Turma</Label>
              <ReactSelect
                options={classes.map((item, idx) => ({
                  value: item._id,
                  label: item.title,
                }))}
                placeholder={'Selecione'}
                value={classIdx}
                onChange={(e) => {
                  setClassIdx(e)

                }}
                isSearchable
                onInputChange={(inputValue) => {
                  if (inputValue) setSearchClass(inputValue)
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // width: '230px',
                  }),
                }}
              />

            </ColumnInput>
          </RowInput>

          <RowInput style={{ alignItems: 'baseline' }}>
            <ColumnInput>
              <Label>Template</Label>
              <SelectInput onChange={(e) => setTemplate(e.target.value)}>
                <option value={'course'} defaultValue>
                  Curso
                </option>
                <option value={'class'}>Turma</option>
              </SelectInput>
            </ColumnInput>
            <ColumnInput>
              <InputText
                label="Data de emissão"
                inputProps={{
                  type: 'text',
                  value: issue,
                  onChange: (e) => setIssue(masks.dayMonthYearMask(e.target.value)),
                }}
              />
            </ColumnInput>
          </RowInput>
          <ButtonContainer>
            <Button
              label="Atualizar"
              loading={loading}
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function UpdateCertify() {
  return (
    <DashboardTemplate>
      <UpdateCertifyContent />
    </DashboardTemplate>
  );
}

export default UpdateCertify;
