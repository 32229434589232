import styled from 'styled-components';

export const BackIcon = styled.img`
  width: 20px;
  margin-right: 10px;
`;
export const BackContainer = styled.span`
  display: flex;
  margin-bottom: 40px;
  cursor: pointer;
  color: #5baab3;
  border: 1px solid #5baab3;
  width: 100px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 40px;
  border-radius: 5px;
  &:hover {
    background-color: #5baab311;
  }
`;
