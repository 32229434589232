import React, { useEffect, useState } from 'react';
import InputSearchBar from '../../../../components/forms/InputSearchBar';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  ActionIcon,
  ActiveTag,
  Body,
  BodyHeader,
  Container,
  ContainerSearch,
  Header,
  SubTitle,
  Title,
} from './styles';

import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyColumn,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';
import CopyText from '../../../../components/forms/CopyText';

import DeleteModal from "../../../../components/modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import iconEdit from "../../../../assets/edit.svg";
import iconTrash from "../../../../assets/trash.svg";
import iconView from "../../../../assets/view.svg";
import iconReload from "../../../../assets/reload.svg";
import Button from "../../../../components/forms/Button";
import { myAlert } from "../../../../lib/Alert";
import SelectShowInactive from "../../../../components/forms/SelectShowInactive";
import Pagination from '../../../../components/Pagination';
import adminChatsService from '../../../../services/adminChatsService';
import time from '../../../../lib/time';

function ListChatContent() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showInactive, setShowInactive] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const [rowsPageSelected, setRowsPageSelected] = useState(1);
  const rowsPage = [5, 10, 20, 50];
  const [pagination, setPagination] = useState({
    lastPage: 0,
    page: 1,
    perPage: 5,
    total: 0,
  });

  const getData = async ({ page = 1, perPage = 10 }) => {
    try {
      setLoading(true);
      const res = await adminChatsService.list({
        find: search,
        isActive: showInactive,
        page,
        perPage,
      });
      setData(res.data);

      const lastPage =
        res.total > res.perPage ? Math.ceil(res.total / res.perPage) : 1;

      setPagination({
        lastPage,
        page: res.page,
        perPage: res.perPage,
        total: res.total,
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        myAlert.open({
          title:
            "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
          type: "error",
        });
        setLoading(false);
      }, 500);
    }
  };
  useEffect(() => {
    getData({});
  }, [search, loading2, showInactive]);
  const tableHeaderItems = [
    {
      label: "Data de criação",
      flex: 1,
    },
    {
      label: "Nome",
      flex: 1,
    },
    {
      label: "ID da Sala",
      flex: 1,
    },
    {
      label: "Link da Sala",
      flex: 1,
    },
    {
      label: "Incorporar em site",
      flex: 1,
    },
    {
      label: "Ações",
      flex: 1,
    },
  ];
  const handleChangeStatusItem = async ({ id, toActive, title }) => {
    try {
      setLoading2(true);
      await adminChatsService.update({
        isActive: toActive,
        id,
      });

      myAlert.open({
        title: `Status de ${title} alterado com sucesso`,
        type: "success",
      });
      setLoading2(false);
    } catch (error) {
      setLoading2(false);
      console.log(error);
      myAlert.open({
        title: `Erro ao alterar status ${title}`,
        type: "error",
      });
    }
  };

  return (
    <Container>
      <Header>
        <Title>Chats</Title>
        <SubTitle>Encontre todos os chats cadastradas na plataforma</SubTitle>
      </Header>
      <Body>
        <BodyHeader>
          <ContainerSearch style={{ display: "flex" }}>
            <InputSearchBar
              loading={loading}
              inputProps={{
                type: "text",
                placeholder: "Pesquise pelo nome ou id do chat",
                onChange: (e) => setSearch(e.target.value),
              }}
            />
            <SelectShowInactive
              inputProps={{
                onChange: (e) => setShowInactive(e.value),
              }}
            />
          </ContainerSearch>
          <Button
            label="Criar novo Chat"
            onClick={() => {
              navigate(`/chats/create`);
            }}
          />
        </BodyHeader>

        {showDelete && (
          <DeleteModal
            {...{
              show: showDelete,
              setShow: setShowDelete,
              title: modalContent.title || "",
              action: modalContent.action,
              label: modalContent.label,
            }}
          />
        )}

        <div style={{ overflowX: 'auto' }}>
          <Table>
            <TableHeader style={{ minWidth: "350px" }}>
              {tableHeaderItems.map((item, idx) => (
                <TableHeaderColumn key={idx} flex={item.flex}>
                  {item.label}
                </TableHeaderColumn>
              ))}
            </TableHeader>
            <TableBody>
              {data.map((item, idx) => (
                <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                  <TableBodyColumn>{time.date2String(item.createdAt, true)}</TableBodyColumn>
                  <TableBodyColumn>{item.roomName}</TableBodyColumn>
                  <TableBodyColumn>
                    {item._id}
                  </TableBodyColumn>
                  <TableBodyColumn>
                    <a href={`/aula/chat/${item._id}`} target='_blank'>Abrir chat</a>
                  </TableBodyColumn>
                  <TableBodyColumn>
                    <CopyText text={`<iframe width="100%" height="800px" src="https://app.incantatoneuropsi.com.br/aula/chat/${item._id}" />`}>Copiar Iframe</CopyText>
                  </TableBodyColumn>
                  <TableBodyColumn>
                    <ActionIcon
                      src={iconView}
                      onClick={() => {
                        navigate(`/chats/${item._id}`);
                      }}
                    />
                    {showInactive ? (
                      <ActionIcon
                        src={iconEdit}
                        onClick={() => {
                          navigate(`/chats/edit/${item._id}`);
                        }}
                      />
                    ) : null}
                    {showInactive ? (
                      <ActionIcon
                        src={iconTrash}
                        onClick={async () => {
                          setModalContent({
                            title: `Deseja desativar o chat "${item.roomName} (${item._id})"?`,
                            action: () =>
                              handleChangeStatusItem({
                                title: item.roomName,
                                id: item._id,
                                toActive: !item.isActive,
                              }),
                            label: "Desativar",
                          });
                          setShowDelete(true);
                        }}
                      />
                    ) : (
                      <ActionIcon
                        src={iconReload}
                        onClick={async () => {
                          setModalContent({
                            title: `Deseja ativar a turma ${item.roomName}?`,
                            action: () =>
                              handleChangeStatusItem({
                                title: item.roomName,
                                id: item._id,
                                toActive: !item.isActive,
                              }),
                            label: "Ativar",
                          });
                          setShowDelete(true);
                        }}
                      />
                    )}
                  </TableBodyColumn>
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </div>
        <Container style={{ display: "flex", justifyContent: "center", alignItems: 'center', color: '#244c59' }}>
          <Pagination
            {...{
              ...pagination,
              rowsPageSelected,
              setRowsPageSelected,
              rowsPage,
              filter: { find: search, isActive: showInactive },
              getData: getData,
            }}
          />
          {`${(pagination.page - 1) * pagination.perPage + 1}-${Math.min(pagination.page * pagination.perPage, pagination.total)} de ${pagination.total}`}
        </Container>
      </Body>
    </Container>
  );
}
function ListChat() {
  return (
    <DashboardTemplate>
      <ListChatContent />
    </DashboardTemplate>
  );
}

export default ListChat;
