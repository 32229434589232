import styled from 'styled-components';

export const ModalContent = styled.div`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  
  display: flex;
  flex-direction: column;
  
  &.mymessage {
    &.open {
      top: 0;
      left: -32px;
    }

    &.closed {
      left: -17px;
      top: 0;
    }

    > div {
      color: black;
      display: flex;
      flex-flow: column;
    }
  }

  &.othermessage {
    &.open {
      top: 0;
      right: -32px;
    }

    &.closed {
      right: -17px;
      top: 0;
    }

    > div {
      color: black;
      display: flex;
      flex-flow: column;
    }
  }
`;

export const OptionsDiv = styled.div`
  padding: 5px;
  /* right: 25px; */
`;