import styled from 'styled-components';
import bgimg from '../../../../assets/background-login.png';

export const Container = styled.div`
  max-width: 800px;
  position: relative;
  width: 100%;
  min-height: 100vh;
  box-shadow: -5px -5px 5px -5px rgba(0, 0, 0, 0.5);
`;
export const ContainerEmpty = styled.div`
  background-color: #1E1E1E;
width: 100%;
min-height: 100vh;
display: flex;
justify-content: center;
`


export const UserDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 10vh;
    background-color: #222831;
    color: white;
    box-shadow: 5px 5px 10px -5px rgba(0, 0, 0, 0.5);

    &>div {
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: 600px) {
        &>div h2 {
            font-size: 16px;
        }
    }
`;

export const MessagesDiv = styled.div`
    background-image: url(${bgimg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    min-height: 450px;
    height: 80vh;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    overflow-y: auto;
    scroll-behavior: smooth;
    .messages{
        scroll-margin-top: 100px;
    }
    
`;

export const FixedMessageContainer = styled.div`
    font-size: 14px;
    display: flex;
    gap: 8px;
    max-width: 30%;

    &>span {
        cursor: pointer;
    }

    @media screen and (max-width: 600px){
        &>span {
            display: none;
        }
    }
`;

export const MessageName = styled.span`
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 3px;
`;
export const MessageScroll = styled.div`
    z-index: 10;
    position: absolute;
    top: -60px;
    color:#244C59;
    font-weight: 600;
    background-color: #FFC631;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    &:hover{
        color: #091820;
        

    }
`
export const SingleMessageDiv = styled.div`
    position: relative;
    max-width: 65%;
    margin: ${props => props.margintop};
    word-wrap: break-word;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.5);
    &&.mymessage {
        
        background-color: white;
        color: black;
        align-self: flex-end;

        && span {
            color: #244C59;
        }
    }

    &&.othermessage {
        align-self: flex-start;
        background-color: white;
        color: black;

        && span {
            color: #00ADB5;
        }
    }

    &&.admin {
        background-color: #00ADB5;
        color: white;

        && span {
            color: #244C59;
        }
    }

    & .time{
        display: none;
    }
    &:hover .time{
        display: flex;
    }

    @media screen and (max-width: 600px){
        max-width: 85%;
    }
`;

export const InformationalDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2px;
    font-size: 12px;
    color: #585858;
`;

export const FormDiv = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;

    height: 10vh;

    background-color: #244C59;
    padding: 10px 20px;

    box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.5);
`;

export const InputForm = styled.input`
    /* background-color: #386572; */
    background-color: white;
    border: none;
    width: 90%;
    color: black;
    font-size: 16px;
    max-width: 700px;

    border-radius: 5px;
    padding: 10px;

    &::placeholder {
        color: black;
        
    }
`;

export const ButtonForm = styled.button`
    width: 5%;
    color: white;
    background-color: inherit;
    border: none;
    cursor: pointer;
`;

export const LoadMessagesDiv = styled.div`
    text-align: center;
    align-self: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    color: black;
    background-color: #393E46;
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.5);
    color: #00ADB5;
    width: 200px;
    cursor: pointer;
    margin-bottom: 5px;
`;