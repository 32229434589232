import styled from 'styled-components';

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #313131aa;
  z-index: 100;
`;
export const CloseContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;
export const Modal = styled.div`
  z-index: 10;
  background-color: #fff;
  width: 50%;
  height: 200px;
  border-radius: 15px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Title = styled.span`
  padding-top: 10px;
  font-size: 20px;
  font-weight: bold;
`;
export const TextContent = styled.span`
  font-size: 16px;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Body = styled.div`
  display: flex;
  justify-content: space-between;
`;
