import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

    @media (max-width: 768px) {
        flex-wrap: wrap;
        width: auto;
    }
`;
export const Input = styled.input`
  margin-right: 10px;
  flex: 1;
  height: 40px;
  padding: 0 5px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  &:focus {
    border: 1px solid #5baab3;
  }
`;
export const Label = styled.label`
  margin: 20px 0 8px 0;
  font-size: 13px;
`;
