import React, { useState } from 'react';
import { Container, ContainerLogin, Form, FormFooter, ImgBackground, Logo } from './styles';
import InputText from '../../../../components/forms/InputText';
import Button from '../../../../components/forms/Button';
import imgBackground from '../../../../assets/background-login.png';
import logo from '../../../../assets/logo.png';

const UserRequestComponent = ({ setUsername, invalid }) => {
    const [tempUsername, setTempUsername] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem('userChatLogin', tempUsername);
        setUsername(tempUsername);
    }

    return (
        <Container>
            <ImgBackground src={imgBackground} />
            <Logo src={logo} />
            <ContainerLogin>
                <Form onSubmit={handleSubmit}>
                    <InputText
                        label="Digite seu nome:"
                        inputProps={{ type: 'text', onChange: (e) => setTempUsername(e.target.value) }}
                    />
                    <FormFooter>
                        <Button label={'Começar chat'} loading={loading} buttonProps={{ type: 'submit' }} />
                    </FormFooter>
                </Form>
            </ContainerLogin>
        </Container>
    );
}

export default UserRequestComponent;