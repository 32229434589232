import React, { useEffect, useRef, useState } from 'react';
import DashboardTemplate from '../../../templates/DashboardTemplate';
import {
  Body,
  ButtonContainer,
  ColumnInput,
  Container,
  Form,
  Header,
  InputCsv,
  LabelCsvInput,
  OptionInput,
  RowInput,
  SubTitle,
  Title
} from './styles';

import {
  Table,
  TableBody,
  TableBodyColumn,
  TableBodyRow,
  TableHeader,
  TableHeaderColumn,
} from '../../users/ListUser/styles';

import Select from 'react-select';
import iconTrash from '../../../../assets/trash.svg';
import uploadsvg from '../../../../assets/upload.svg';
import ViewCertificate from '../../../../components/certificate/ViewCertificate';
import Button from '../../../../components/forms/Button';
import ButtonBack from '../../../../components/forms/ButtonBack';
import InputText from '../../../../components/forms/InputText';
import { Label } from '../../../../components/forms/InputText/styles';
import { myAlert } from '../../../../lib/Alert';
import files from '../../../../lib/files';
import masks from '../../../../lib/masks';
import time from '../../../../lib/time';
import adminCertificateService from '../../../../services/adminCertificateService';
import adminClassesService from '../../../../services/adminClassesService';
import adminCoursesService from '../../../../services/adminCoursesService';
import adminStudentsService from '../../../../services/adminStudentsService';
import { ActionIcon, ActiveTag } from '../../students/ListStudent/styles';
import ConfirmSend from './ConfirmSend';

function CreateCertifyContent() {

  const [students, setStudents] = useState([]);
  const [listStudents, setListStudents] = useState([]);
  const [listStudentsClass, setListStudentsClass] = useState([]);

  const [search, setSearch] = useState('');
  const [searchCourse, setSearchCourse] = useState('');
  const [searchClass, setSearchClass] = useState('');
  const [searchStudent, setSearchStudent] = useState('');

  const [issue, setIssue] = useState(time.date2String(new Date(), false));
  const [imageFront, setImageFront] = useState('');
  const [imageBack, setImageBack] = useState('');
  const [classCertificate, setClassCertificate] = useState(true);
  const [textTemplate, setTextTemplate] = useState('');
  const [classes, setClasses] = useState([]);
  const [classIdx, setClassIdx] = useState();
  const [courses, setCourses] = useState([]);
  const [courseIdx, setCourseIdx] = useState();
  const [sendMail, setSendMail] = useState('false');
  const [template, setTemplate] = useState('course');
  const [showResume, setShowResume] = useState(false);

  const [loading, setLoading] = useState(false);
  const csvRef = useRef();
  useEffect(() => {
    if (courses.length > 0 && courses[courseIdx] && template === 'course') {

      if (!courses[courseIdx].certificate || !courses[courseIdx].certificate.front || !courses[courseIdx].certificate.back) {
        // if(template === 'course'){
        // myAlert.open({
        //   title: 'Imagens não encontradas. Verifique os dados do curso.',
        //   type: 'error',
        // });

        // }
        setImageFront('');
        setImageBack('');
        setTextTemplate('');
        return
      }

      setImageFront(courses[courseIdx].certificate.front.file);
      setImageBack(courses[courseIdx].certificate.back.file);
      setTextTemplate(courses[courseIdx].certificate.text);
    }


    if (template === 'class' && classes.length > 0 && classes[classIdx]) {
      if (
        !classes[classIdx].certificate.front ||
        !classes[classIdx].certificate.front.file ||
        !classes[classIdx].certificate.back ||
        !classes[classIdx].certificate.back.file ||
        !classes[classIdx].certificate.text
      ) {
        myAlert.open({
          title: 'Template de turma não encontrado. Usando template do curso',
          type: 'warning',
        });
      } else {
        setImageFront(classes[classIdx].certificate.front.file);
        setImageBack(classes[classIdx].certificate.back.file);
        setTextTemplate(classes[classIdx].certificate.text);
      }
    }
  }, [template, courses, courseIdx, classIdx]);
  useEffect(() => {
    const getClass = async () => {
      try {

        const classe = await adminClassesService.list({
          find: searchClass,
          isActive: true,
          courseId: courses[courseIdx]._id,
          perPage: 50

        });
        setClasses(classe.data);
      } catch (error) {
        console.log(error);
        // myAlert.open({
        //   title: 'Turmas não encontradas. Verifique os dados cadastrados.',
        //   type: 'warning',
        // });
      }
    };

    if (courses.length > 0) getClass();
  }, [courses, courseIdx, searchClass]);




  useEffect(() => {
    const getDataClassStudents = async () => {
      try {
        setLoading(true);
        const res = await adminClassesService.show(classes[classIdx]._id);
        // setClassData(res.data);
        if (res.data.students.length > 0) {
          setListStudentsClass(res.data.students);
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    if (classes[classIdx]) getDataClassStudents();
  }, [classes, classIdx]);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const course = await adminCoursesService.list({
          find: searchCourse,
          isActive: true,
          perPage: 100
        });
        setCourses(course.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        myAlert.open({
          title: 'Cursos não encontrados. Verifique os dados cadastrados.',
          type: 'warning',
        });
        setLoading(false);
      }
    };
    getData();
  }, [searchCourse]);
  useEffect(() => {
    if (!classCertificate) {
      const getStudents = async () => {
        try {
          const res = await adminStudentsService.list({
            find: searchStudent,
            isActive: true,
            perPage: 100
          });
          setStudents(res.data);
        } catch (error) {
          console.log(error);
          myAlert.open({
            title: 'Alunos não encontrados. Verifique os dados cadastrados.',
            type: 'warning',
          });
        }
      };
      getStudents();
    }
  }, [classCertificate, searchStudent]);

  const tableHeaderItems = [
    {
      label: 'Nome',
      flex: 1,
    },
    {
      label: 'E-mail',
      flex: 1.3,
    },

    {
      label: 'Origem',
      flex: 1,
    },
    {
      label: 'Remover',
      flex: 1,
    },
  ];
  const tableHeaderItemsClass = [
    {
      label: 'Nome',
      flex: 1,
    },
    {
      label: 'Email',
      flex: 1.3,
    },

  ];


  const onChangeFile = async () => {
    const currFile = csvRef;
    if (currFile.current.files[0]) {
      const csvString = await files.readCsv(currFile.current.files[0]);
      const newStudents = files.csvJSON(csvString);
      const formatStudent = [];
      let error = { status: false };
      for (let i = 0; i < newStudents.length; i++) {
        const currStudent = newStudents[i];
        if (!currStudent.email) {
          error = {
            status: true,
            message: `Email não encontrado no ${i + 1}º item. Verifique seu arquivo novamente`,
          };
          break;
        }
        if (!currStudent.nome) {
          error = {
            status: true,
            message: `Nome não encontrado no ${i + 1}º item. Verifique seu arquivo novamente`,
          };
          break;
        }
        const existEmail = listStudents.find(value => value.email === currStudent.email)
        if (existEmail) {
          myAlert.open({
            title: `Email ${currStudent.email} ignorado, email já adicionado na lista.`,
            type: 'warning'
          });
          continue;
        }
        const newStudent = {
          _id: currStudent._id || crypto.randomUUID(),
          email: currStudent.email,
          newStudent: true,
        };
        if (currStudent.nome) newStudent.fullname = currStudent.nome;
        if (currStudent.cpf) newStudent.cpfCnpj = currStudent.cpf;

        formatStudent.push(newStudent);
      }
      if (error.status) {
        myAlert.open({
          title: error.message,
          type: 'error',
        });
        currFile.current.value = '';
        return;
      }

      setListStudents([...listStudents, ...formatStudent]);
      currFile.current.value = '';

      return;
    }
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (!classCertificate && listStudents.length === 0) {
        myAlert.open({ title: 'Nenhum aluno cadastrado', type: 'warning' });
        setLoading(false);
        return;
      }
      if (!imageFront && !imageBack) {
        myAlert.open({ title: 'Nenhuma imagem encontrada para geração do certificado. Verifique os dados do template', type: 'warning' });
        setLoading(false);
        return;
      }
      const newCertificate = {
        courseId: courses[courseIdx]._id,
        classId: classes[classIdx]._id,
        template,
        type: classCertificate ? 'class' : 'individual',
        issueDate: issue,
        sendMail: sendMail === 'true',
      };

      if (!classCertificate) newCertificate.students = listStudents;

      await adminCertificateService.create(newCertificate);
      setTimeout(() => {
        myAlert.open({
          title: 'Certificado cadastrado com sucesso',
          type: 'success',
        });
        // navigate('/certificates', { replace: true });
        setShowResume(true)
      }, 500);
    } catch (error) {
      console.log(error);
      setTimeout(() => {
        const errorRes = error.response;
        console.log(errorRes);
        myAlert.open({
          title: 'Erro ao emitir certificado. Tente novamente ou contacte o suporte.',
          type: 'error',
        });

        setLoading(false);
      }, 500);
    }
  };

  return showResume ? (
    <ConfirmSend data={{
      total: !classCertificate ? listStudents.length : listStudentsClass.length,
      course: courses[courseIdx].title,
      classe: classes[classIdx].title,
      template,
      type: classCertificate ? 'class' : 'individual',
      issueDate: issue,
      sendMail: sendMail === 'true',
    }} />
  ) : (
    < Container >
      <Header>
        <ButtonBack />
        <Title>Emitir Certificado</Title>
        <SubTitle>Emita um novo certificado preenchendo o formulário abaixo</SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <ColumnInput>
              <Label>Curso</Label>
              <Select
                options={courses.map((item, idx) => ({
                  value: idx,
                  label: item.title,
                }))}

                placeholder={(courses[courseIdx] && courses[courseIdx].title) || 'Selecione'}
                onChange={(e) => {
                  setCourseIdx(e.value)
                }}
                isSearchable
                onInputChange={(inputValue) => {
                  if (inputValue) setSearchCourse(inputValue)
                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // width: '230px',
                  }),
                }}
              />

            </ColumnInput>
            <ColumnInput>
              <Label>Turma</Label>
              <Select

                options={classes.map((item, idx) => ({
                  value: idx,
                  label: item.title,
                }))}
                isSearchable
                onInputChange={(inputValue) => {
                  if (inputValue) {
                    setSearchClass(inputValue)
                  }

                }}
                placeholder="Selecione"
                onChange={(e) => {
                  setClassIdx(e.value)

                }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
              />

            </ColumnInput>
          </RowInput>

          <RowInput >
            <ColumnInput>
              <Label>Template</Label>
              <Select

                options={
                  [
                    { value: "course", label: "Curso" },
                    { value: "class", label: "Turma" },
                  ]
                }

                placeholder="Curso"
                onChange={(e) => {
                  setTemplate(e.value)
                }
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
              />

            </ColumnInput>
            <ColumnInput>
              <InputText
                label="Data de emissão"
                inputProps={{
                  type: 'text',
                  value: issue,
                  onChange: (e) => setIssue(masks.dayMonthYearMask(e.target.value)),
                }}
              />
            </ColumnInput>
          </RowInput>
          <RowInput >
            <ColumnInput>
              <Label>Enviar certificado por email após emissão?</Label>
              <Select

                options={
                  [
                    { value: "false", label: "Não" },
                    { value: "true", label: "Sim" },
                  ]
                }

                placeholder="Não"
                onChange={(e) => setSendMail(e.value)}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                  }),
                }}
              />
            </ColumnInput>
            <ColumnInput></ColumnInput>
          </RowInput>

          <RowInput style={{ marginTop: '20px' }}>
            {courses && courses[courseIdx] && classes && classes[classIdx] ?
              imageFront && imageBack ? (
                <ViewCertificate
                  {...{
                    course: courses[courseIdx],
                    classe: classes[classIdx],

                    front: {
                      url: imageFront,
                      text: textTemplate,
                    },
                    back: {
                      url: imageBack,
                    },
                    issueDate: issue
                  }}
                />
              ) : <h2 style={{ margin: "30px 0 20px 0", fontWeight: 500, color: "#EA5C47", fontSize: 20 }}>Imagem do certificado não encontradas. Verifique os dados do template</h2> : null}
          </RowInput>
          <RowInput style={{ alignItems: 'baseline' }}>
            <Label style={{ fontWeight: '700', marginRight: '12px' }}>Alunos</Label>
            <OptionInput
              type="radio"
              id="turmainteira"
              name="alunos"
              value="selecionarturma"
              checked={classCertificate}
              onClick={() => {
                setClassCertificate(true);
              }}
            />
            <Label htmlFor="turmainteira">Turma inteira</Label>
            <OptionInput
              type="radio"
              id="selecionaralunos"
              name="alunos"
              value="selecionaralunos"
              onClick={() => {
                setClassCertificate(false);
              }}
            />
            <Label htmlFor="selecionaralunos">Selecionar alunos</Label>
          </RowInput>
          {!classCertificate ? (
            <>
              <RowInput
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flexWrap: 'wrap' }}>
                  <Select
                    placeholder="Selecionar aluno"
                    value={{
                      value: search,
                      label: search ? search.fullname + " - " + search.email : '',
                    }}
                    options={students.map((student) => ({
                      value: student,
                      label: student.fullname + ' - ' + student.email,
                    }))}
                    isSearchable
                    onInputChange={(inputValue) => {
                      if (inputValue) setSearchStudent(inputValue)
                    }}
                    onChange={(e) => setSearch(e.value)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: '400px',
                      }),
                    }}
                  />
                  <Button
                    label={'Adicionar'}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!listStudents.find(({ _id }) => _id === search._id)) {
                        setListStudents([...listStudents, search]);
                      }
                      setSearch('')
                    }}
                  />
                </div>
                <LabelCsvInput htmlFor="alunoscsv">
                  <img src={uploadsvg} alt="upload" style={{ width: '20px', height: '20px' }} />
                  Enviar csv
                </LabelCsvInput>
                <InputCsv
                  {...{
                    type: 'file',
                    ref: csvRef,
                    id: 'alunoscsv',
                    accept: '.csv',
                    multiple: true,
                    onChange: () => onChangeFile('back'),
                  }}
                />
              </RowInput>

              <Table>
                <TableHeader>
                  {tableHeaderItems.map((item, idx) => (
                    <TableHeaderColumn key={idx} flex={item.flex}>
                      {item.label}
                    </TableHeaderColumn>
                  ))}
                </TableHeader>
                <TableBody>
                  {listStudents.length > 0 &&
                    listStudents.map((item, idx) => (
                      <TableBodyRow
                        key={idx}
                        $bgc={idx % 2 > 0}
                        style={{
                          height: 'auto',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                        }}
                      >
                        <TableBodyColumn>{item.fullname}</TableBodyColumn>
                        <TableBodyColumn flex={1.3}>{item.email}</TableBodyColumn>

                        <TableBodyColumn>
                          <ActiveTag $active={true}>{item.newStudent ? 'CSV' : 'BD'}</ActiveTag>
                        </TableBodyColumn>
                        <TableBodyColumn>
                          <ActionIcon
                            src={iconTrash}
                            onClick={() => {
                              setListStudents(listStudents.filter(({ _id }) => _id !== item._id));
                            }}
                          />
                        </TableBodyColumn>
                      </TableBodyRow>
                    ))}
                </TableBody>
              </Table>
            </>
          ) : <div style={{ overflowX: 'auto' }}>
            <Table>
              <Label style={{ fontSize: 16, marginBottom: 15, textAlign: 'center' }}>Alunos cadastrados na turma</Label>

              <TableHeader>
                {tableHeaderItemsClass.map((item, idx) => (
                  <TableHeaderColumn key={idx} flex={item.flex}>
                    {item.label}
                  </TableHeaderColumn>
                ))}
              </TableHeader>
              <TableBody>
                {listStudentsClass.length > 0 &&
                  listStudentsClass.map((item, idx) => (
                    <TableBodyRow key={idx} $bgc={idx % 2 > 0}>
                      <TableBodyColumn>{item.fullname}</TableBodyColumn>
                      <TableBodyColumn flex={1.3}>{item.email}</TableBodyColumn>
                    </TableBodyRow>
                  ))}
              </TableBody>
            </Table>
          </div>}

          <ButtonContainer>
            <Button
              label="Cadastrar"
              loading={loading}
              buttonProps={{ type: 'submit' }}
              containerStyle={{ width: '200px' }}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container >
  );
}
function CreateCertify() {
  return (
    <DashboardTemplate>
      <CreateCertifyContent />
    </DashboardTemplate>
  );
}

export default CreateCertify;
