import { handleApi } from './server';

const adminCoursesService = {
  update: async (data) => {
    const { fileFront, fileBack, title, description, certificate, url, id, isActive } = data;

    let formData = new FormData();
    const _data = {};
    if (title !== undefined) _data.title = title;
    if (description !== undefined) _data.description = description;
    if (certificate !== undefined) _data.certificate = certificate;
    if (url !== undefined) _data.url = url;
    if (isActive !== undefined) _data.isActive = isActive;
    formData.append('data', JSON.stringify(_data));

    if (fileFront) {
      formData.append('front', fileFront, fileFront.name);
    }
    if (fileBack) {
      formData.append('back', fileBack, fileBack.name);
    }
    const response = await handleApi('/admin/courses/' + id, 'put', {
      data,
      formData,
    });
    return response;
  },
  sync: async () => {

    const response = await handleApi('/admin/courses/sync', 'post', {

    });
    return response
  },
  create: async (data) => {
    const { fileFront, fileBack, title, description, certificate, url } = data;
    let formData = new FormData();
    const _data = {
      title,
      description,
      certificate,
      url,
    };
    formData.append('data', JSON.stringify(_data));

    if (fileFront) {
      formData.append('front', fileFront, fileFront.name);
    }
    if (fileBack) {
      formData.append('back', fileBack, fileBack.name);
    }
    const response = await handleApi('/admin/courses', 'post', {
      data,
      formData,
    });
    return response.data;
  },
  list: async (data) => {
    const params = {
      ...data,
    };
    return await handleApi('/admin/courses', 'get', {
      data,
      params,
    });
  },
  show: async (id) => {
    return await handleApi('/admin/courses/' + id, 'get', {});
  },
};
export default adminCoursesService;
