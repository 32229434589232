import { handleApi } from './server';

const adminStudentsService = {
  update: async (data) => {
    const { fullname, email, phone, cpfCnpj, id, tags, isActive } = data;
    const response = await handleApi('/admin/students/' + id, 'put', {
      data: { fullname, email, phone, cpfCnpj, tags, isActive },
    });
    return response;
  },
  create: async (data) => {
    const { email, fullname, phone, cpfCnpj, tags } = data;
    const response = await handleApi('/admin/students', 'post', {
      data: { email, fullname, phone, cpfCnpj, tags },
    });
    return response.data;
  },
  list: async (data) => {
    const params = {
      ...data,
    };
    return await handleApi('/admin/students', 'get', {
      data,
      params,
    });
  },
  sync: async () => {
    return await handleApi('/admin/students/sync', 'post', {});

  },
  show: async (id) => {
    return await handleApi('/admin/students/' + id, 'get', {});
  },
};
export default adminStudentsService;
