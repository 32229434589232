import styled from 'styled-components';

export const ModalBackground = styled.div`
  position: absolute;
  bottom: 10vh;
  max-width: 30vw;
  max-height: 200px;
  overflow: auto;

  background-color: #244C59;
  box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  @media (max-width: 830px) {
    left: 0;
    max-width: 50vw;
  }

  @media (max-width: 600px) {
    max-width: 70vw;
  }
`;

export const ModalContent = styled.div`
  background-color: #244C59;
  widows: inherit;
  color: white;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const OwnerName = styled.h2`
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #244C59;
`;