import React from 'react';

import { Container, Input, Label } from './styles';
import CopyText from '../CopyText';

function InputText({ inputProps, label, labelProps, copyValue }) {
  return (
    <Container>
      <Label for={inputProps.name} {...labelProps}>{label} {copyValue && <CopyText paragraphStyle={{ display: 'none' }}>{inputProps.value}</CopyText>}</Label>
      <Input {...inputProps} />

    </Container>
  );
}

export default InputText;
