import React, { useState } from 'react';
import { ModalBackground, ModalContent, OwnerName } from './styles';


const EmojiModal = ({ isOpen, setNewMessage, newMessage }) => {
  if (!isOpen) return null;
  const emojis = ['✅', '🚀', '👏🏻', "😁", "🧠"];

  return (
    <ModalBackground>
      <ModalContent>
        {
          emojis.map((emoji, index) => (
            <span style={{ cursor: 'pointer' }} key={index} onClick={() => setNewMessage(newMessage + emoji)}>{emoji}</span>
          ))
        }
      </ModalContent>
    </ModalBackground>
  );
};

export default EmojiModal;
