import { handleApi } from './server';

const authService = {
  login: async (data) => {
    return await handleApi('/sessions', 'post', { data });
  },
  checkLogin: async () => {
    return await handleApi('/sessions/check', 'get', {});
  },
  forgotPassword: async (data) => {
    return await handleApi('/password/forgot', 'post', { data });
  },
  validateTokenResetPassword: async (data) => {
    return await handleApi('/password/forgot', 'get', { params: { token: data.token } });
  },
  resetPassword: async (data) => {
    return await handleApi('/password/reset', 'put', { data });
  },
  registryConfirm: async (data) => {
    return await handleApi('/users/registry-confirm', 'post', { data });
  },
  validateChatId: async (data) => {
    return await handleApi('/users/validate-chat-id', 'get', { params: { data } });
  }
};
export default authService;
