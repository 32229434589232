import React, { useEffect, useState } from "react";
import DashboardTemplate from "../../../templates/DashboardTemplate";
import {
  Body,
  ButtonContainer,
  Container,
  Form,
  Header,
  RowInput,
  SubTitle,
  Title,
} from "./styles";

import Button from "../../../../components/forms/Button";
import ButtonBack from "../../../../components/forms/ButtonBack";
import InputText from "../../../../components/forms/InputText";
import CheckBox from "../../../../components/forms/CheckBox";
import adminStudentsService from "../../../../services/adminStudentsService";
import { useParams } from "react-router-dom";
import { myAlert } from "../../../../lib/Alert";
import { useNavigate } from "react-router-dom";
import masks from "../../../../lib/masks";
import tagConst from '../../../../constants/tags';

function UpdateStudentContent() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [phone, setPhone] = useState("");
  const [tags, setTags] = useState(tagConst.array);

  let { id } = useParams();

  const [student, setStudent] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getStudent = async () => {
      try {
        setLoading(true);
        const res = await adminStudentsService.show(id);
        setStudent(res.data);
        setFullname(res.data.fullname);
        setEmail(res.data.email);
        setCpfCnpj(res.data.cpfCnpj);
        setPhone(res.data.phone);
        setLoading(false);
      } catch (error) {
        console.log(error);
        myAlert.open({
          title:
            "Erro ao buscar dados. Recarregue a página ou contate o suporte.",
          type: "error",
        });
        setLoading(false);
      }
    };
    getStudent();
  }, [id]);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let _tags = tags.filter((tag) => tag.status);
      _tags = _tags.map((tag) => tag.key);

      if (!fullname || !email || !cpfCnpj || !phone) {
        myAlert.open({
          title: 'Preencha todos os campos obrigatórios',
          type: 'warning',
        });
        setLoading(false);
        return;
      } else {
        const rest = await adminStudentsService.update({
          email,
          fullname,
          cpfCnpj,
          phone,
          id,
          tags: _tags,
        });

        myAlert.open({ title: "Atualizado com sucesso", type: "success" });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      myAlert.open({ title: "Erro ao atualizar", type: "error" });
      setLoading(false);
    }
  };
  return (
    <Container>
      <Header>
        <ButtonBack />
        <Title>Atualização Aluno</Title>
        <SubTitle>
          Você está atualizando as informações de <b>{student.name}</b>
        </SubTitle>
      </Header>
      <Body>
        <Form onSubmit={handleSubmit}>
          <RowInput>
            <InputText
              label="Nome"
              inputProps={{
                type: "text",
                value: fullname,
                onChange: (e) => setFullname(e.target.value),
              }}
            />
            <InputText
              label="E-mail"
              inputProps={{
                type: "e-mail",
                value: email,
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <InputText
              label="CPF"
              inputProps={{
                type: "text",
                value: cpfCnpj,
                onChange: (e) => setCpfCnpj(masks.cpf_cnpj_mask(e.target.value)),
              }}
            />
            <InputText
              label="Telefone"
              inputProps={{
                type: "text",
                value: phone,
                onChange: (e) => setPhone(e.target.value),
              }}
            />
          </RowInput>
          <RowInput>
            <CheckBox label="Tags" options={tags} setOptions={setTags} />
          </RowInput>
          <ButtonContainer>
            <Button
              label="Atualizar"
              buttonProps={{ type: "submit" }}
              containerStyle={{ width: "200px" }}
              loading={loading}
            />
          </ButtonContainer>
        </Form>
      </Body>
    </Container>
  );
}
function UpdateStudent() {
  return (
    <DashboardTemplate>
      <UpdateStudentContent />
    </DashboardTemplate>
  );
}

export default UpdateStudent;
