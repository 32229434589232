import { handleApi } from './server';

const adminChatsService = {
  update: async (data) => {
    const { id, isActive, fixedMessage, roomName } = data;

    const response = await handleApi('/admin/chats/' + id, 'put', {
      data,
    });
    return response;
  },
  create: async (data) => {
    const { title, fixedMessage } = data;

    const format = {
      roomName: title,
      fixedMessage,
    }

    const response = await handleApi('/admin/chats', 'post', {
      data: format,
    });

    return response.data;
  },

  show: async (id) => {
    return await handleApi('/admin/chats/' + id, 'get', {});
  },

  list: async (data) => {
    const params = {
      ...data,
    };
    return await handleApi('/admin/chats', 'get', {
      data,
      params,
    });
  },
};
export default adminChatsService;
