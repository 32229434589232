import styled, { css } from 'styled-components';

export const Container = styled.div``;

const DefaultContainerImg = styled.label(
  (props) => css`
    ${props.$clickable &&
    css`
      cursor: pointer;
    `}
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    position: relative;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);
export const UploadFile = styled.img`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
`;
export const FrontContainer = styled(DefaultContainerImg)``;
export const BackContainer = styled(DefaultContainerImg)``;
export const ImageContainer = styled.img``;
export const CenterText = styled.span`
  width: 100%;
  padding: 0 140px;
  text-align: center;
  position: absolute;
  z-index: 10;
  white-space: pre-line;
  @media (max-width: 700px) {
    font-size: 8px;
  }
`;
export const LeftText = styled.span`
  width: 100%;
  white-space: pre-line;
  padding: 120px 64px;
  -moz-column-count: 2;
  -webkit-column-count: 2;
  column-count: 2;
  column-fill: auto;
  height: 100%;
  /* text-align: center; */
  top: 0;
  left: 0;
  white-space: pre-line;

  position: absolute;
  font-size: 13px;
  z-index: 10;
  @media (max-width: 700px) {
    padding: 30px 30px;
    font-size: 8px;
  }
`;
export const RightText = styled(LeftText)`
  margin-top: 10px;
  font-size: 11.76px;
  line-height: 17px;
`
export const HashTitle = styled.p`
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 10;
  bottom: 67px;
  left: 0;
  font-size: 10px;
  @media (max-width: 700px) {
    font-size: 6px;
    bottom: 30px;
  }
`;
export const HashText = styled.span`
  width: 100%;
  text-align: center;
  position: absolute;
  z-index: 10;
  bottom: 52px;
  left: 0;
  font-size: 10px;
  @media (max-width: 700px) {
    font-size: 6px;
    bottom: 30px;
  }
`;
export const LinkText = styled.span``;
export const Title = styled.p`
  /* margin: 10px 0; */
  /* padding: 0; */
  box-sizing: border-box;
  font-family: 'Inter', 'Roboto', Helvetica, Arial;
  font-size: 13px;
  margin: 20px 0 8px 0;
`;
export const TextHelper = styled.p`
  font-size: 12px;
  margin: 5px 0 0 0;
`;

export const ContainerHelperText = styled.div`
  margin: 10px 0 0 10px;
`;
