export const makeid = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

class Alert {
  init = (data) => {
    Object.assign(this, data);
  };
  open = (data, closeNotifications) => {};
  closeAll = () => {};
}
export const myAlert = new Alert();
